import React from 'react'
import { SecondaryText, RegularText, Bold } from 'uiComponents/typography'
import { NameAndStatus, Cell, MultipleStatus } from 'uiComponents/table'
import { CAMPAIGN_STATE, NotificationCampaignListItem, SCHEDULE_TYPE } from 'notificationCampaigns/types'
import { ActionMenu } from './listItemActionMenu'
import { faCalendarDay, faRepeat, faQuestion } from '@fortawesome/pro-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { find } from 'lodash'
import { DataRowAutoHeight, StatusAndText } from './ui'

interface RowProps {
    item: NotificationCampaignListItem
    onEdit: () => void
    onDelete: (name: string) => void
    onSetActive: () => void
    onSetInactive: () => void
}

const SCHEDULE_TYPE_ICON = [
    {
        id: SCHEDULE_TYPE.ONE_TIME,
        icon: faCalendarDay as IconProp,
    },
    {
        id: SCHEDULE_TYPE.RECURRING,
        icon: faRepeat as IconProp,
    },
]

export const ListItem = ({ item, onEdit, onDelete, onSetActive, onSetInactive }: RowProps) => {
    const icon = find(SCHEDULE_TYPE_ICON, { id: item.scheduleType })?.icon || (faQuestion as IconProp)

    const status = () => {
        switch (item.state) {
            case CAMPAIGN_STATE.ACTIVE:
                return 'success'
            case CAMPAIGN_STATE.DRAFT:
                return 'blue'
            default:
                return 'success'
        }
    }

    return (
        <DataRowAutoHeight key={item.id}>
            <Cell align="center">
                <FontAwesomeIcon icon={icon} style={{ marginRight: '.3em' }} />
            </Cell>
            <Cell className="wrapContent">
                <NameAndStatus>
                    <RegularText>{item.name}</RegularText>
                    <SecondaryText>{item.scheduleDescription}</SecondaryText>
                </NameAndStatus>
            </Cell>
            <Cell className="wrapContent">
                <NameAndStatus>
                    <Bold>{item.title}</Bold>
                    <RegularText>{item.message}</RegularText>
                </NameAndStatus>
            </Cell>
            <Cell>
                <NameAndStatus>
                    <StatusAndText>
                        <MultipleStatus
                            status={status()}
                            withText
                            successText="Active"
                            errorText="Inactive"
                            blueText="Draft"
                        />
                    </StatusAndText>
                </NameAndStatus>
            </Cell>
            <ActionMenu
                state={item.state}
                onEditInfo={onEdit}
                onDelete={() => onDelete(item.id)}
                onActive={onSetActive}
                onInactive={onSetInactive}
            />
        </DataRowAutoHeight>
    )
}

export default React.memo(ListItem)
