import { HttpService } from 'http/httpService'
import { LoggingService, EventType, ActionEventData } from 'http/loggingService'

export type ChannelsSectionType = 'resellers' | 'retailers' | 'google_ttd'
export type CreateNewType = ChannelsSectionType | 'campaign' | null

export interface ResellerResponseItem {
    name: string
    resellerId: string
    archived: boolean
}

interface Campaign {
    name: string
    id: string
}

export interface RetailerResponseItem {
    name: string
    id: string
    archived: boolean
    campaigns: Campaign[]
}

export class ChannelsService {
    constructor(
        private httpService: HttpService,
        private loggingService: LoggingService,
        private backofficeEndpoint: string,
    ) {}

    getResellersList = async (accountSlug: string, query?: string): Promise<ResellerResponseItem[]> => {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/resellers/${query || ''}`,
            {
                method: 'GET',
            },
        )
        if (!response.ok) {
            throw new Error('Unable to get resellers list')
        }
        return await response.json()
    }

    getResellerDetails = async (accountSlug: string, resellerId: string): Promise<ResellerResponseItem> => {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/resellers/${resellerId}/`,
            {
                method: 'GET',
            },
        )
        if (!response.ok) {
            throw new Error('Unable to get reseller details')
        }
        return await response.json()
    }

    createReseller = async (accountSlug: string, name: string, resellerId: string): Promise<void> => {
        const logEventType: EventType = 'reseller_created'
        const logEventData: ActionEventData = {
            category: 'resellers',
            payload: { name, resellerId },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/resellers/`,
            {
                method: 'POST',
                body: JSON.stringify({ name, resellerId }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        )

        if (!response.ok) {
            if (response.status === 400) {
                const body = await response.json()
                this.loggingService.logError(body, logEventType, logEventData)
                throw new Error(body.error && body.error.code ? body.error.code : 'Unable to create a reseller')
            } else {
                this.loggingService.logResponseError(response, logEventType, logEventData)
                throw new Error('Unable to create a reseller')
            }
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    updateReseller = async (accountSlug: string, name: string, resellerId: string): Promise<ResellerResponseItem> => {
        const logEventType: EventType = 'reseller_updated'
        const logEventData: ActionEventData = {
            category: 'resellers',
            payload: { name, resellerId },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/resellers/${resellerId}/`,
            {
                method: 'PUT',
                body: JSON.stringify({ name, resellerId }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to update the reseller')
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async archiveReseller(accountSlug: string, resellerId: string): Promise<void> {
        const logEventType: EventType = 'reseller_deleted'
        const logEventData: ActionEventData = {
            category: 'resellers',
            payload: { resellerId },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/resellers/${resellerId}/`,
            {
                method: 'DELETE',
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to delete reseller')
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    getRetailersList = async (accountSlug: string, query?: string): Promise<RetailerResponseItem[]> => {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/external_channels/${query || ''}`,
            {
                method: 'GET',
            },
        )
        if (!response.ok) {
            throw new Error('Unable to get retailers list')
        }
        return await response.json()
    }

    getRetailerDetails = async (accountSlug: string, id: string): Promise<RetailerResponseItem> => {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/external_channels/${id}/`,
            {
                method: 'GET',
            },
        )
        if (!response.ok) {
            throw new Error('Unable to get retailer details')
        }
        return await response.json()
    }

    createRetailer = async (accountSlug: string, name: string): Promise<void> => {
        const logEventType: EventType = 'retailer_created'
        const logEventData: ActionEventData = {
            category: 'retailers',
            payload: { name },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/external_channels/`,
            {
                method: 'POST',
                body: JSON.stringify({ name }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to create a retailer')
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    updateRetailer = async (accountSlug: string, name: string, id: string): Promise<RetailerResponseItem> => {
        const logEventType: EventType = 'retailer_updated'
        const logEventData: ActionEventData = {
            category: 'retailers',
            payload: { name, id },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/external_channels/${id}/`,
            {
                method: 'PUT',
                body: JSON.stringify({ name, id }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to update the reseller')
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }
}
