export type DateLocale = 'INTL' | 'US'

export interface UserPreferences {
    activeAccount: string | null
    dateLocale: DateLocale
}

export function createInitialUserPrefsState(): UserPreferences {
    return {
        activeAccount: null,
        dateLocale: 'INTL',
    }
}
