import { Store, AnyAction } from 'redux'
import { Session } from 'http/session'
import { AuthTicket } from 'http/oauthService'
import { State } from 'store'
import { UserDetails } from './userService'
import { userLoggedIn, userDataRetrieved, userLoggedOut } from './actions'
import { changeActiveAccount } from 'preferences/actions.async'
import { profileLoaded } from 'settings/actions'

export class ReduxBackedSession implements Session {
    constructor(private store: Store<State>, private getUserDetails: () => Promise<UserDetails>) {}

    authTicket(): AuthTicket | null {
        return this.store.getState().auth.ticket
    }

    async initialize(ticket: AuthTicket) {
        this.store.dispatch(userLoggedIn(ticket))
        const userDetails = await this.getUserDetails()
        this.store.dispatch(userDataRetrieved(userDetails.currentUser))
        this.store.dispatch(profileLoaded(userDetails.profile))
        const state = this.store.getState()
        const activeAccount = state.preferences.activeAccount
        const canSeeCurrentAccount =
            activeAccount && !!userDetails.currentUser.accounts.find((x) => x.slug === activeAccount)
        const newActiveAccount = canSeeCurrentAccount
            ? activeAccount
            : userDetails.currentUser.accounts[0]
            ? userDetails.currentUser.accounts[0].slug
            : null
        await this.store.dispatch(changeActiveAccount(newActiveAccount) as any as AnyAction)
    }

    abandon(): void {
        this.store.dispatch(userLoggedOut())
    }
}
