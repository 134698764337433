type VariableType =
    | 'String'
    | 'Date'
    | 'StatsMetric'
    | '[FilterDictionary]'
    | 'StatsGranularity'
    | 'StatsTimeFilter'
    | 'DelayTypes'
    | 'Int'
    | 'ComparisonTypes'
    | 'TimeIdentifierType'
type QueryType = 'stats' | 'comparisonMetrics'
type PresetResultType =
    | 'totals'
    | 'totalsComparison'
    | 'dataPoints'
    | 'timeseries'
    | 'timeseriesAndTotals'
    | 'dataSeries'
    | 'dataSeriesAndTotals'

interface QueryVariables {
    name: string
    configValue?: string
    customValue?: string | number | Date
}

interface QueryConfigVariables {
    name: string
    type: VariableType
}

export interface Query {
    name: string
    type: QueryType
    configVariables: string[]
    customVariables: QueryVariables[]
    presetResult: PresetResultType
    customResult?: string
}

export interface QueryConfig {
    querySetName: string
    variablesConfig: QueryConfigVariables[]
    queries: Query[]
}

export const totals = `
  totals {
    name
    value
  },
`

export const totalsComparison = `
  totals {
    value,
    change
  },
`

export const dataPoints = `
  points {
    timestamp,
    value
  }
`

export const timeseries = `
  timeSeries(granularity: $granularity) {
    label
    ${dataPoints}
  }
`

export const timeseriesAndTotals = `
  ${timeseries}
  ${totals}
`

export const dataSeries = `
  dataSeries {
    columns,
    data
  }
`

export const dataSeriesAndTotals = `
  ${dataSeries}
  ${totals}
`

const presetResultsMap = {
    totals,
    totalsComparison,
    dataPoints,
    timeseries,
    timeseriesAndTotals,
    dataSeries,
    dataSeriesAndTotals,
}

const getSubQueryString = (q: Query) => {
    let variablesString = ''
    q.configVariables.forEach((v) => {
        variablesString += `${v}: $${v}, `
    })
    q.customVariables.forEach((cv) => {
        variablesString += `${cv.name}: ${cv.customValue || `$${cv.configValue}`}, `
    })

    return `${q.name}: ${q.type}(${variablesString}) { ${q.customResult || presetResultsMap[q.presetResult]} } `
}

export const getQueryString = (config: QueryConfig) => {
    let variablesConfigString = ''
    let queriesString = ''

    config.variablesConfig.forEach((v) => {
        variablesConfigString += `$${v.name}: ${v.type}, `
    })

    config.queries.forEach((q) => {
        queriesString += getSubQueryString(q)
    })

    return `query ${config.querySetName}(${variablesConfigString}) {${queriesString}}`
}
