import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { httpService } from 'middleware/store'

interface HttpServiceQueryParams {
    url: string
    method?: 'POST' | 'GET' | 'PUT' | 'DELETE'
    data?: any
    headers?: any
    params?: string
}

export type BaseQueryType<T = unknown, E = unknown> = (
    arg: HttpServiceQueryParams,
) => Promise<QueryReturnValue<T, E, {}>>

export const authenticatedHttpServiceQuery = (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
): BaseQueryFn<HttpServiceQueryParams, unknown, unknown> => {
    return async ({ url, method = 'GET', ...rest }: RequestInit & { url: string }) => {
        try {
            const result = await httpService.fetch(baseUrl + url, {
                method,
                ...rest,
            })
            return { data: result.json() }
        } catch (e) {
            return {
                error: e,
            }
        }
    }
}
