import React from 'react'
import styled from 'styled-typed'
import NumberInputFieldFormik from 'uiComponents/input/numberInput/numberInputFieldFormik'
import { Question } from 'orders/ordersService'
import { FormWrapper } from 'uiComponents/form/form'
import ActionButton from 'uiComponents/buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Status } from './types'

const Container = styled.div`
    @media only screen and (max-width: 767px) {
        width: calc(95vw - 3rem);
    }
`

const Title = styled.div`
    margin-bottom: 10px;
    text-align: center;
    color: #6a7e92;
    font-size: 18px;
    font-weight: bold;
`

const QuestionElement = styled.div`
    width: 100%;
    background: white;
    padding: 15px;
    border-radius: 10px;
    overflow: auto;
    margin-bottom: 10px;
    & label {
        display: inline-block;
        margin: 0 2px;
    }
    & input {
        color: #009d51;
        width: 70px;
        border: none;
        border-bottom: solid 1px #009d51;
        text-align: center;
        font-weight: bold;
        font-size: 22px !important;
        border-radius: unset;
        height: unset;
        padding: unset;
        background: unset;
    }
    & input:focus {
        border: none;
        border-bottom: solid 1px #009d51;
    }
`
const QuestionLabel = styled.div`
    display: inline-block;
    width: 65%;
    margin-top: 6px;
    font-weight: bold;
    font-size: 22px;
`
const Answer = styled.div`
    display: inline-block;
    float: right;

    & [type='button'] {
        width: 50px;
        height: 40px;
        font-size: 30px;
        color: #009d51;
        background: white;
        border: solid 2px #f5f7fa;
        cursor: pointer;
        transition: 150ms;
    }

    & [type='button']:active {
        border-color: #009d51;
    }

    & [type='button'].decrement {
        border-radius: 8px 0 0 8px;
    }

    & [type='button'].increment {
        border-radius: 0 8px 8px 0;
    }

    & [type='button'][disabled] {
        color: #c5c5c5;
        cursor: not-allowed;
    }

    & [type='button']:not([disabled]):hover {
        color: white;
        background: #009d51;
    }
    & ::placeholder {
        font-style: italic;
    }
`

const CancelButton = styled.div`
    float: left;
    padding: 7px 10px;
    line-height: 26px;
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;
    color: grey;
`

const SubmitButton = styled(ActionButton)`
    float: right;
    width: 50%;
    font-weight: normal;
    background: #5298f4;
    transition: 150ms;
    &:hover {
        background: #5298f4b8 !important;
    }
    &[disabled] {
        color: white;
        background: #5298f4b8;
    }
`

interface GroupRedeemModeQuestionnaireProps {
    questions: Question[]
    submitAnswers: any
    status: Status
    cancel: any
}

export function GroupRedeemModeQuestionnaire({
    questions,
    submitAnswers,
    status,
    cancel,
}: GroupRedeemModeQuestionnaireProps) {
    const getInitialValues = () =>
        questions.reduce((acc, question) => {
            acc[question.articleUuid] = question.answerMaximum
            return acc
        }, {})

    return (
        <Container>
            <Title>Enter the amount of tickets to redeem</Title>
            <FormWrapper initialValues={getInitialValues()} onSubmit={submitAnswers}>
                {questions.map((question) => (
                    <QuestionElement key={question.articleUuid}>
                        <QuestionLabel>{question.articleName}</QuestionLabel>
                        <Answer>
                            <NumberInputFieldFormik
                                name={question.articleUuid}
                                min={question.answerMinimum}
                                max={question.answerMaximum}
                                integerOnly
                                placeholder={`${question.answerMaximum}`}
                                addButtons={true}
                                resetToZero={true}
                                required={true}
                                validationMessageProps={{ style: { display: 'block', textAlign: 'center' } }}
                            />
                        </Answer>
                    </QuestionElement>
                ))}
                <CancelButton onClick={cancel}>Cancel</CancelButton>
                <SubmitButton size="large" type="submit" disabled={status === 'loading'}>
                    {status === 'loading' ? (
                        <FontAwesomeIcon icon={faSpinner} className="fa-pulse" />
                    ) : (
                        <>Redeem tickets</>
                    )}
                </SubmitButton>
            </FormWrapper>
        </Container>
    )
}
