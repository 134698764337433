import React, { FC, MouseEvent } from 'react'
import styled from 'styled-typed'
import { SingleSelectOption as SingleSelectOptionType } from './singleSelect'

interface ContainerProps {
    selected: boolean
    disabled?: boolean
}

export const Container = styled.div<ContainerProps>`
    background: ${(props) => (props.selected ? props.theme.colors.background : '')};
    line-height: 1.8rem;
    font-weight: lighter;
    outline: none;
    cursor: pointer;
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 1em 0 1.1em;

    &:hover,
    &.focused {
        background: ${(props) => props.theme.colors.background};
    }
`

export interface SingleSelectOptionProps {
    option: SingleSelectOptionType
    focused?: boolean
    selected?: boolean
    onClick: (event: MouseEvent) => void
}

const SingleSelectOption: FC<SingleSelectOptionProps> = (props) => {
    const { option, selected = false, focused = false, onClick } = props

    return (
        <Container
            selected={selected}
            onClick={onClick}
            className={`option${focused ? ' focused' : ''}${option.value === null ? ' no-value' : ''}`}
            disabled={option.disabled}
            id={option.value}
        >
            {option.name}
        </Container>
    )
}

export default SingleSelectOption
