import { HttpService } from 'http/httpService'
import { Availability, GetAvailabilitiesPayload } from './types'
import * as config from 'config'

export class ReservationsService {
    constructor(private httpService: HttpService) {}

    private reservationsApiUrl = config.getRequired('reservations-endpoint')

    async getReservationsList(payload: GetAvailabilitiesPayload): Promise<Availability[]> {
        const queryParams = new URLSearchParams(payload).toString()

        let response = await this.httpService.fetch(
            `${this.reservationsApiUrl}v1/availability/for_period?${queryParams}`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch reservations')
        }

        const data = await response.json()

        return data.results
    }
}
