import * as React from 'react'
import { ContentState, ContentBlock } from 'draft-js'

type Callback = (start: number, end: number) => void

export function findLinkEntities(contentBlock: ContentBlock, callback: Callback, contentState: ContentState) {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity()
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
    }, callback)
}

interface LinkProps {
    contentState: ContentState
    entityKey: string
    children: React.ReactNode
}

export const Link = (props: LinkProps) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData()
    return (
        <a href={url} style={{ cursor: 'pointer' }} title={url}>
            {props.children}
        </a>
    )
}
