import 'cropperjs/dist/cropper.css'
import * as React from 'react'
import styled from 'styled-typed'
import Cropper from 'cropperjs'

const Canvas = styled.img`
    display: block;
    max-width: 100%;
`

interface ImageCropperProps {
    id?: string
    src: string
    cropOptions?: Cropper.Options
    onCrop: (e: any, id?: string) => void
}

export const ImageCropper = ({ id, src, onCrop, cropOptions }: ImageCropperProps) => {
    const imgEl = React.useRef(null)

    React.useEffect(() => {
        const img = imgEl.current
        if (!img) {
            return
        }
        const cropper = new Cropper(img, {
            ...cropOptions,
            crop: (e) => onCrop(e.detail, id),
        })
        return () => {
            cropper.destroy()
        }
    }, [imgEl.current])

    return <Canvas id={id} src={src} ref={imgEl} />
}
