const getPromotions = `query GetPromotions($slug: String!) {
  getPromotions(slug: $slug, consentGiven: true) {
    id
    name
    active
    startDate
    endDate
    weight
    audiences {
      audience
    }
  }
}`

const getPromotion = `query GetPromotion($slug: String!, $id: ID!) {
  getPromotion(slug: $slug, id: $id) {
    slug
    id
    name
    active
    startDate
    endDate
    weight
    partnerSlug
    partnerName
    consentRequired
    weight
    content {
      title
      content
      locale
      default
    }
    style {
      brandColor
      theme
      image
    }
    audiences {
      audience
    }
  }
}`

const updatePromotionsWeight = `mutation UpdatePromotionsWeight($slug: String!, $promotions: [PromotionWeightInput!]!) {
  updatePromotionsWeight(slug: $slug, promotions: $promotions) {
    id
    name
    active
    startDate
    endDate
    weight
  }
}`

const updatePromotion = `mutation UpdatePromotion($promotion: UpdatePromotionInput) {
  updatePromotion(promotion: $promotion) {
    id
  }
}`

const addPromotion = `mutation AddPromotion($promotion: AddPromotionInput) {
  addPromotion(promotion: $promotion){
    name
  }
}`

const deletePromotion = `mutation DeletePromotion($slug: String!, $id: ID!) {
  deletePromotion(slug: $slug, id: $id)
}`

export default {
    getPromotions,
    getPromotion,
    updatePromotionsWeight,
    updatePromotion,
    addPromotion,
    deletePromotion,
}
