import { AuthenticatedHttpService } from 'http/httpService'
import { DetailedError } from 'utils/errorHandler'
import * as yup from 'yup'

export interface Tax {
    name: string
    taxPercentage: number
    proportionPercentage: number
    taxType: string
    uuid: string
}

export interface TaxConfiguration {
    accountSlug: string
    name: string
    taxes: Tax[]
    uuid: string
}

export interface TaxConfigurationsResponse {
    taxConfigurations: TaxConfiguration[]
    defaultTaxConfigurationUuid: string
}

interface TaxError {
    [key: string]: string[]
}

export interface TaxConfigurationsError {
    code: string
    errors: { taxes: TaxError[] }
}

export type TaxType = 'VAT' | 'SALES' | 'LIQUOR' | 'AMUSEMENT'

export interface TaxTypesResponse {
    priceIncludesVat: boolean
    taxTypes: TaxType[]
}

export const taxConfigurationFormValidationSchema = yup.object().shape({
    name: yup.string().required(),
    taxes: yup
        .array()
        .of(
            yup.object().shape({
                name: yup.string().required(),
                taxPercentage: yup.number().required().min(0).max(100),
                proportionPercentage: yup.number().required().min(0).max(100),
            }),
        )
        .required(),
})

const convertPercentagesToNumbers = (taxConfiguration: TaxConfiguration) => ({
    ...taxConfiguration,
    taxes: taxConfiguration.taxes.map((tax: any) => ({
        ...tax,
        taxPercentage: parseFloat(tax.taxPercentage),
        proportionPercentage: parseFloat(tax.proportionPercentage),
    })),
})

export const parseTaxConfigurationsResponse = (response: any): TaxConfigurationsResponse => ({
    ...response,
    taxConfigurations: response.taxConfigurations.map((taxConfiguration: any) =>
        convertPercentagesToNumbers(taxConfiguration),
    ),
})

export class TaxConfigurationsService {
    constructor(private httpService: AuthenticatedHttpService, public backofficeEndpoint: string) {}

    private taxConfigurationsUrl(accountSlug: string) {
        return `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/tax_configurations/`
    }

    async getTaxConfigurations(accountSlug: string): Promise<TaxConfigurationsResponse | null> {
        const response = await this.httpService.fetch(this.taxConfigurationsUrl(accountSlug))
        if (response.status === 404) {
            return null
        }
        if (!response.ok) {
            throw new Error('Unable to retrieve tax configurations')
        }
        const responseJson = await response.json()
        return parseTaxConfigurationsResponse(responseJson)
    }

    async createTaxConfiguration(
        accountSlug: string,
        taxConfiguration: Partial<TaxConfiguration>,
    ): Promise<TaxConfiguration> {
        const response = await this.httpService.fetch(this.taxConfigurationsUrl(accountSlug), {
            method: 'POST',
            body: JSON.stringify(taxConfiguration),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const responseJson = await response.json()
        if (!response.ok) {
            throw new DetailedError('Unable to create tax configuration', responseJson.error)
        }
        return convertPercentagesToNumbers(responseJson)
    }

    async updateTaxConfiguration(
        accountSlug: string,
        taxConfiguration: Partial<TaxConfiguration>,
    ): Promise<TaxConfiguration> {
        const response = await this.httpService.fetch(
            `${this.taxConfigurationsUrl(accountSlug)}${taxConfiguration.uuid}`,
            {
                method: 'PUT',
                body: JSON.stringify(taxConfiguration),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        const responseJson = await response.json()
        if (!response.ok) {
            throw new DetailedError('Unable to update tax configuration', responseJson.error)
        }
        return await responseJson
    }

    async deleteTaxConfiguration(accountSlug: string, taxConfigurationUuid: string): Promise<void> {
        const response = await this.httpService.fetch(
            `${this.taxConfigurationsUrl(accountSlug)}${taxConfigurationUuid}`,
            {
                method: 'DELETE',
            },
        )
        if (!response.ok) {
            throw new Error('Unable to delete tax configuration')
        }
    }

    async setDefaultTaxConfiguration(accountSlug: string, taxConfigurationUuid: string): Promise<void> {
        const response = await this.httpService.fetch(
            `${this.taxConfigurationsUrl(accountSlug)}${taxConfigurationUuid}/default`,
            {
                method: 'PATCH',
            },
        )
        if (!response.ok) {
            throw new Error('Unable to set default tax configuration')
        }
    }

    async getTaxTypes(accountSlug: string): Promise<TaxTypesResponse | null> {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/market_type_settings/`,
        )
        if (response.status === 404) {
            return null
        }
        if (!response.ok) {
            throw new Error('Unable to retrieve tax types')
        }
        return await response.json()
    }
}
