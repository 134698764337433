import React from 'react'
import styled from 'styled-typed'
import { EditorState, RichUtils, Modifier } from 'draft-js'
import alignCenterIcon from './icons/align-center.svg'
import alignJustifyIcon from './icons/align-justify.svg'
import alignLeftIcon from './icons/align-left.svg'
import alignRightIcon from './icons/align-right.svg'
import boldIcon from './icons/bold.svg'
import italicIcon from './icons/italic.svg'
import redoIcon from './icons/redo.svg'
import underlineIcon from './icons/underline.svg'
import undoIcon from './icons/undo.svg'
import linkIcon from './icons/link.svg'
import variableIcon from './icons/variable.svg'
import { specialize } from 'reactUtils'
import { ToggleButton, DropdownButton, DropdownInlineButton } from './buttons'
import { TextInput } from 'uiComponents/input'
import { ActionButton } from 'uiComponents/buttons'
import { faPalette, faList, faListOl } from '@fortawesome/pro-regular-svg-icons'
import { ColorPickerPopup } from 'uiComponents/colorPicker'

const ToolbarContainer = styled.div`
    font-size: 0.875rem;
    border-radius: 0.375em;
    height: 0.375em;
    transition: all 0.2s ease-in;
    margin-top: -0.625em;
    padding: 0.375em 0.5em 0;
    position: relative;
    overflow: hidden;

    &.visible-animation-done {
        overflow: visible;
    }

    &.visible {
        padding-top: 0.625em;
        height: 3.8em;
        outline: 1px solid var(--aluminium);
    }

    &.with-dialog {
        height: 8em;
    }
`
const ToolbarButtons = styled.div`
    display: flex;
`
const ToolbarDialog = styled.div`
    position: relative;
    padding-top: 0.4em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s 0.1s, opacity 0.1s ease-in;

    &.visible {
        overflow: auto;
        visibility: visible;
        opacity: 1;
        transition: opacity 0.1s ease-in;
    }
`
const Input = styled(TextInput)`
    font-size: 0.8em;
    height: 2.7em;
    width: 16em;
`
const VariableContainer = styled.div`
    font-size: 0.9em;
    height: 3.8em;
`
const Variable = styled.div`
    background: ${(props) => props.theme.colors.background};
    padding: 0.2em;
    margin: 0.2em 0.15em;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
`
const Spacer = styled.div`
    flex: 1 1 0;
`

interface Alignment {
    id: string
    icon: any
}

const alignments = [
    {
        id: 'paragraph-align-left',
        icon: alignLeftIcon,
    },
    {
        id: 'paragraph-align-center',
        icon: alignCenterIcon,
    },
    {
        id: 'paragraph-align-right',
        icon: alignRightIcon,
    },
    {
        id: 'paragraph-align-justify',
        icon: alignJustifyIcon,
    },
]

const AlignmentList = styled.div`
    display: flex;
`

interface AlignmentProps {
    item: Alignment
    onClick: () => void
}

function AlignmentItem({ item, onClick }: AlignmentProps) {
    return <DropdownInlineButton key={item.id} icon={item.icon} onClick={onClick} />
}

const AlignmentDropdownButton = specialize<DropdownButton<Alignment>>(DropdownButton)

const DEFAULT_TEXT_COLOR = '#394954'

interface ToolbarProps {
    visible: boolean
    editorState: EditorState
    styles: any
    onChange: (editorState: EditorState) => void
    style?: React.CSSProperties
    withLink?: boolean
    withColor?: boolean
    withOrderedList?: boolean
    withUnorderedList?: boolean
    hideAlignmentOption?: boolean
    colorPickerOnRight?: boolean
    customVariables?: string[]
    setErrorMessage: (message: string) => void
}

interface ToolbarState {
    animating: boolean
    showUrlDialog: boolean
    showVariableDialog: boolean
    showColorPicker: boolean
    url: string
    color: string
}

export class Toolbar extends React.Component<ToolbarProps, ToolbarState> {
    constructor(props: ToolbarProps) {
        super(props)
        this.state = {
            animating: false,
            showUrlDialog: false,
            showVariableDialog: false,
            showColorPicker: false,
            url: '',
            color: DEFAULT_TEXT_COLOR,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: ToolbarProps) {
        if (this.props.visible !== nextProps.visible) {
            this.setAnimationTimeout()
            if (!nextProps.visible) {
                this.setState({ showUrlDialog: false, showVariableDialog: false })
            }
        }
        if (
            this.props.styles.color.current(this.props.editorState) !==
            this.props.styles.color.current(nextProps.editorState)
        ) {
            this.setState({
                color: this.props.styles.color.current(nextProps.editorState) || DEFAULT_TEXT_COLOR,
            })
        }
    }

    setAnimationTimeout = () => {
        this.setState({ animating: true }, () => setTimeout(() => this.setState({ animating: false }), 200))
    }

    toggleStyle = (style: string) => {
        const editorState = EditorState.forceSelection(this.props.editorState, this.props.editorState.getSelection())
        this.props.onChange(RichUtils.toggleInlineStyle(editorState, style))
    }

    toggleColor = () => {
        const selection = this.props.editorState.getSelection()
        if (!selection.isCollapsed()) {
            const newEditorState =
                this.state.color === DEFAULT_TEXT_COLOR
                    ? this.props.styles.color.remove(this.props.editorState)
                    : this.props.styles.color.toggle(this.props.editorState, this.state.color)
            this.props.onChange(newEditorState)
            this.setState({ showColorPicker: false })
        } else {
            this.props.setErrorMessage('Please select text the color will apply to')
        }
    }

    setBlockType = (blockType: string) => {
        if (blockType === 'unordered-list-item' || blockType === 'ordered-list-item') {
            this.props.onChange(RichUtils.toggleBlockType(this.props.editorState, blockType))
        } else if (RichUtils.getCurrentBlockType(this.props.editorState) !== blockType) {
            this.props.onChange(RichUtils.toggleBlockType(this.props.editorState, blockType))
        }
    }

    toggleColorDialog = () => {
        this.setState({ showColorPicker: !this.state.showColorPicker })
        const selection = this.props.editorState.getSelection()
        const editorState = EditorState.forceSelection(this.props.editorState, selection)
        this.props.onChange(editorState)
    }

    toggleLinkDialog = () => {
        if (this.state.showUrlDialog) {
            this.setState({ url: '', showUrlDialog: false })
            this.props.setErrorMessage('')
            return
        }
        const selection = this.props.editorState.getSelection()
        if (!selection.isCollapsed()) {
            this.setAnimationTimeout()
            this.setState({ showUrlDialog: true, showVariableDialog: false })
            this.props.setErrorMessage('')
            const editorState = EditorState.forceSelection(this.props.editorState, selection)
            this.props.onChange(editorState)
            const contentState = editorState.getCurrentContent()
            const startKey = editorState.getSelection().getStartKey()
            const startOffset = editorState.getSelection().getStartOffset()
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey)
                const url = linkInstance.getData().url
                this.setState({ url })
            }
        } else {
            this.setState({ showVariableDialog: false })
            this.props.setErrorMessage('Please select text for the link')
        }
    }

    removeLink = () => {
        const selection = this.props.editorState.getSelection()
        if (!selection.isCollapsed()) {
            this.props.onChange(RichUtils.toggleLink(this.props.editorState, selection, null))
        }
        this.setState({ url: '', showUrlDialog: false })
        this.props.setErrorMessage('')
    }

    addLink = () => {
        if (!this.checkUrlValidity()) {
            return
        }
        const contentState = this.props.editorState.getCurrentContent()
        const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
            url: this.state.url,
            target: '_blank',
        })
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
        const newEditorState = EditorState.set(this.props.editorState, {
            currentContent: contentStateWithEntity,
        })
        this.props.onChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey))
        this.setState({ url: '', showUrlDialog: false })
    }

    toggleVariableDialog = () => {
        if (this.state.showVariableDialog) {
            this.setState({ showVariableDialog: false })
            return
        }
        this.setState({ showVariableDialog: true, showUrlDialog: false })
        this.props.setErrorMessage('')
    }

    onInsertVariable = (v: string) => {
        const selection = this.props.editorState.getSelection()
        const contentState = this.props.editorState.getCurrentContent()
        let newEditorState = EditorState.createEmpty()
        const newContentState = selection.isCollapsed()
            ? Modifier.insertText(contentState, selection, v)
            : Modifier.replaceText(contentState, selection, v)
        newEditorState = EditorState.push(this.props.editorState, newContentState, 'insert-characters')
        this.props.onChange(newEditorState)
    }

    undo = () => {
        this.props.onChange(EditorState.undo(this.props.editorState))
    }

    redo = () => {
        this.props.onChange(EditorState.redo(this.props.editorState))
    }

    onLinkUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ url: e.target.value })
    }

    checkUrlValidity = () => {
        if (!this.state.url.trim()) {
            this.props.setErrorMessage('Please enter a target URL')
            return false
        } else {
            this.props.setErrorMessage('')
            return true
        }
    }

    render() {
        const { visible, editorState, style } = this.props
        const blockType = RichUtils.getCurrentBlockType(editorState)
        const activeAlignment = alignments.find((a) => a.id === blockType) || alignments[0]

        const classes = []
        if (visible) {
            classes.push('visible')
        }
        if (visible && !this.state.animating) {
            classes.push('visible-animation-done')
        }
        if (visible && (this.state.showUrlDialog || this.state.showVariableDialog)) {
            classes.push('with-dialog')
        }

        const showDialog =
            this.props.visible && !this.state.animating && (this.state.showUrlDialog || this.state.showVariableDialog)

        return (
            <ToolbarContainer className={classes.join(' ')} style={style}>
                <ToolbarButtons>
                    <ToggleButton
                        icon={boldIcon}
                        active={editorState.getCurrentInlineStyle().has('BOLD')}
                        onClick={() => this.toggleStyle('BOLD')}
                    />
                    <ToggleButton
                        icon={italicIcon}
                        active={editorState.getCurrentInlineStyle().has('ITALIC')}
                        onClick={() => this.toggleStyle('ITALIC')}
                    />
                    <ToggleButton
                        icon={underlineIcon}
                        active={editorState.getCurrentInlineStyle().has('UNDERLINE')}
                        onClick={() => this.toggleStyle('UNDERLINE')}
                    />
                    {!this.props.hideAlignmentOption && (
                        <AlignmentDropdownButton
                            icon={activeAlignment.icon}
                            items={alignments}
                            containerComponent={AlignmentList}
                            itemComponent={AlignmentItem}
                            onChange={(alignment) => this.setBlockType(alignment.id)}
                        />
                    )}
                    {this.props.withUnorderedList && (
                        <ToggleButton
                            icon={faList}
                            fontAwesome
                            active={RichUtils.getCurrentBlockType(this.props.editorState) === 'unordered-list-item'}
                            onClick={() => this.setBlockType('unordered-list-item')}
                        />
                    )}
                    {this.props.withOrderedList && (
                        <ToggleButton
                            icon={faListOl}
                            fontAwesome
                            active={RichUtils.getCurrentBlockType(this.props.editorState) === 'ordered-list-item'}
                            onClick={() => this.setBlockType('ordered-list-item')}
                        />
                    )}
                    {this.props.withLink && (
                        <ToggleButton
                            icon={linkIcon}
                            active={this.state.showUrlDialog}
                            onClick={this.toggleLinkDialog}
                        />
                    )}
                    {this.props.customVariables && this.props.customVariables.length > 0 && (
                        <ToggleButton
                            icon={variableIcon}
                            active={this.state.showVariableDialog}
                            onClick={this.toggleVariableDialog}
                        />
                    )}
                    {this.props.withColor && (
                        <ToggleButton
                            icon={faPalette}
                            fontAwesome
                            active={this.state.showColorPicker || this.props.styles.color.current(editorState)}
                            onClick={this.toggleColorDialog}
                        />
                    )}
                    <Spacer />
                    <ToggleButton
                        icon={undoIcon}
                        active={false}
                        disabled={editorState.getUndoStack().isEmpty()}
                        onClick={this.undo}
                    />
                    <ToggleButton
                        icon={redoIcon}
                        active={false}
                        disabled={editorState.getRedoStack().isEmpty()}
                        onClick={this.redo}
                    />
                </ToolbarButtons>
                <ToolbarDialog className={showDialog ? 'visible' : ''}>
                    {this.state.showUrlDialog && (
                        <>
                            <Input
                                value={this.state.url}
                                onChange={this.onLinkUrlChange}
                                placeholder="Add URL..."
                                onFinishTyping={this.checkUrlValidity}
                            />
                            <div>
                                <ActionButton
                                    secondary
                                    size="small"
                                    style={{ marginRight: '1em' }}
                                    onClick={this.removeLink}
                                >
                                    Remove
                                </ActionButton>
                                <ActionButton secondary size="small" kind="action" onClick={this.addLink}>
                                    Add
                                </ActionButton>
                            </div>
                        </>
                    )}
                    {this.state.showVariableDialog && this.props.customVariables && (
                        <VariableContainer>
                            {this.props.customVariables.map((v, i) => (
                                <Variable key={i} onClick={() => this.onInsertVariable(v)}>
                                    {v}
                                </Variable>
                            ))}
                        </VariableContainer>
                    )}
                </ToolbarDialog>
                <ColorPickerPopup
                    visible={this.props.visible && this.state.showColorPicker}
                    top={-250}
                    left={this.props.colorPickerOnRight ? 340 : undefined}
                    selectedColor={this.state.color}
                    recommendedColors={[]}
                    showRecommendedColors={false}
                    handleChangeComplete={(c) => this.setState({ color: c.hex })}
                    onAcceptSelectedColor={this.toggleColor}
                    hide={() => this.setState({ showColorPicker: false })}
                />
            </ToolbarContainer>
        )
    }
}
