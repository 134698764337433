import { HttpService } from 'http/httpService'
import { Result, success, failure } from 'result'
import { LoggingService, EventType, ActionEventData } from 'http/loggingService'

export interface OpeningTimesUpdateRequest {
    openingTime: string
    closingTime: string
    sellUntilTime: string | null
}

export interface OpeningTimesListItem extends OpeningTimesUpdateRequest {
    id: string
    locationName: string
}
export interface Exception {
    id: string
    name: string
    openingTime: string
    closingTime: string
    sellUntilTime: string | null
    availableFrom: string | null
    availableTo: string | null
    weekdays: string[]
    locationClosed?: boolean
}

export interface CreateExceptionError {
    code: 'exceptions_overlap' | 'server_error' | 'unknown'
    message?: string
    statusCode?: number
}

export interface OpeningTimesUnit extends OpeningTimesListItem {
    exceptions: Exception[]
}

export class OpeningTimesService {
    constructor(
        private httpService: HttpService,
        private loggingService: LoggingService,
        private backofficeEndpoint: string,
    ) {}

    async getOpeningTimes(accountSlug: string): Promise<OpeningTimesListItem[]> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/opening_times/`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch opening times')
        }
        return await response.json()
    }

    async getOpeningTimesUnit(accountSlug: string, id: string): Promise<OpeningTimesUnit> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/opening_times/${id}/`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch opening times')
        }
        return await response.json()
    }

    async updateOpeningTimesUnit(
        accountSlug: string,
        data: OpeningTimesUpdateRequest,
        id: string,
        locationName: string,
    ): Promise<OpeningTimesUnit> {
        const logEventType: EventType = 'opening_times_updated'
        const logEventData: ActionEventData = {
            category: 'opening_times',
            payload: { ...data, id, locationName },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/opening_times/${id}/`,
            {
                method: 'PUT',
                body: JSON.stringify({ ...data }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to update the opening times unit')
        }

        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async listLocations(accountSlug: string): Promise<OpeningTimesListItem[]> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/opening_times/`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch locations')
        }
        return await response.json()
    }

    async getException(accountSlug: string, defaultId: string, id: string): Promise<Exception> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/opening_times/${defaultId}/exceptions/${id}/`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch exception')
        }
        return await response.json()
    }

    async createException(
        accountSlug: string,
        data: Exception,
        id: string,
    ): Promise<Result<Exception, CreateExceptionError>> {
        const logEventType: EventType = 'opening_times_exception_created'
        const logEventData: ActionEventData = {
            category: 'opening_times',
            payload: { ...data, openingTimesId: id },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/opening_times/${id}/exceptions/`,
            {
                method: 'POST',
                body: JSON.stringify({ ...data }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        const body = await response.json()
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            if (!!body.error) {
                try {
                    const errorObj = JSON.parse(body.error['message'])
                    const errorType = errorObj['code']
                    if (errorType === 'exceptions_overlap') {
                        return failure({
                            code: errorType,
                            message: errorObj,
                        } as CreateExceptionError)
                    } else {
                        return failure({
                            code: 'unknown',
                            message: 'Unknown error',
                        } as CreateExceptionError)
                    }
                } catch {
                    return failure({
                        code: 'unknown',
                        message: body.error['message'],
                    } as CreateExceptionError)
                }
            }
        }

        this.loggingService.logAction(logEventType, {
            ...logEventData,
            payload: { ...logEventData.payload, id: body.id },
        })
        return success(body)
    }

    async updateException(
        accountSlug: string,
        data: Exception,
        defaultId: string,
        id: string,
    ): Promise<Result<Exception, CreateExceptionError>> {
        const logEventType: EventType = 'opening_times_exception_updated'
        const logEventData: ActionEventData = {
            category: 'opening_times',
            payload: { ...data, openingTimesId: defaultId },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/opening_times/${defaultId}/exceptions/${id}/`,
            {
                method: 'PUT',
                body: JSON.stringify({ ...data }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        const body = await response.json()

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            if (!!body.error) {
                try {
                    const errorObj = JSON.parse(body.error['message'])
                    const errorType = errorObj['code']
                    if (errorType === 'exceptions_overlap') {
                        return failure({
                            code: errorType,
                            message: errorObj,
                        } as CreateExceptionError)
                    } else {
                        return failure({
                            code: 'unknown',
                            message: 'Unknown error',
                        } as CreateExceptionError)
                    }
                } catch {
                    return failure({
                        code: 'unknown',
                        message: body.error['message'],
                    } as CreateExceptionError)
                }
            }
        }

        this.loggingService.logAction(logEventType, logEventData)
        return success(body)
    }

    async deleteException(accountSlug: string, defaultId: string, id: string): Promise<void> {
        const logEventType: EventType = 'opening_times_exception_deleted'
        const logEventData: ActionEventData = {
            category: 'opening_times',
            payload: { id, openingTimesId: defaultId },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/opening_times/${defaultId}/exceptions/${id}/`,
            {
                method: 'DELETE',
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to delete exception')
        }

        this.loggingService.logAction(logEventType, logEventData)
    }
}
