import { RootAction } from 'store'
import { getType } from 'typesafe-actions'
import { createInitialFeatureState, FeatureState } from './state'
import { defaultFeaturesLoaded, accountFeaturesLoaded } from './actions'

export function featureReducer(state: Readonly<FeatureState>, action: RootAction): FeatureState {
    state = state || createInitialFeatureState()

    switch (action.type) {
        case getType(defaultFeaturesLoaded):
            return {
                ...state,
                defaults: action.payload.features,
            }
        case getType(accountFeaturesLoaded):
            return {
                ...state,
                forAccount: {
                    ...state.forAccount,
                    [action.payload.accountSlug]: action.payload.features,
                },
            }
        default:
            return state
    }
}
