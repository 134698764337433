import { HttpService } from 'http/httpService'
import { ActionEventData, EventType, LoggingService } from 'http/loggingService'
import { PNApp, PNCampaign } from './types'

export class AngeliaNotificationsService {
    private APP_PATH = 'app'
    private CAMPAIGN_PATH = 'campaign'

    constructor(
        private httpService: HttpService,
        private loggingService: LoggingService,
        private awsNotificationsEndpoint: string,
    ) {}

    async createApp(app: PNApp): Promise<PNApp> {
        const logEventType: EventType = 'create_aws_pinpoint_app'
        const logEventData: ActionEventData = {
            category: 'notifications',
            payload: { app },
        }

        let response = await this.httpService.fetch(`${this.awsNotificationsEndpoint}${this.APP_PATH}/${app.slug}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(app),
        })

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async getApp(slug: string): Promise<any> {
        const logEventType: EventType = 'get_aws_pinpoint_app'
        const logEventData: ActionEventData = {
            category: 'notifications',
            payload: { slug },
        }

        let response = await this.httpService.fetch(`${this.awsNotificationsEndpoint}${this.APP_PATH}/${slug}`)

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async deleteApp(slug: string): Promise<void> {
        const logEventType: EventType = 'delete_aws_pinpoint_app'
        const logEventData: ActionEventData = {
            category: 'notifications',
            payload: { slug },
        }

        let response = await this.httpService.fetch(`${this.awsNotificationsEndpoint}${this.APP_PATH}/${slug}`)

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async getCampaignsList(slug: string): Promise<any> {
        const logEventType: EventType = 'get_notifications_campaigns'
        const logEventData: ActionEventData = {
            category: 'notifications',
            payload: { slug },
        }

        let response = await this.httpService.fetch(
            `${this.awsNotificationsEndpoint}${this.CAMPAIGN_PATH}/list/${slug}`,
        )

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async getCampaign(slug: string, campaignId: string): Promise<any> {
        const logEventType: EventType = 'get_notifications_campaign'
        const logEventData: ActionEventData = {
            category: 'notifications',
            payload: { campaignId },
        }

        let response = await this.httpService.fetch(
            `${this.awsNotificationsEndpoint}${this.CAMPAIGN_PATH}/${slug}?campaign_id=${campaignId}`,
        )

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async createCampaign(campaign: PNCampaign): Promise<any> {
        const logEventType: EventType = 'create_notifications_campaign'
        const logEventData: ActionEventData = {
            category: 'notifications',
            payload: { campaign },
        }

        let response = await this.httpService.fetch(
            `${this.awsNotificationsEndpoint}${this.CAMPAIGN_PATH}/${campaign.slug}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(campaign),
            },
        )

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async updateCampaign(campaign: PNCampaign): Promise<any> {
        const logEventType: EventType = 'update_notifications_campaign'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload: { campaign },
        }

        let response = await this.httpService.fetch(
            `${this.awsNotificationsEndpoint}${this.CAMPAIGN_PATH}/${campaign.slug}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(campaign),
            },
        )

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async deleteCampaign(slug: string, campaignId: string): Promise<void> {
        const logEventType: EventType = 'delete_notifications_campaign'
        const logEventData: ActionEventData = {
            category: 'notifications',
            payload: { campaignId },
        }

        let response = await this.httpService.fetch(
            `${this.awsNotificationsEndpoint}${this.CAMPAIGN_PATH}/${slug}?campaign_id=${campaignId}`,
            {
                method: 'DELETE',
            },
        )

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }
}
