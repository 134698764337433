import styled from 'styled-typed'
import { SmallText } from 'uiComponents/typography'
import { DataRow } from 'uiComponents/table'

export const StatusAndText = styled.div`
    display: flex;
    align-items: flex-end;
    > ${SmallText} {
        margin-left: -2em;
    }
`

export const DataRowAutoHeight = styled(DataRow)`
    height: auto;
    padding: 0.75em 0;
`

export const Container = styled.div`
    display: flex;
    flex: 1;
    margin: 10px;
    word-break: break-word;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 20px);
`

export const FormHeader = styled.div`
    height: 6em;
    display: table;
    text-align: center;
    & > span {
        font-size: 1.3em;
        padding: 0 0.4em;
        line-height: 1.5em;
        display: table-cell;
        vertical-align: middle;
    }
`

export const FormDescription = styled.div`
    height: 5em;
    padding: 0.7em;
    color: ${(props) => props.theme.colors.textDark};
    & span {
        font-size: 0.75em;
    }
`

export const PushNotificationContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: white;
    padding: 0.75em 0.5em;
`

export const PushNotificationHeader = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
`

export const InboxMessageContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    flex-direction: column;
`

export const InboxMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    padding: 0.75em 0.5em;
    border-radius: 16px;
`

export const InboxMessageButton = styled.div`
    background: red;
    border-radius: 20px;
    margin: 5px 0;
    padding: 0.75em;
    display: flex;
    justify-content: center;
    & span {
        color: white;
    }
`
