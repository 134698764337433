import React from 'react'
import { Tree } from 'uiComponents/tree'
import { OptionsList, OptionsListItem } from './common'
import { SingleSelectOption } from './singleSelect'

export interface SingleSelectOptionsListProps {
    options: SingleSelectOption[]
    selected: string | null
    maxHeight?: string
    setListRef: any
    onOptionClick: (e: React.MouseEvent, option: SingleSelectOption) => void
}
const PADDING = 30

const getDepth = (depth: number) => {
    if (!!depth || depth - 1 > 0) {
        return (depth - 1) * PADDING
    }

    return 0
}

const NestedSelectOptionsList: React.FC<SingleSelectOptionsListProps> = ({
    options,
    maxHeight,
    selected,
    setListRef,
    onOptionClick,
}) => {
    return (
        <OptionsList maxHeight={maxHeight} ref={setListRef}>
            <Tree
                data={options}
                idField="value"
                parentField="parentId"
                component={({ data, depth, children }) => {
                    return (
                        <>
                            <OptionsListItem
                                key={data.value}
                                selected={data.value === selected}
                                onClick={(e) => onOptionClick(e, data)}
                                title={data.name}
                                disabled={data.disabled}
                            >
                                <div style={{ display: 'inline-block', width: getDepth(depth) }} />
                                {data.name}
                            </OptionsListItem>
                            {children}
                        </>
                    )
                }}
            />
        </OptionsList>
    )
}

export default React.memo(NestedSelectOptionsList)
