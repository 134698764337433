import { HttpService } from 'http/httpService'
import {
    Promotion,
    PromotionService,
    PromotionListResponse,
    PromotionWeightUpdatePayload,
    PromotionAddPayload,
    PromotionGetResponse,
} from './types'
import queries from './queries'

export default class Service implements PromotionService {
    constructor(private httpService: HttpService, private endpoint: string) {}
    fetch(id: string, accountSlug: string): Promise<Promotion> {
        throw new Error('Method not implemented.')
    }

    async update(id: string, promotion: PromotionAddPayload): Promise<boolean> {
        const query = queries.updatePromotion

        const body = JSON.stringify({
            operationName: 'UpdatePromotion',
            query,
            variables: {
                promotion: {
                    ...promotion,
                    id: id,
                },
            },
        })

        const payload = await this.handleFetch(body)
        return !!payload
    }

    async updateWeight(slug: string, promotions: PromotionWeightUpdatePayload[]): Promise<PromotionListResponse[]> {
        const query = queries.updatePromotionsWeight

        const body = JSON.stringify({
            operationName: 'UpdatePromotionsWeight',
            query,
            variables: {
                slug: slug,
                promotions: promotions,
            },
        })

        const payload = await this.handleFetch(body)
        return payload.data.updatePromotionsWeight as PromotionListResponse[]
    }

    async delete(id: string, accountSlug: string): Promise<boolean> {
        const query = queries.deletePromotion

        const body = JSON.stringify({
            operationName: 'DeletePromotion',
            query,
            variables: {
                slug: accountSlug,
                id: id,
            },
        })

        const payload = await this.handleFetch(body)
        return payload
    }

    async fetchAll(slug: string): Promise<PromotionListResponse[]> {
        const query = queries.getPromotions

        const body = JSON.stringify({
            operationName: 'GetPromotions',
            query,
            variables: {
                slug: slug,
            },
        })

        const payload = await this.handleFetch(body)
        return payload.data.getPromotions as PromotionListResponse[]
    }

    async add(promotion: PromotionAddPayload): Promise<boolean> {
        const query = queries.addPromotion

        const body = JSON.stringify({
            operationName: 'AddPromotion',
            query,
            variables: {
                promotion: promotion,
            },
        })

        const payload = await this.handleFetch(body)
        return payload
    }

    async get(slug: string, id: string): Promise<PromotionGetResponse> {
        const query = queries.getPromotion

        const body = JSON.stringify({
            operationName: 'GetPromotion',
            query,
            variables: {
                id: id,
                slug: slug,
            },
        })

        const payload = await this.handleFetch(body)
        return payload.data.getPromotion
    }

    private async handleFetch(body: BodyInit): Promise<any> {
        const response = await this.httpService.fetch(`${this.endpoint}`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Request failed with status code: ${response.status}`)
        }

        const payload = await response.json()
        return payload
    }
}
