import { HttpService } from 'http/httpService'
import { ActionEventData, EventType, LoggingService } from 'http/loggingService'
import { ICustomAudience, IFilter } from './types'

export class AudienceService {
    constructor(
        private httpService: HttpService,
        private loggingService: LoggingService,
        private audienceBuilderEndpoint: string,
    ) {}

    async getAudiences(slug: string): Promise<ICustomAudience[]> {
        const logEventType: EventType = 'get_custom_audience_list'
        const logEventData: ActionEventData = {
            category: 'custom_audience',
            payload: { slug },
        }

        let response = await this.httpService.fetch(`${this.audienceBuilderEndpoint}audience/${slug}/list`, {
            method: 'GET',
        })

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async getFilters(slug: string): Promise<IFilter[]> {
        const logEventType: EventType = 'get_custom_audience'
        const logEventData: ActionEventData = {
            category: 'custom_audience',
            payload: {},
        }

        let response = await this.httpService.fetch(`${this.audienceBuilderEndpoint}audience/${slug}/options`, {
            method: 'GET',
        })

        return this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async getAudience(id: string, slug: string): Promise<ICustomAudience> {
        const logEventType: EventType = 'get_custom_audience'
        const logEventData: ActionEventData = {
            category: 'custom_audience',
            payload: { id },
        }

        let response = await this.httpService.fetch(`${this.audienceBuilderEndpoint}audience/${slug}?id=${id}`, {
            method: 'GET',
        })

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async createAudience(audience: ICustomAudience): Promise<ICustomAudience> {
        const logEventType: EventType = 'create_custom_audience'
        const logEventData: ActionEventData = {
            category: 'custom_audience',
            payload: { audience },
        }

        let response = await this.httpService.fetch(
            `${this.audienceBuilderEndpoint}audience/${audience.account_slug}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(audience),
            },
        )

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async updateAudience(audience: ICustomAudience): Promise<ICustomAudience> {
        const logEventType: EventType = 'update_custom_audience'
        const logEventData: ActionEventData = {
            category: 'custom_audience',
            payload: { audience },
        }

        let response = await this.httpService.fetch(
            `${this.audienceBuilderEndpoint}audience/${audience.account_slug}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(audience),
            },
        )

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async deleteAudience(audienceId: number, slug: string): Promise<void> {
        const logEventType: EventType = 'delete_custom_audience'
        const logEventData: ActionEventData = {
            category: 'custom_audience',
            payload: { audienceId },
        }

        let response = await this.httpService.fetch(
            `${this.audienceBuilderEndpoint}audience/${slug}?id=${audienceId}`,
            {
                method: 'DELETE',
            },
        )

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async getExportAudienceEndpoint(audienceId: number, slug: string): Promise<string> {
        const logEventType: EventType = 'get_export_custom_audience_endpoint'
        const logEventData: ActionEventData = {
            category: 'custom_audience',
            payload: { audienceId },
        }

        let response = await this.httpService.fetch(
            `${this.audienceBuilderEndpoint}audience/${slug}/export/${audienceId}`,
            {
                method: 'GET',
            },
        )

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }
}
