import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { adminApi } from 'admin/reduxQuery'
import { routerMiddleware } from 'connected-react-router'
import { engageToolsApi } from 'engageTools/reduxApi'
import { ComponentsService } from 'engageTools/studio/componentsService'
import { FeatureService } from 'features/featureService'
import { History } from 'history'
import { ImpersonationService } from 'http/impersonationService'
import { statsApi } from 'http/reduxQuery'
import { ordersApi } from 'orders/reduxQueries'
import { pricingApi } from 'products/pricing/reduxQueries'
import { articleApi } from 'products/reduxApi'
import { Store } from 'redux'
import { integrationsApi } from 'settings/reduxQuery'
import { discountUploadMiddleware } from 'venue/discountUploadMiddleware'
import { parkMapApi } from 'venue/parkMap/reduxQuery'
import { bookingCodeApi } from 'venue/reduxQuery'
import { audienceApi } from '../crm/audiences/reduxQuery'
import { pushNotificationsApi } from '../pushNotifications/reduxQuery'
import { State } from './interface'
import { supersetApi } from 'reports/supersetQuery'
import { allReducers } from './reducers'
import { taxConfigurationsApi } from 'settings/accountSettings/taxConfigurations/rtkQuery'

export interface StoreServices {
    featureService: FeatureService
    componentsService: ComponentsService
    impersonationService: ImpersonationService
    history: History
}

export function createReduxStore(initialState: State, storeServices: StoreServices): Store<State, any> {
    const store = configureStore({
        reducer: allReducers(storeServices.history),
        preloadedState: initialState,
        devTools: process.env.NODE_ENV !== 'production',
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ thunk: { extraArgument: storeServices }, serializableCheck: false })
                .concat(engageToolsApi.middleware)
                .concat(ordersApi.middleware)
                .concat(bookingCodeApi.middleware)
                .concat(integrationsApi.middleware)
                .concat(articleApi.middleware)
                .concat(routerMiddleware(storeServices.history))
                .concat(integrationsApi.middleware)
                .concat(parkMapApi.middleware)
                .concat(audienceApi.middleware)
                .concat(pricingApi.middleware)
                .concat(statsApi.middleware)
                .concat(adminApi.middleware)
                .concat(pushNotificationsApi.middleware)
                .concat(supersetApi.middleware)
                .concat(taxConfigurationsApi.middleware)
                .prepend(discountUploadMiddleware.middleware),
    })

    setupListeners(store.dispatch)

    const nModule = module as any
    if (nModule.hot) {
        // Enable Webpack hot module replacement for reducers
        nModule.hot.accept('./reducers', () => {
            const newReducers = require('./reducers')
            store.replaceReducer(newReducers)
        })
    }

    return store
}
