import styled from '../../styled-typed'
import { Body } from '../../uiComponents/typography'
import Svg from '../../svg'

export const Title = styled(Body)`
    color: ${(props) => props.theme.colors.aluminium};
    margin-bottom: 0.25rem;
`

export const TypeIcon = styled(Svg)`
    flex-shrink: 0;
    margin-right: 0.25rem;
    width: 1.2rem;
    height: 1.2rem;
`
