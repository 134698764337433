import { HttpService } from 'http/httpService'

interface EmailData {
    devEmail: string
    url: string
    widgetSlug: string
}

export class SnippetService {
    constructor(
        private httpService: HttpService,
        private dashboardApiendpoint: string,
        private experimentsEndpoint: string,
    ) {}

    async sendSnippetToDev(emailData: EmailData): Promise<void> {
        const response = await this.httpService.fetch(`${this.dashboardApiendpoint}profile/send_script_to_dev/`, {
            method: 'POST',
            body: JSON.stringify(emailData),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (response.status !== 200) {
            throw new Error('Oops! Something went wrong, please try again.')
        }
    }

    async detectSnippet(accountSlug: string): Promise<void> {
        const response = await this.httpService.fetch(
            `${this.experimentsEndpoint}experiments/api/v1/loader_status/${accountSlug}/`,
            {
                method: 'GET',
            },
        )
        if (response.status === 404) {
            throw new Error('script not found')
        } else if (response.status !== 200) {
            throw new Error('Oops! Something went wrong, please try again.')
        }
    }
}
