import React, { FC } from 'react'
import { OptionsList } from './common'
import { SingleSelectOption as SingleSelectOptionType } from './singleSelect'
import NestedSelectOptionsList from './nestedSelectOption'
import { default as DefaultSingleSelectOption, SingleSelectOptionProps } from './singleSelectOption'

export interface SingleSelectOptionsListProps {
    options: SingleSelectOptionType[]
    selected: string | null
    maxHeight?: string
    focusedItem: number
    setListRef: any
    onOptionClick: (e: React.MouseEvent, option: SingleSelectOptionType) => void
    nested?: boolean
    SingleSelectOption?: FC<SingleSelectOptionProps>
}

const SingleSelectOptionsList: React.FC<SingleSelectOptionsListProps> = ({
    options,
    maxHeight,
    focusedItem,
    selected,
    setListRef,
    onOptionClick,
    nested,
    SingleSelectOption = DefaultSingleSelectOption,
}) => {
    if (nested) {
        return (
            <NestedSelectOptionsList
                onOptionClick={onOptionClick}
                options={options}
                maxHeight={maxHeight}
                selected={selected}
                setListRef={setListRef}
            />
        )
    }

    return (
        <OptionsList maxHeight={maxHeight} ref={setListRef}>
            {options.map((opt, i) => (
                <SingleSelectOption
                    option={opt}
                    key={`${opt.value}-${opt.name}-${opt.parentId}`} // Not doing JSON.stringify(opt.value) to save performance
                    selected={opt.value === selected}
                    onClick={(e) => onOptionClick(e, opt)}
                    focused={focusedItem === i}
                />
            ))}
        </OptionsList>
    )
}

export default React.memo(SingleSelectOptionsList)
