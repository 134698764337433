import React, { CSSProperties, FC } from 'react'
import { HistoryRecord, Status } from '../types'
import styled from 'styled-typed'
import { Body } from 'uiComponents/typography'
import classNames from 'classnames'
import { ColorName, getColorForStatus, getStatusSettings } from '../status'
import { TypeIcon } from '../common-ui'

export const Container = styled.div<{ isSelected: boolean; status: Status }>`
    background-color: ${(props) => getColorForStatus(props.status, props.theme, 'bgColor') ?? props.theme.colors.white};
    border-top: 1px solid
        ${(props) => (props.isSelected ? getColorForStatus(props.status, props.theme) : props.theme.colors.background)};
    border-bottom: 1px solid
        ${(props) => (props.isSelected ? getColorForStatus(props.status, props.theme) : props.theme.colors.background)};
    padding: 0.25rem 0.75rem;
    min-height: 1.875rem;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    cursor: pointer;
    .details {
        display: ${(props) => (props.isSelected ? 'block' : 'none')}};
    }
`

const Message = styled(Body)<{ textColor: ColorName }>`
    color: ${(props) => props.theme.colors[props.textColor]};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.details {
        overflow: auto;
        text-overflow: unset;
        white-space: unset;
    }
    ul {
        margin: 0;
        list-style-position: inside;
        padding-left: 0;
        li {
            padding-left: 0;
        }
    }
`

export const Code = styled(Body)`
    color: ${(props) => props.theme.colors.aluminium};
    margin-right: 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95px;
`

interface Props {
    className?: string
    style?: CSSProperties
    record: HistoryRecord
    onClick?: () => void
    isSelected?: boolean
}

const HistoryItem: FC<Props> = ({ className, record, onClick, style, isSelected = false }) => {
    const { historyIcon, message, color } = getStatusSettings(record.type)
    const codes = record.codes ?? [record.code]

    return (
        <Container
            status={record.type}
            isSelected={isSelected}
            onClick={onClick}
            className={classNames('history-item', className)}
            style={style}
        >
            {!isSelected ? (
                <>
                    <Code size={2}>{record.code}</Code>
                    <TypeIcon src={historyIcon} />
                    <Message size={2} textColor={color}>
                        {message}
                    </Message>
                </>
            ) : (
                <>
                    <Message size={2} textColor={color} className="details">
                        <div>
                            <strong>Code(s):</strong>
                            <ul>
                                {codes.map((code) => (
                                    <li key={code}>{code}</li>
                                ))}
                            </ul>
                        </div>
                        <div>
                            <strong>Status:</strong>
                        </div>
                        <div>{message}</div>
                        {record.message !== message && (
                            <>
                                <div>
                                    <strong>Details:</strong>
                                </div>
                                <div>{record.message}</div>
                            </>
                        )}
                    </Message>
                </>
            )}
        </Container>
    )
}

export default HistoryItem
