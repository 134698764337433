import React, { useState, useEffect, useRef, useReducer } from 'react'
import styled from 'styled-typed'
import { useParams } from 'react-router'
import { TextInput, Toggle } from 'uiComponents/input'
import {
    GetQuestionsSuccess,
    GetQuestionsError,
    Question,
    GroupRedeemSuccess,
    CreateExceptionBarcodeError,
    RedeemSuccess,
    GroupRedeemError,
} from 'orders/ordersService'
import { delay } from 'utils'
import { BaseRouteParams } from 'hocs'
import { AppServices } from 'middleware'
import { GroupRedeemModeQuestionnaire } from './groupRedeemModeQuestionnaire'
import RedeemHistory from './history/history'
import { HistoryRecord, HistoryRecordParams, BarcodeRedeemErrorCode, Status } from './types'
import { ColorName, getErrorKey, getStatusByErrorCode, getStatusSettings } from './status'
import { Title, TypeIcon } from './common-ui'
import whiteSpinner from './icons/white/spinner.svg'
import { isLocalStorageAvailable } from 'utils/storage'

const Container = styled.div`
    margin-top: 30px;
    .barcode-scanner {
        display: flex;
        & > section {
            width: 50%;
        }
        align-items: flex-start;
    }

    @media only screen and (min-width: 768px) {
        width: 45rem;
        padding: 0 1rem;
        .barcode-scanner {
            flex-direction: row;
            align-items: stretch;
            .history-section {
                padding-left: 1rem;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .barcode-scanner {
            flex-direction: column;
            & > section {
                width: calc(95vw - 3rem);
            }
        }
    }
`

const Input = styled(TextInput)`
    width: 100%;
    height: 2.8em;
    font-size: 1.5em;
    font-weight: bold;
    letter-spacing: 0.16em;
    border-radius: 4px;
    background: white;
    border: 2px solid ${(props) => props.theme.colors.boyBlue};
    &:focus {
        border: 2px solid ${(props) => props.theme.colors.boyBlue};
    }
    &[disabled] {
        border-color: ${(props) => props.theme.colors.border};
    }
    &[disabled].success {
        color: ${(props) => props.theme.colors.status.success};
        border-color: ${(props) => props.theme.colors.status.success};
    }
    &[disabled].error {
        color: ${(props) => props.theme.colors.status.error};
        border-color: ${(props) => props.theme.colors.status.error};
    }
`

const InfoBox = styled.div<{ bgColor: ColorName }>`
    margin-top: 10px;
    padding: 0.5em;
    min-height: 2.5em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.white};
    border-radius: 4px;
    background: ${(props) => props.theme.colors[props.bgColor]};
    > .message {
        margin-left: 0.5em;
    }
`

const persistUseGroupRedeemMode = (useGroupRedeemMode: boolean) => {
    if (isLocalStorageAvailable()) {
        window.localStorage.setItem('useGroupRedeemMode', JSON.stringify(useGroupRedeemMode))
    }
}

const getUseGroupRedeemMode = (): boolean => {
    if (isLocalStorageAvailable()) {
        const useGroupRedeemMode = window.localStorage.getItem('useGroupRedeemMode')
        return useGroupRedeemMode ? JSON.parse(useGroupRedeemMode) : false
    } else {
        return false
    }
}

interface GroupRedeemModeProps {
    getQuestions?: typeof AppServices.ordersService.getQuestions
    groupRedeemBarcodes?: typeof AppServices.ordersService.groupRedeemBarcodes
    redeemBarcodes?: typeof AppServices.ordersService.redeemBarcodes
}

const MAX_HISTORY_LENGTH = 100

function RedeemModeDialogContent({
    getQuestions = AppServices.ordersService.getQuestions,
    groupRedeemBarcodes = AppServices.ordersService.groupRedeemBarcodes,
    redeemBarcodes = AppServices.ordersService.redeemBarcodes,
}: GroupRedeemModeProps) {
    const inputRef = useRef<HTMLInputElement>(null)
    const { accountSlug } = useParams<BaseRouteParams>()
    const [barcode, setBarcode] = useState<string>('')
    const [questions, setQuestions] = useState<Question[]>([])
    const [status, setStatus] = useState<Status>('initial')
    const [inputDisabled, setInputDisabled] = useState<boolean>(false)
    const [historyRecords, addHistoryRecord] = useReducer(
        (state: HistoryRecord[], action: HistoryRecordParams): HistoryRecord[] => {
            return [new HistoryRecord(action), ...state.slice(0, MAX_HISTORY_LENGTH - 2)]
        },
        [],
    )
    const [selectedHistoryItem, setSelectedHistoryItem] = useReducer((state: number, action: number) => {
        if (action === state) {
            return -1
        }
        return action
    }, -1)
    const [useGroupRedeemMode, setUseGroupRedeemMode] = useState<boolean>(getUseGroupRedeemMode())
    const useGroupRedeemModeRef = useRef(useGroupRedeemMode)

    useEffect(() => {
        useGroupRedeemModeRef.current = useGroupRedeemMode
        persistUseGroupRedeemMode(useGroupRedeemMode)
    }, [useGroupRedeemMode])

    const statusSettings = getStatusSettings(status)

    useEffect(() => {
        focusInput()
    }, [])

    const focusInput = () => {
        if (inputRef?.current) {
            inputRef.current.focus()
        }
    }

    const onFinishTypingSingleTicketBarcode = async (barcode: string) => {
        if (!barcode.trim()) {
            return
        }
        setStatus('loading')
        try {
            const result = await redeemBarcodes(accountSlug, [barcode])
            result
                .ifSuccess((response: RedeemSuccess) => onSingleTicketRedeemSuccess(response, barcode))
                .ifFailure((response: CreateExceptionBarcodeError) => onSingleTicketRedeemFailure(barcode, response))
        } catch {
            onSingleTicketRedeemFailure(barcode)
        }
    }

    const onSingleTicketRedeemFailure = (code: string, response: CreateExceptionBarcodeError | null = null) => {
        if (response) {
            const errorKey = getErrorKey(response)

            if (errorKey) {
                const status = getStatusByErrorCode(errorKey)
                updateAndResetStatus(status)
                return addHistoryRecord({
                    type: status,
                    code: response[errorKey],
                    message: response.error || 'Unknown error',
                })
            }
        } else {
            updateAndResetStatus('error')
            addHistoryRecord({
                type: 'error',
                code: code || '',
                message: 'Unknown error',
            })
        }
    }

    const onSingleTicketRedeemSuccess = (response: RedeemSuccess, code: string) => {
        if (!response.barcodes[code]) {
            onSingleTicketRedeemFailure(code)
            return
        }
        addHistoryRecord({ type: 'success', code, message: getStatusSettings('success').message })
        updateAndResetStatus('success')
    }

    const onFinishTypingGroupTicketBarcode = async (barcode: string) => {
        if (!barcode.trim()) {
            return
        }
        setStatus('loading')
        setInputDisabled(true)
        try {
            const result = await getQuestions(accountSlug, barcode.trim())
            result
                .ifSuccess((result: GetQuestionsSuccess) => onGetQuestionsSuccess(barcode, result))
                .ifFailure((result: GetQuestionsError) => {
                    onGetQuestionsFailure(barcode, result)
                })
        } catch {
            onGetQuestionsFailure(barcode)
        }
    }

    const onFinishTyping = (barcode: string) => {
        if (useGroupRedeemModeRef.current) {
            onFinishTypingGroupTicketBarcode(barcode)
        } else {
            onFinishTypingSingleTicketBarcode(barcode)
        }
    }

    const updateAndResetStatus = async (status: Status) => {
        setStatus(status)
        await delay(1000)
        setBarcode('')
        setInputDisabled(false)
        focusInput()
        await delay(1000)
        setStatus('initial')
    }

    const onGetQuestionsFailure = (barcode: string, error?: GetQuestionsError) => {
        let serverErrorMessage = error?.message ?? 'Redeem failed'

        if (error?.code === 'barcode_not_group_scannable') {
            onFinishTypingSingleTicketBarcode(barcode)
            return
        }
        const status = getStatusByErrorCode(error?.code as BarcodeRedeemErrorCode)
        addHistoryRecord({
            type: status,
            message: serverErrorMessage,
            code: barcode,
        })
        updateAndResetStatus(status)
    }

    const onGetQuestionsSuccess = (barcode: string, response: GetQuestionsSuccess) => {
        setQuestions(response.questions)
        setStatus('questionsPending')
    }

    const submitAnswers = async (answers: any[]) => {
        setStatus('loading')
        const articlesToRedeem = []
        for (const key in answers) {
            if (Object.prototype.hasOwnProperty.call(answers, key)) {
                articlesToRedeem.push({ article_uuid: key, amount_to_redeem: answers[key] })
            }
        }

        const result = await groupRedeemBarcodes(accountSlug, barcode, articlesToRedeem)
        result
            .ifSuccess((response: GroupRedeemSuccess) => {
                updateAndResetStatus('success')
                addHistoryRecord({
                    type: 'success',
                    message: `Successfully redeemed a total of ${Object.keys(response.barcodes).length} tickets`,
                    code: barcode,
                    codes: Object.keys(response.barcodes),
                })
            })
            .ifFailure((response: GroupRedeemError) => {
                addHistoryRecord({
                    type: 'error',
                    message: response.message || 'Unknown error',
                    code: barcode,
                    codes: Object.keys(response.barcodes as object) ?? [],
                })
                updateAndResetStatus('error')
            })
        setQuestions([])
    }

    const cancel = () => {
        setQuestions([])
        setBarcode('')
        setInputDisabled(false)
        setStatus('initial')
        focusInput()
    }

    return (
        <Container>
            {useGroupRedeemMode && questions.length ? (
                <GroupRedeemModeQuestionnaire
                    questions={questions}
                    submitAnswers={submitAnswers}
                    status={status}
                    cancel={cancel}
                />
            ) : (
                <>
                    <div className="barcode-scanner">
                        <section className="barcode-input-section">
                            <Title weight="medium" size={2}>
                                Scan a barcode to redeem
                            </Title>
                            <Input
                                maxLength={128}
                                className={status}
                                inputRef={inputRef}
                                value={barcode}
                                onChange={(e) => setBarcode(e.target.value)}
                                onFinishTyping={onFinishTyping}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        event.stopPropagation()
                                    }
                                }}
                                placeholder=" "
                                disabled={inputDisabled}
                                data-testid="orders-redeem-barcode-input"
                            />
                            <InfoBox
                                className={status}
                                bgColor={statusSettings.color}
                                data-testid="orders-redeem-button"
                            >
                                <TypeIcon
                                    src={statusSettings.statusIcon}
                                    className={statusSettings.statusIcon === whiteSpinner ? 'fa-pulse' : ''}
                                />
                                <div className="message">{statusSettings.message}</div>
                            </InfoBox>
                            <div
                                className="redeem-mode-toggle-container"
                                onClick={() => {
                                    setUseGroupRedeemMode(!useGroupRedeemMode)
                                }}
                            >
                                <h6>Group redeem</h6>
                                <Toggle
                                    isOn={useGroupRedeemMode}
                                    onClick={setUseGroupRedeemMode}
                                    className="smallInlineToggle"
                                    id="orders-group-redeem-toggle"
                                />
                                <div className="redeem-mode-toggle-info">(Supported tickets only)</div>
                            </div>
                        </section>
                        <section className="history-section" data-testid="orders-redeem-history">
                            <Title weight="medium" size={2}>
                                Previously scanned:
                            </Title>
                            <RedeemHistory
                                height="180px"
                                history={historyRecords}
                                onHistoryRecordIndexSelect={setSelectedHistoryItem}
                                selectedHistoryRecordIndex={selectedHistoryItem}
                            />
                        </section>
                    </div>
                </>
            )}
        </Container>
    )
}

export default RedeemModeDialogContent
