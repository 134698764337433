import React, { CSSProperties, FC } from 'react'
import { Form, Formik, FormikConfig } from 'formik'

interface FormWrapperProps<T = any> extends FormikConfig<T> {
    style?: CSSProperties
    className?: string
    formId?: string
    formName?: string
}

export const FormWrapper: FC<FormWrapperProps> = ({ children, style, formId, className, formName, ...rest }) => {
    if (typeof children === 'function') {
        return (
            <Formik validateOnChange={false} {...rest}>
                {(formikProps) => {
                    return (
                        <Form name={formName} id={formId} translate={undefined} className={className} style={style}>
                            {children(formikProps)}
                        </Form>
                    )
                }}
            </Formik>
        )
    }

    return (
        <Formik validateOnChange={false} {...rest}>
            <Form name={formName} id={formId} translate={undefined} className={className} style={style}>
                {children}
            </Form>
        </Formik>
    )
}
