import { FunctionComponent } from 'react'

export type FieldValue = string | ((data: any) => any)

export interface TreeLeafComponentProps {
    data: any
    component?: FunctionComponent
    idField: FieldValue
    depth: number
    style: React.CSSProperties
}

export const getField = (field: FieldValue, data?: any) => {
    if (!data) {
        return undefined
    }

    if (typeof field === 'function') {
        return field(data)
    }

    return data[field]
}

export const createDataTree = (dataset: any[], idField: FieldValue, parentField: FieldValue) => {
    if (!dataset || !idField || !parentField) {
        return []
    }

    const hashTable = Object.create(null)
    dataset.forEach((aData, index) => {
        hashTable[getField(idField, aData)] = { ...aData, childNodes: [], parentIds: [], original: aData, index }
    })
    const dataTree = [] as any[]

    dataset.forEach((aData) => {
        const parentId = getField(parentField, aData)
        const id = getField(idField, aData)

        if (hashTable?.[parentId]) {
            hashTable[parentId].childNodes.push(hashTable[id])
        } else {
            dataTree.push(hashTable[getField(idField, aData)])
        }
    })

    dataTree.forEach((branch) => {
        let relatives = [] as any[]
        const loopThrowChilds = (node: any) => {
            const parentId = getField(parentField, node)

            if (parentId) {
                relatives.push(parentId)
            }
            const uniqueRelatives = new Set(relatives).values()
            node.parentIds = [...uniqueRelatives]

            if (node.childNodes?.length) {
                const branchRelatives = [...relatives]
                node.childNodes.forEach((childBranch: any) => {
                    loopThrowChilds(childBranch)
                })

                relatives = [...branchRelatives]
            }
        }

        loopThrowChilds(branch)
    })

    return dataTree
}
