import { HttpService } from 'http/httpService'
import { ActionEventData, EventType, LoggingService } from 'http/loggingService'
import { User } from 'auth/state'
import { AdjustmentType, GlobalAccountSettings } from 'products/pricing/pricingService'

export interface PriceSettingsForOverview {}

interface CalendarProduct {
    id: string
    number_of_tickets: number
    price: string
    original_price: string
}
export interface CalendarPrice {
    dt: string
    startTime: string | null
    endTime: string | null
    color: number
    products: CalendarProduct[]
    expires_at: number
    signature: string
}
interface ValidationMessageTimeslot {
    dt: string
    start_time: string
    end_time: string | null
    override_id: string
}

export interface ValidationMessage {
    message: string
    timeslots: ValidationMessageTimeslot[]
}

export interface CalendarData {
    modelId: string
    modelVersion: number
    pricingStrategy: any[]
    defaultPrice: {
        dt: string | null
        startTime: string | null
        endTime: string | null
        color: number
        products: CalendarProduct[]
        expiresAt: number
        signature: string
    }
    prices: CalendarPrice[]
    validationMessages: ValidationMessage[]
}

interface ExceptionForOverview {
    id: string | null
    account: string
    minAcceptedPriceAdjustment: number | null
    minAcceptedPriceAdjustmentType: AdjustmentType | null
    originalPriceAdjustment: number | null
    originalPriceAdjustmentType: AdjustmentType | null
    targetAvgPriceAdjustment: number | null
    targetAvgPriceAdjustmentType: AdjustmentType | null
    startDate: string
    endDate: string
}

export interface TimeSlot {
    event_date: string
    start_time: string | null
    end_time: string | null
}

export class ArticlePricingService {
    constructor(
        private httpService: HttpService,
        private loggingService: LoggingService,
        private backofficeEndpoint: string,
    ) {}

    async getPricesForCalendar(
        slug: string,
        userInfo: User,
        productsList: string | null,
        productData: {
            id: string
            priceSettings: PriceSettingsForOverview
            override: ExceptionForOverview | {}
        },
        dateFrom: string,
        dateTo: string,
        accountSettings?: GlobalAccountSettings,
    ): Promise<CalendarData> {
        const cookie_id: string = localStorage.getItem('convious_cookie_id' || 'cookieId') || ''
        const settings = {
            product_data: productData,
            account_settings: accountSettings || null,
        }
        const requestBody = JSON.stringify({
            article_uuid: productData.id,
            products_list_uuid: productsList,
            date_from: dateFrom,
            date_to: dateTo,
            settings: settings,
        })

        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}dashboard_api/accounts/${slug}/article/pricing/`,
            {
                method: 'POST',
                body: requestBody,
                headers: {
                    'Content-Type': 'application/json',
                    'convious-cookie-id': cookie_id,
                },
            },
        )

        if (response.status !== 200) {
            const reason = await response.text()
            throw new Error(`Oops! Something went wrong, please try again: ${reason}`)
        }

        const logEventType: EventType = 'price_calendar_preview'
        const logEventData: ActionEventData = {
            category: 'pricing',
            payload: {
                product_id: productData.id,
                date_from: dateFrom,
                date_to: dateTo,
                price_setting: productData.priceSettings,
                override: {},
            },
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }
}
