import { AuthenticatedHttpService } from 'http/httpService'
import {
    Template,
    TemplateType,
    TemplateStatusType,
    TemplateSettings,
    TemplateSettingsWithVariables,
    PayloadTemplateSettings,
    cartPaidTemplates,
    reminderTemplates,
    payLaterTemplates,
} from 'emailTemplates/schema'
import { LoggingService, EventType, ActionEventData } from 'http/loggingService'

const getTemplatesEndpointPath = (template: TemplateType) => {
    if (cartPaidTemplates.includes(template)) {
        return 'cart_paid_email_templates'
    }
    if (reminderTemplates.includes(template)) {
        return 'cart_reminder_email_templates'
    }
    if (payLaterTemplates.includes(template)) {
        return 'cart_pay_later_email_templates'
    }
    return 'email_templates'
}

export class EmailTemplatesService {
    constructor(
        private httpService: AuthenticatedHttpService,
        private loggingService: LoggingService,
        private backofficeEndpoint: string,
    ) {}

    async getTemplatesList(accountSlug: string): Promise<Template[]> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/email_templates/`,
            {
                method: 'GET',
            },
        )

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body.emailTemplates
    }

    async updateTemplateStatus(accountSlug: string, template: TemplateType, status: TemplateStatusType): Promise<void> {
        const logEventType: EventType = 'email_template_status_updated'
        const logEventData: ActionEventData = {
            category: 'email_templates',
            payload: { template, status },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/email_templates/${template}/status/`,
            {
                method: 'PUT',
                body: JSON.stringify({ status }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async getTemplateSettings(accountSlug: string, template: TemplateType): Promise<TemplateSettingsWithVariables> {
        const endpointPath = getTemplatesEndpointPath(template)
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/${endpointPath}/${template}/`,
            {
                method: 'GET',
            },
        )

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        return await response.json()
    }

    async updateTemplateSettings(
        accountSlug: string,
        template: TemplateType,
        config: PayloadTemplateSettings,
    ): Promise<void> {
        const logEventType: EventType = 'email_template_config_updated'
        const logEventData: ActionEventData = {
            category: 'email_templates',
            payload: { template, config },
        }
        const endpointPath = getTemplatesEndpointPath(template)
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/${endpointPath}/${template}/`,
            {
                method: 'PUT',
                body: JSON.stringify({ ...config }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async getTemplateHtml(
        accountSlug: string,
        template: TemplateType,
        config: PayloadTemplateSettings | null,
        language: string,
    ): Promise<string> {
        const body = config ? JSON.stringify({ templateSettings: { ...config } }) : JSON.stringify({})
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/email_templates/${template}/html/${language}/`,
            {
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        return await response.text()
    }

    async sendTestEmail(
        accountSlug: string,
        template: TemplateType,
        config: TemplateSettings,
        language: string,
        email: string,
    ): Promise<void> {
        const logEventType: EventType = 'email_template_test_sent'
        const logEventData: ActionEventData = {
            category: 'email_templates',
            payload: { template, status },
        }
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/email_templates/${template}/test/${language}/`,
            {
                method: 'POST',
                body: JSON.stringify({
                    templateSettings: { ...config },
                    recipient: email,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
    }
}
