import * as React from 'react'
import styled from 'styled-typed'
import { Link, LinkProps } from 'react-router-dom'

const LinkWithoutStyle = styled(Link)`
    color: inherit;
    text-decoration: inherit;
`

interface UnstyledLinkProps extends LinkProps {
    to: LinkProps['to']
    id?: string
    onClick?: (e: React.MouseEvent) => void
    disabled?: boolean
    block?: boolean
    className?: string
    title?: string
}

export const UnstyledLink: React.FC<UnstyledLinkProps> = ({
    to,
    onClick,
    id,
    block,
    className,
    title,
    disabled,
    children,
    ...rest
}) => {
    return (
        <>
            {disabled && <>{children}</>}
            {!disabled && (
                <LinkWithoutStyle
                    to={to}
                    onClick={onClick}
                    id={id}
                    style={block ? { display: 'block' } : {}}
                    className={className}
                    title={title}
                    {...rest}
                >
                    {children}
                </LinkWithoutStyle>
            )}
        </>
    )
}
