import React, { FC } from 'react'
import { HistoryRecord } from '../types'
import HistoryItem from './historyItem'
import styled from 'styled-typed'

export const History = styled.div<{ height?: string }>`
    overflow-y: auto;
    border-radius: 0.25rem;
    height: ${(props) => props.height ?? 'auto'};
    .history-item {
        &:first-of-type {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
        }

        &:last-of-type {
            border-bottom-left-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
        }
    }
`

interface Props {
    height?: string
    history: HistoryRecord[]
    selectedHistoryRecordIndex?: number
    onHistoryRecordIndexSelect?: (index: number) => void
}

const RedeemHistory: FC<Props> = ({ height, history, onHistoryRecordIndexSelect, selectedHistoryRecordIndex }) => {
    return (
        <History height={height}>
            {history.map((record, index) => (
                <HistoryItem
                    isSelected={index === selectedHistoryRecordIndex}
                    onClick={() => onHistoryRecordIndexSelect?.(index)}
                    key={`${record.time}-${record.code}`}
                    record={record}
                />
            ))}
        </History>
    )
}

export default RedeemHistory
