interface Projection<T, U> {
    (input: T): U
}

interface AsyncProjection<T, U> {
    (input: T): Promise<U>
}

interface Binding<T, U, TFailure> {
    (success: T): Result<U, TFailure>
}

interface Action<T> {
    (param: T): void
}

interface AsyncAction<T> {
    (param: T): Promise<void>
}

export interface Result<TSuccess, TFailure> {
    isSuccess(): boolean
    isFailure(): boolean
    map<TNew>(projection: Projection<TSuccess, TNew>): Result<TNew, TFailure>
    mapFailure<TNew>(projection: Projection<TFailure, TNew>): Result<TSuccess, TNew>
    mapFailureAsync<TNew>(projection: AsyncProjection<TFailure, TNew>): Promise<Result<TSuccess, TNew>>
    mapAsync<TNew>(projection: AsyncProjection<TSuccess, TNew>): Promise<Result<TNew, TFailure>>
    bind<TNew>(binding: Binding<TSuccess, TNew, TFailure>): Result<TNew, TFailure>
    ifSuccess(action: Action<TSuccess>): Result<TSuccess, TFailure>
    ifSuccessAsync(action: AsyncAction<TSuccess>): Promise<Result<TSuccess, TFailure>>
    ifFailure(action: Action<TFailure>): Result<TSuccess, TFailure>
    ifFailureAsync(action: AsyncAction<TFailure>): Promise<Result<TSuccess, TFailure>>
    resolve<TValue>(
        successProjection: Projection<TSuccess, TValue>,
        failureProjection: Projection<TFailure, TValue>,
    ): TValue
    throwOnFailure(e: Error): Result<TSuccess, TFailure>
}

class SuccessResult<TSuccess, TFailure> implements Result<TSuccess, TFailure> {
    constructor(private success: TSuccess) {} // tslint:disable-line

    public isSuccess = () => true
    public isFailure = () => false

    public map<TNew>(projection: Projection<TSuccess, TNew>): Result<TNew, TFailure> {
        return new SuccessResult<TNew, TFailure>(projection(this.success))
    }

    public async mapAsync<TNew>(projection: AsyncProjection<TSuccess, TNew>): Promise<Result<TNew, TFailure>> {
        const mapped = await projection(this.success)
        return new SuccessResult<TNew, TFailure>(mapped)
    }

    public mapFailure<TNew>(): Result<TSuccess, TNew> {
        return <Result<TSuccess, TNew>>(<any>this)
    }

    public async mapFailureAsync<TNew>(): Promise<Result<TSuccess, TNew>> {
        return <Result<TSuccess, TNew>>(<any>this)
    }

    public bind<TNew>(binding: Binding<TSuccess, TNew, TFailure>): Result<TNew, TFailure> {
        return binding(this.success)
    }

    public ifSuccess(action: Action<TSuccess>) {
        action(this.success)
        return this
    }

    public async ifSuccessAsync(action: AsyncAction<TSuccess>): Promise<Result<TSuccess, TFailure>> {
        await action(this.success)
        return this
    }

    public ifFailure() {
        return this
    }

    public async ifFailureAsync() {
        return this
    }

    public resolve<TValue>(successProjection: Projection<TSuccess, TValue>) {
        return successProjection(this.success)
    }

    public throwOnFailure(e: Error) {
        return this
    }
}

class FailureResult<TSuccess, TFailure> implements Result<TSuccess, TFailure> {
    constructor(private failure: TFailure) {} // tslint:disable-line

    public isSuccess = () => false
    public isFailure = () => true

    public map<TNew>(): Result<TNew, TFailure> {
        return <Result<TNew, TFailure>>(<any>this)
    }

    public async mapAsync<TNew>(): Promise<Result<TNew, TFailure>> {
        return <Result<TNew, TFailure>>(<any>this)
    }

    public mapFailure<TNew>(projection: Projection<TFailure, TNew>): Result<TSuccess, TNew> {
        return new FailureResult<TSuccess, TNew>(projection(this.failure))
    }

    public async mapFailureAsync<TNew>(projection: AsyncProjection<TFailure, TNew>): Promise<Result<TSuccess, TNew>> {
        const mapped = await projection(this.failure)
        return new FailureResult<TSuccess, TNew>(mapped)
    }

    public bind<TNew>(): Result<TNew, TFailure> {
        return <Result<TNew, TFailure>>(<any>this)
    }

    public ifSuccess() {
        return this
    }

    public async ifSuccessAsync(): Promise<Result<TSuccess, TFailure>> {
        return this
    }

    public ifFailure(action: Action<TFailure>) {
        action(this.failure)
        return this
    }

    public async ifFailureAsync(action: AsyncAction<TFailure>) {
        await action(this.failure)
        return this
    }

    public resolve<TValue>(_: Projection<TSuccess, TValue>, failureProjection: Projection<TFailure, TValue>) {
        return failureProjection(this.failure)
    }

    public throwOnFailure(e: Error): Result<TSuccess, TFailure> {
        throw e
    }
}

export function success<TSuccess, TFailure>(value: TSuccess): Result<TSuccess, TFailure> {
    return new SuccessResult<TSuccess, TFailure>(value)
}

export function failure<TSuccess, TFailure>(value: TFailure): Result<TSuccess, TFailure> {
    return new FailureResult<TSuccess, TFailure>(value)
}
