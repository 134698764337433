const getPlacements = `query GetPlacements($slug: String!) {
  getPlacements(slug: $slug, consentGiven: true) {
    slug
    id
    name
    active
    startDate
    endDate
    weight
    locationId
    audiences {
        audience
    }
  }
}`

const getPlacement = `query GetPlacement($id: ID!, $slug: String!) {
  getPlacement(id: $id, slug: $slug) {
    id
    active
    type
    slug
    name
    startDate
    endDate
    weight
    locationId
    style {
        theme
        image
        size
        background
    }
    action {
        value
    }
    content {
        subtitle
        locale
        title
        default
        buttons {
            caption
            action {
                value
            }
        }
    }
    audiences {
        audience
    }
    consentRequired
  }
}`

const updatePlacementsWeight = `mutation UpdatePlacementsWeight($slug: String!, $placements: [PlacementWeightInput!]!) {
  updatePlacementsWeight(slug: $slug, placements: $placements) {
    slug
    id
    name
    active
    startDate
    endDate
    weight
  }
}`

const deletePlacement = `mutation DeletePlacement($slug: String!, $id: ID!) {
  deletePlacement(slug: $slug, id: $id)
}`

const addPlacement = `mutation AddPlacement($placement: AddPlacementInput) {
  addPlacement(placement: $placement){
    name
    id
  }
}`

const updatePlacement = `mutation UpdatePlacement($placement: UpdatePlacementInput) {
  updatePlacement(placement: $placement){
    name
    id
  }
}`

export default {
    getPlacements,
    getPlacement,
    updatePlacementsWeight,
    addPlacement,
    updatePlacement,
    deletePlacement,
}
