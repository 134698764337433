export const transactionsQuery = `
query Transactions($page: Int, $widget: ID, $search: String, $dateFrom: DateTime, $dateTo: DateTime,
  $dateRangeType: DateRangeType, $sortBy: OrderSort, $sortDirection: SortDirection, $pageSize: Int,
  $searchType: GrapheneSearchType, $filters: String) {
  account(slug: $widget) {
    openDisputeCount
    orders(page: $page, search: $search, dateFrom: $dateFrom, dateTo: $dateTo, dateRangeType: $dateRangeType,
      sortBy: $sortBy, sortDirection: $sortDirection, pageSize: $pageSize, searchType: $searchType, filters: $filters) {
      entries {
        id,
        status,
        orderStatus,
        paymentStatus,
        location,
        customer {
          email,
          language
        },
        orderDate,
        paymentDate,
        visitDate,
        visitTime,
        ticketDownloadUrl,
        quantity,
        total,
        totalAfterDiscount,
        discountAmount,
        discountCode,
        canRefund,
        emailStatus,
        resellerName,
        unfilledApf,
        paymentProvider,
        downloaded,
        uuid,
        paymentMethod
      },
      totalCount,
      prevPage,
      nextPage,
      currentPage,
      pageSize
    }
  }
}
`

export const barcodesQuery = `
query Barcodes($page: Int, $widget: ID, $search: String, $dateFrom: DateTime, $dateTo: DateTime,
  $dateRangeType: DateRangeType, $sortBy: String, $sortDirection: SortDirection,  $pageSize: Int,
  $searchType: GrapheneSearchType, $filters: String) {
  account(slug: $widget) {
    barcodes(page: $page, search: $search, dateFrom: $dateFrom, dateTo: $dateTo, dateRangeType: $dateRangeType,
      sortBy: $sortBy, sortDirection: $sortDirection, pageSize: $pageSize, searchType: $searchType, filters: $filters) {
      entries {
        product,
        barcode,
        orderId,
        orderUuid,
        orderItemId,
        orderEmail,
        amountPaid,
        discountCode,
        bundle,
        bundleOrderItemId,
        paymentDate,
        visitDate,
        visitTime,
        validFrom,
        validTo,
        redeemed,
        status,
        marketingOptIn,
        printed,
        resellerName,
        options {
          name,
          numberOfItems,
          price
        }
        userFields {
          name,
          value,
          type,
          label,
          placeholder,
          isRequired,
          editable,
          repeatable
        }
      },
      totalCount,
      prevPage,
      nextPage,
      currentPage,
      pageSize,
      userFieldConfig {
        enabled,
        fields {
            name
            label
            placeholder
            required
            articles
            repeatable
            type
        }
      }
    }
  }
}
`

export const orderRefundQuery = `
query OrderRefundDetails($widget: ID, $number: ID!) {
  account(slug: $widget) {
    order(number: $number) {
      id,
      uuid,
      status,
      customer {
        email,
        language
      },
      orderDate,
      orderStatus,
      paymentDate,
      visitDate,
      visitTime,
      quantity,
      total,
      totalAfterDiscount,
      discountAmount,
      canRefund,
      canPartialRefund,
      partnerRefundFee,
      partnerRefundFeeType,
      tickets {
        canRefund,
        orderItemId,
        orderItemUuid,
        product,
        quantity,
        pricePerItem,
        pricePerItemAfterDiscount,
        freeItems,
        isBundle,
        groupedTicketEntities {
          id,
          uuid,
          invalidatedAt,
          status,
          barcode {
            barcode,
            refunded,
            redeemed,
            canRefund
          },
          canRefund,
          price
        }
        bundleItemPrices
      }
    }
  }
}
`

export const apfItemUpdateQuery = `
  mutation UpdateUserField ($account: String!, $barcode: ID!, $name: String!, $value: String) {
    updateUserField(
      account: $account,
      barcode: $barcode,
      name: $name,
      value: $value) {
        ok,
        errorCode
    }
  }`

export const ticketValidToUpdateQuery = `
  mutation UpdateOrderItemValidTo ($account: String!, $orderItemId: ID, $validTo: Date!, $orderItemUuid: UUID) {
    updateOrderItemValidTo(
      account: $account,
      orderItemId: $orderItemId,
      validTo: $validTo,
      orderItemUuid: $orderItemUuid) {
        ok,
        errorCode
    }
  }`
