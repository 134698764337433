import { Session } from './session'
import { OAuthService, AuthTicket } from './oauthService'
import * as config from 'config'

export interface HttpService {
    fetch: typeof fetch
    fetchAnonymous: typeof fetch
    refreshToken(): Promise<void>
}

export class AuthenticatedHttpService implements HttpService {
    private refreshPromise: Promise<AuthTicket> | null = null

    constructor(private session: () => Session, private oauthService: OAuthService, private state: any) {}

    private appendAuthHeader(init?: RequestInit): RequestInit {
        const authTicket = this.session().authTicket()
        if (!authTicket) {
            this.session().abandon()
            throw new Error('Not authenticated')
        }

        init = init || {}

        return {
            ...init,
            headers: {
                ...init.headers,
                Authorization: 'Bearer ' + authTicket.accessToken,
            },
        }
    }

    private appendHeader(init?: RequestInit): RequestInit {
        init = init || {}

        return {
            ...init,
            headers: {
                ...init.headers,
            },
        }
    }

    private appendConviousHeaders(url: string, reqInit: RequestInit): RequestInit {
        return url.includes(config.getRequired('backoffice-endpoint'))
            ? {
                  ...reqInit,
                  headers: {
                      ...reqInit.headers,
                      'convious-account-slug': this.state().preferences.activeAccount,
                  },
              }
            : reqInit
    }

    public async refreshToken() {
        if (this.refreshPromise) {
            await this.refreshPromise
            return
        }

        const authTicket = this.session().authTicket()
        if (!authTicket) {
            throw new Error('Not authenticated')
        }

        try {
            this.refreshPromise = this.oauthService.refreshToken(authTicket.refreshToken)
            const newTicket = await this.refreshPromise
            await this.session().initialize(newTicket)
        } catch (e) {
            this.session().abandon()
            throw e
        } finally {
            this.refreshPromise = null
        }
    }

    public async fetch(url: string, init?: RequestInit): Promise<Response> {
        const self = this
        let response: Response = await fetch(url, reqInit())
        if (response.status !== 401) {
            return response
        }

        try {
            await this.refreshToken()
        } catch (e) {
            if (!response) {
                throw new Error('An error has occurred while receiving response')
            }
            return response
        }
        return await fetch(url, reqInit())

        function reqInit() {
            let reqInitWithHeaders = self.appendAuthHeader(init)
            reqInitWithHeaders = self.appendConviousHeaders(url, reqInitWithHeaders)
            return reqInitWithHeaders
        }
    }

    public async fetchAnonymous(url: string, init?: RequestInit): Promise<Response> {
        const self = this
        let reqInitWithHeaders = reqInit()

        const authTicket = this.session().authTicket()
        if (!authTicket) {
            return await fetch(url, reqInitWithHeaders)
        }

        const response = await this.fetch(url, init)
        if (response.status === 401) {
            return await fetch(url, reqInitWithHeaders)
        }

        return response

        function reqInit() {
            let reqInitWithHeaders = self.appendHeader(init)
            reqInitWithHeaders = self.appendConviousHeaders(url, reqInitWithHeaders)
            return reqInitWithHeaders
        }
    }
}
