import { Dispatch } from 'redux'
import { createAction } from 'typesafe-actions'
import { Message, MessageKind } from 'uiComponents/messages'
import uuid from 'uuid'

export const addMessage = createAction(
    'AddMessage',
    (action) => (id: string, status: MessageKind, text: string) =>
        action({
            type: 'AddMessage',
            id,
            status,
            text,
        }),
)

export const replaceMessage =
    (id: string, status: MessageKind, text: string, time?: number) => (dispatch: Dispatch) => {
        dispatch(removeMessage(id))
        const action = dispatch(addMessage(id, status, text))

        if (time) {
            setTimeout(() => {
                dispatch(removeMessage(id))
            }, time)
        }

        return action
    }

export const removeMessage = createAction(
    'RemoveMessage',
    (action) => (id: string) =>
        action({
            type: 'RemoveMessage',
            id,
        }),
)

export const removeAllMessages = createAction(
    'RemoveAllMessages',
    (action) => () =>
        action({
            type: 'RemoveAllMessages',
        }),
)

export const addInlineMessage = createAction(
    'AddInlineMessage',
    (action) => (message: Omit<Message, 'id' | 'visible' | 'type'>) =>
        action({
            type: 'AddInlineMessage',
            payload: {
                id: uuid.v4(),
                type: 'inline',
                visible: true,
                ...message,
            },
        }),
)
export const removeAllTagMessages = createAction(
    'RemoveAllTagMessages',
    (action) => (tag: string) =>
        action({
            type: 'RemoveAllTagMessages',
            tag,
        }),
)
