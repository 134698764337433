import styled from 'styled-typed'

export const Page = styled.div`
    font-family: ${(props) => props.theme.fonts.primary};
    display: flex;
    flex: 1;
`

export const Body = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`

export const Side = styled.div`
    z-index: 1;
    font-weight: lighter;
    display: flex;
`
