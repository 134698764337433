import * as React from 'react'
import styled from 'styled-typed'
import { DataRow } from 'uiComponents/table'

export interface NoResultsRowProps {
    item?: string
    text?: string
    headline?: string
    alignLeft?: boolean
}

const NoResults = styled(DataRow)`
    color: ${(props) => props.theme.colors.textLight};
    text-align: center;
    background: ${(props) => props.theme.colors.white};
    height: unset;
    border-radius: 0 0 0.7em 0.7em;

    & h3 {
        margin: 0 0 0.9em;
        font-size: 1.25em;
    }
`

const NoResultsCell = styled.div<NoResultsRowProps>`
    flex: 1;
    padding: 2em 0;
    text-align: ${(props) => (props.alignLeft ? 'left' : '')};
    padding-left: ${(props) => (props.alignLeft ? '5em' : '')};
`

const ChildrenWrapper = styled.div`
    margin-top: 1rem;
`

const Headline = styled.h3`
    color: ${(props) => props.theme.colors.textDark};
`

export const NoResultsRow: React.FC<NoResultsRowProps & React.HTMLAttributes<HTMLDivElement>> = ({
    style,
    alignLeft,
    item,
    text,
    headline,
    children,
}) => {
    return (
        <NoResults style={style}>
            <NoResultsCell alignLeft={alignLeft}>
                <Headline>{headline || 'No results'}</Headline>
                {item && `Get more ${item} or change your filters to see more`}
                {!item && text}
                {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
            </NoResultsCell>
        </NoResults>
    )
}
