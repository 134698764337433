import { totals, timeseriesAndTotals } from 'reports/queryGenerator'

import {
    trafficTotalsByGroupQuery,
    salesTotalsByGroupQuery,
    emailTotalsByGroupQuery,
    totalsForOverviewQuery,
    totalsForEmailsQuery,
    statsRevenueWithDiscountsTimeSeriesQuery,
    statsSalesTimeSeriesQuery,
} from 'reports/queriesCustomReports'

export const statsTimeSeriesQuery = `
  query StatsTimeSeries(
      $widget: String,
      $metric: StatsMetric,
      $granularity: StatsGranularity,
      $groupBy: String,
      $dateFrom: Date,
      $dateTo: Date,
      $filters: [FilterDictionary]) {
    stats(widget: $widget, metric: $metric, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters,
      groupBy: $groupBy) {
      ${timeseriesAndTotals}
      }
  },
`

export const statsTotalsByGroupQuery = `
  query StatsTotalsByGroup(
      $widget: String,
      $metric: StatsMetric,
      $dateFrom: Date,
      $dateTo: Date,
      $filters: [FilterDictionary],
      $groupBy: String) {
    customGroupBy: stats(
      widget: $widget, metric: $metric, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters,
      groupBy: $groupBy) {
      ${totals}
    }
  },
`

export const filterMetaDataQuery = `
  query FilterMetaData($accountSlug: String, $metadataFor: [String]) {
    metadata(accountSlug: $accountSlug, metadataFor: $metadataFor) {
        result {
          metadata
        }
      }
  }
`

export const queryMap = {
    generalTimeSeries: {
        query: statsTimeSeriesQuery,
        operationName: 'StatsTimeSeries',
    },
    generalTotalsByGroup: {
        query: statsTotalsByGroupQuery,
        operationName: 'StatsTotalsByGroup',
    },
    trafficTotalsByGroup: {
        query: trafficTotalsByGroupQuery,
        operationName: 'TrafficTotalsByGroup',
    },
    emailTotalsByGroup: {
        query: emailTotalsByGroupQuery,
        operationName: 'StatsEmailTotalsByGroup',
    },
    totalsForOverview: {
        query: totalsForOverviewQuery,
        operationName: 'OverviewTotals',
    },
    totalsForEmails: {
        query: totalsForEmailsQuery,
        operationName: 'EmailsTotals',
    },
    salesTotalsByGroup: {
        query: salesTotalsByGroupQuery,
        operationName: 'StatsTotalsByGroup',
    },
    revenueWithDiscountsTimeseries: {
        query: statsRevenueWithDiscountsTimeSeriesQuery,
        operationName: 'StatsTimeSeries',
    },
    salesGroupedByLocationTimeSeries: {
        query: statsSalesTimeSeriesQuery,
        operationName: 'StatsTimeSeries',
    },
}
