import { HttpService } from 'http/httpService'

export type RequestType = 'AB_TEST' | 'CUSTOM_REPORT' | 'GOOGLE_ADS_HELP' | 'GOOGLE_ADS_ACCOUNT' | 'GOOGLE_ADS_BUDGET'

export interface RequestEmailData {
    accountName: string
    accountSlug: string
    requestType: RequestType
    category?: string
    request: string
}

export class RequestService {
    constructor(private httpService: HttpService, private dashboardApiendpoint: string) {}

    async sendRequestEmail(emailData: RequestEmailData): Promise<void> {
        const response = await this.httpService.fetch(`${this.dashboardApiendpoint}request/`, {
            method: 'POST',
            body: JSON.stringify(emailData),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (response.status !== 200) {
            throw new Error('Oops! Something went wrong, please try again.')
        }
    }
}
