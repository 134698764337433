import { HttpService } from 'http/httpService'
import { Session } from 'http/session'
import {
    Industry,
    ScheduledEmail,
    AccountUserRequest,
    TransactionsSubsriptionInfo,
    AccountUserRequestResult,
    AccountUser,
    ExistingUser,
    UpdateProfileRequest,
    UserListItem,
} from './schema'
import { LoggingService, EventType, ActionEventData } from 'http/loggingService'

interface LogoUrl {
    logo_url: string
}

export interface PartnerIdUploadResult {
    fileName: string // TBD once API is done
}

interface ScheduledEmailPayload {
    product: string
    versionId: string
}

export interface ResellerResponseItem {
    name: string
    resellerId: string
}

interface ChangePassOutcome {
    ok: boolean
    errCode: string
    message: string
}

interface ChangePassResult {
    data: {
        changePassword: ChangePassOutcome
    }
}

export class AccountSettingsService {
    constructor(
        private httpService: HttpService,
        private loggingService: LoggingService,
        private backofficeEndpoint: string,
        private dashboardApiEndpoint: string,
        private clientId: string,
        private clientSecret: string,
        private session: Session,
    ) {}

    async handleUploadError(response: Response) {
        const error = await response.json()
        if (error.errors.file.indexOf('invalid_image') > -1) {
            throw new Error('Uploaded file does not appear to be an image. Please try another file.')
        } else if (error.errors.file.indexOf('file_size') > -1) {
            throw new Error('Uploaded image is larger than 10MB. Please try a smaller image.')
        } else {
            throw new Error('An unknown error has occured. Please try again.')
        }
    }

    async avatarUpload(file: File): Promise<void> {
        const data = new FormData()
        data.append('file', file)
        const response = await this.httpService.fetch(`${this.dashboardApiEndpoint}profile/avatar/`, {
            method: 'POST',
            body: data,
        })
        if (response.status !== 201) {
            this.handleUploadError(response)
        }
    }

    async logoUpload(file: File): Promise<LogoUrl> {
        const data = new FormData()
        data.append('file', file)
        const response = await this.httpService.fetch(`${this.dashboardApiEndpoint}profile/campaigns_img_upload/`, {
            method: 'POST',
            body: data,
        })
        if (response.status !== 201) {
            this.handleUploadError(response)
        }
        return await response.json()
    }

    async partnerOwnerIdUpload(file: File): Promise<PartnerIdUploadResult> {
        // --waiting for API--
        // const data = new FormData();
        // data.append('file', file)
        // const response = await this.httpService.fetch(`${this.backofficeEndpoint}`, {
        //   method: 'POST',
        //   body: data,
        // })
        // if (response.status !== 201) {
        //   this.handleUploadError(response)
        // }
        // return await response.json()
        return await new Promise((resolve) => resolve({ fileName: file.name }))
    }

    async getOrderAlertsSubscribersList(): Promise<string[]> {
        const response = await this.httpService.fetch(`${this.backofficeEndpoint}dashboard_api/new_order_alerts/`, {
            method: 'GET',
        })
        return await response.json()
    }

    async subscribeOrderAlerts(selectedWidgets: string[]): Promise<void> {
        const logEventType: EventType = 'order_alerts_subscribed'
        const logEventData: ActionEventData = {
            category: 'notifications',
            payload: { account: selectedWidgets[0] },
        }

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}dashboard_api/new_order_alerts/`, {
            method: 'POST',
            body: JSON.stringify({
                subscribe: { email: true },
                widgets: selectedWidgets,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (response.status !== 200) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Oops! Something went wrong, please try again.')
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async unsubscribeOrderAlerts(selectedWidgets: string[]): Promise<void> {
        const logEventType: EventType = 'order_alerts_unsubscribed'
        const logEventData: ActionEventData = {
            category: 'notifications',
            payload: { account: selectedWidgets[0] },
        }

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}dashboard_api/new_order_alerts/`, {
            method: 'POST',
            body: JSON.stringify({
                unsubscribe: { email: true },
                widgets: selectedWidgets,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (response.status !== 200) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Oops! Something went wrong, please try again.')
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async getTransactionsSubscriptionInfo(accountSlug: string): Promise<TransactionsSubsriptionInfo> {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}dashboard_api/accounts/${accountSlug}/order_alert_subscription/`,
            {
                method: 'GET',
            },
        )
        return await response.json()
    }

    async updateTransactionsSubscription(accountSlug: string, subscribed: boolean, articles: string[]): Promise<void> {
        const logEventType: EventType = 'order_alerts_unsubscribed'
        const logEventData: ActionEventData = {
            category: 'notifications',
            payload: { accountSlug, subscribed, articles },
        }
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}dashboard_api/accounts/${accountSlug}/order_alert_subscription/`,
            {
                method: 'PUT',
                body: JSON.stringify({ subscribed, articles }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Oops! Something went wrong, please try again.')
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async getScheduledEmails(): Promise<ScheduledEmail[]> {
        const response = await this.httpService.fetch(`${this.dashboardApiEndpoint}profile/scheduled_emails/`, {
            method: 'GET',
        })
        const body = await response.json()
        return body.scheduled_emails
    }

    scheduleEmail = async (
        emailType: string,
        checkout: string,
        frequency: string,
        weekday: number | null,
        payload: ScheduledEmailPayload | null = null,
    ): Promise<void> => {
        const logEventType: EventType =
            emailType === 'performance_report'
                ? 'performance_reports_subscription_update'
                : 'barcodes_alert_subscription_update'
        const logEventData: ActionEventData = {
            category: 'notifications',
            payload: {
                emailType,
                checkout,
                unsubscribe: frequency === 'never',
                frequency,
                weekday,
                payload,
            },
        }

        const response = await this.httpService.fetch(`${this.dashboardApiEndpoint}profile/scheduled_emails/`, {
            method: 'POST',
            body: JSON.stringify({
                emailType,
                checkout,
                frequency,
                weekday,
                payload,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (response.status !== 200) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Oops! Something went wrong, please try again.')
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async updateEmail(email: string, oldEmail: string | null = null): Promise<void> {
        const response = await this.httpService.fetch(`${this.dashboardApiEndpoint}profile/user_email/`, {
            method: 'PUT',
            body: JSON.stringify({
                email: email,
                old_email: oldEmail,
                client_id: this.clientId,
                client_secret: this.clientSecret,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (response.status === 409) {
            throw new Error('emailRegistered')
        }
        const body = await response.json()
        if (!oldEmail) {
            const ticket = {
                accessToken: body.access_token,
                refreshToken: body.refresh_token,
            }
            await this.session.initialize(ticket)
        }
        return body
    }

    async updatePassword(oldPassword: string, newPassword: string): Promise<ChangePassResult> {
        const query = `
      mutation changePassword($oldPassword: String, $newPassword: String){
        changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
          ok
          errCode
          message
        }
      }
    `
        const response = await this.httpService.fetch(`${this.dashboardApiEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({
                query,
                variables: { oldPassword, newPassword },
            }),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Dashboard Api has returned status code: ${response.status}`)
        }

        return await response.json()
    }

    confirmEmail = async (token: string): Promise<boolean> => {
        const response = await this.httpService.fetch(`${this.dashboardApiEndpoint}profile/email_confirm/`, {
            method: 'POST',
            body: JSON.stringify({ token }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (response.status === 500) {
            const reason = await response.text()
            throw new Error(`Server failure: ${reason}`)
        }
        if (response.status === 400) {
            const reason = await response.json()
            if (reason.errors.invalid_token) {
                throw new Error('tokenInvalid')
            }
        }

        return response.status >= 200 && response.status < 300
    }

    resendConfirmEmail = async (email: string | null = null): Promise<void> => {
        const response = await this.httpService.fetch(`${this.dashboardApiEndpoint}profile/resend_email_confirm`, {
            method: 'POST',
            body: JSON.stringify({ email }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (response.status !== 200) {
            throw new Error('Oops! Something went wrong, please try again.')
        }
    }

    getIndustries = async (email: string | null = null): Promise<Industry[]> => {
        const query = `query Industries {
      industries {
        name
        value
      },
    }`

        const requestBody = JSON.stringify({
            query: query,
            operationName: 'Industries',
        })

        const response = await this.httpService.fetch(`${this.backofficeEndpoint}graphql`, {
            method: 'POST',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        return payload.data.industries
    }

    async updateWelcomeSeen(): Promise<void> {
        const welcomeSeen = true
        const query = `mutation markWelcomeSeen ($welcomeSeen: Boolean) {
      updateUserProfile(welcomeSeen: $welcomeSeen) {
        ok
      }
    }`
        const response = await this.httpService.fetch(`${this.dashboardApiEndpoint}graphql`, {
            method: 'POST',
            body: JSON.stringify({ query, variables: { welcomeSeen } }),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Dashboard Api has returned status code: ${response.status}`)
        }
    }

    createOrUpdateUser = async (requestData: AccountUserRequest): Promise<AccountUserRequestResult> => {
        const query = `
      mutation UserAccount($email: String, $permissions: [Permissions], $accounts: [String], $deletedAccounts: [String],
        $conviousAdmin: Boolean, $resellerId: String){
        userAccount(
          email: $email,
          permissions: $permissions,
          accounts: $accounts,
          deletedAccounts: $deletedAccounts,
          conviousAdmin: $conviousAdmin,
          resellerId: $resellerId
        ) {
          ok
          message
        }
      }
    `

        const requestBody = JSON.stringify({
            operationName: 'UserAccount',
            query: query,
            variables: { ...requestData },
        })

        const response = await this.httpService.fetch(`${this.dashboardApiEndpoint}graphql`, {
            method: 'POST',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Dashboard API has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        return payload.data.userAccount
    }

    updateUserProfile = async (requestData: UpdateProfileRequest): Promise<void> => {
        const query = `
      mutation UpdateProfileQuery ($firstName: String, $lastName: String,
        $avatarUrl: String, $contactNo: String, $companyRole: String) {
        updateUserProfile(
          firstName: $firstName,
          lastName: $lastName,
          avatarUrl: $avatarUrl,
          contactNo: $contactNo,
          companyRole: $companyRole) {
          ok
        }
      }
    `

        const requestBody = JSON.stringify({
            operationName: 'UpdateProfileQuery',
            query: query,
            variables: { ...requestData },
        })

        const response = await this.httpService.fetch(`${this.dashboardApiEndpoint}graphql`, {
            method: 'POST',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Dashboard API has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        if (!payload.data.updateUserProfile.ok) {
            throw new Error('Dashboard API mutation failed')
        }
    }

    getUserList = async (slug: string, search: string, filters: string): Promise<UserListItem[]> => {
        const query = `
      query GetUserList($slug: String, $search: String, $filters: String) {
        users(accountSlug: $slug, search: $search, filters: $filters) {
          id,
          email,
          firstName,
          lastName,
          role
        }
      }
    `
        const requestBody = JSON.stringify({
            operationName: 'GetUserList',
            query: query,
            variables: { slug, search, filters },
        })

        const response = await this.httpService.fetch(`${this.dashboardApiEndpoint}graphql`, {
            method: 'POST',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Dashboard API has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        return payload.data.users
    }

    getUserById = async (slug: string, userId: number): Promise<AccountUser> => {
        const query = `
      query GetAccountUser($slug: String, $userId: Int) {
        user(accountSlug: $slug, userId: $userId) {
          id,
          email,
          firstName,
          lastName,
          role,
          permissions,
          accounts,
          emailConfirmed,
          requiresPasswordChange,
          resellerId,
        }
      }
    `

        const requestBody = JSON.stringify({
            operationName: 'GetAccountUser',
            query: query,
            variables: { slug, userId },
        })

        const response = await this.httpService.fetch(`${this.dashboardApiEndpoint}graphql`, {
            method: 'POST',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Dashboard API has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        return payload.data.user
    }

    getExistingUser = async (slug: string, email: string): Promise<ExistingUser> => {
        const query = `
      query GetAccountUser($slug: String, $email: String) {
        existingUser(accountSlug: $slug, userEmail: $email) {
          role,
          currentAccountUser,
          resellerId,
        }
      }
    `

        const requestBody = JSON.stringify({
            operationName: 'GetAccountUser',
            query: query,
            variables: { slug, email },
        })

        const response = await this.httpService.fetch(`${this.dashboardApiEndpoint}graphql`, {
            method: 'POST',
            body: requestBody,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
        if (!response.ok) {
            throw new Error(`Dashboard API has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        return payload.data.existingUser
    }

    getResellersList = async (accountSlug: string): Promise<ResellerResponseItem[]> => {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/resellers/`,
            {
                method: 'GET',
            },
        )
        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body
    }
}
