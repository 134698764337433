import React from 'react'
import { OnChangeFunc } from 'engageTools/studio/schema'
import { MultiSelect, Status } from 'uiComponents/input'
import { MultiSelectField } from '../fieldComponentSchema'
import { isEqual } from 'lodash'

interface SelectProps {
    id?: string
    field: MultiSelectField
    handleChange?: OnChangeFunc
    value: Array<string> | null
    availableOptions?: any[]
    disabled?: boolean
    noSelectOption?: string
    status?: Status
}

interface SelectComponentState {
    selectedValue: Array<string>
    open: boolean
}

export class MultiSelectStudio extends React.Component<SelectProps, SelectComponentState> {
    constructor(props: SelectProps) {
        super(props)
        this.state = {
            selectedValue: this.getValue(this.props.value),
            open: false,
        }
    }

    onSelect = (v: string[]) => {
        if (typeof this.props.handleChange !== 'undefined') {
            this.props.handleChange(v, this.props.field.path)
        }
        this.setState({ selectedValue: v })
    }

    getValue = (value?: Array<string> | null): Array<string> => {
        if (!Array.isArray(value)) {
            return []
        } else {
            return value
        }
    }

    componentDidMount() {
        this.setState({ selectedValue: this.getValue(this.props.value) })
    }

    componentDidUpdate(prevProps: SelectProps) {
        const value = this.getValue(this.props.value)
        const prevValue = this.getValue(prevProps.value)
        if (!isEqual(value, prevValue)) {
            this.setState({ selectedValue: value })
        }
    }

    render() {
        const optionsPresent = typeof this.props.field.availableOptions !== 'undefined'
        const options = this.props.availableOptions
            ? this.props.availableOptions
            : this.props.field.availableOptions || []
        const value = this.state.selectedValue
        if (!Array.isArray(value)) {
            throw new Error(`Wrong input type: ${typeof value} of ${this.props.field.name}`)
        }

        const mappedOptions = options.map((o) => {
            o.value = o.value.toString()
            return o
        })

        if (!optionsPresent) {
            return null
        }

        return (
            <MultiSelect
                id={this.props.field.name}
                options={mappedOptions}
                selected={this.state.selectedValue}
                noSelectOption={this.props.noSelectOption ? this.props.noSelectOption : 'Select'}
                width={this.props.field.span && this.props.field.span % 6 === 0 ? '100%' : '73%'}
                lineHeight="2em"
                maxHeight="15em"
                onSelect={this.onSelect}
                disabled={this.props.disabled}
                status={this.props.status}
                widthWhenOpen="100%"
            />
        )
    }
}
