import { RootAction } from 'store'
import { getType } from 'typesafe-actions'
import { createInitialProfileState, ProfileState } from './state'
import { profileLoaded } from './actions'

export function profileReducer(state: Readonly<ProfileState>, action: RootAction): ProfileState {
    state = state || createInitialProfileState()
    switch (action.type) {
        case getType(profileLoaded):
            return {
                ...state,
                profile: action.payload.profile,
            }
        default:
            return state
    }
}
