import React from 'react'
import styled from 'styled-typed'
import { buttonReset } from 'uiComponents/buttons'
import Svg from 'svg'
import desktopIcon from 'uiComponents/studio/icons/device-desktop.svg'
import mobileIcon from 'uiComponents/studio/icons/device-mobile.svg'
import { PreviewSize } from 'uiComponents/studio/preview/components'

const PreviewSizeButton = styled.button`
    ${buttonReset}
    border: 1px solid ${(props) => props.theme.colors.textDark};
    color: ${(props) => props.theme.colors.textDark};
    padding-top: 0.3125em;
    width: 4.6875em;
    height: 3.125em;
    display: inline-block;
    transition: all 0.1s ease-in;
    margin: 0 0.0625em;

    &:first-child {
        border-radius: 0.375em 0 0 0.375em;
    }

    &:last-child {
        border-radius: 0 0.375em 0.375em 0;
    }

    &:only-child {
        border-radius: 0.375em;
    }

    &:hover,
    &.active {
        background: ${(props) => props.theme.colors.textDark};
        color: ${(props) => props.theme.colors.white};
    }

    &.active {
        cursor: default;
    }
`

const PreviewSizeIcon = styled(Svg)`
    height: 2em;
    fill: currentColor;
    stroke: currentColor;
`

const ButtonsContainer = styled.div`
    padding: 0 2.8125em;
    display: flex;
    align-items: center;
`

interface PreviewSizeControlsProps {
    previewSize: PreviewSize
    onPreviewSizeChange: (size: PreviewSize) => void
    targetDevice: string
    studioType: 'engageTools' | 'emailTemplates' | 'tapPlacements' | 'apf' | 'notificationCampaigns' | 'tapPromotions'
}

const sizes = [
    {
        id: 'desktop' as PreviewSize,
        icon: desktopIcon,
    },
    {
        id: 'mobile' as PreviewSize,
        icon: mobileIcon,
    },
]

export function PreviewSizeControls({
    previewSize,
    onPreviewSizeChange,
    targetDevice,
    studioType,
}: PreviewSizeControlsProps) {
    let buttons = sizes
    const buttonInactive = buttons.length === 1
    if (
        studioType === 'engageTools' ||
        studioType === 'tapPlacements' ||
        studioType === 'notificationCampaigns' ||
        studioType === 'tapPromotions'
    ) {
        if (targetDevice === 'mobile') {
            buttons = sizes.filter((s) => s.id === 'mobile')
        } else if (targetDevice === 'desktop') {
            buttons = sizes.filter((s) => s.id === 'desktop')
        }
    }
    return (
        <ButtonsContainer>
            {buttons.map(({ id, icon }) => (
                <PreviewSizeButton
                    key={id}
                    className={previewSize === id ? 'active' : ''}
                    onClick={buttonInactive ? () => {} : () => onPreviewSizeChange(id)}
                >
                    <PreviewSizeIcon src={icon} />
                </PreviewSizeButton>
            ))}
        </ButtonsContainer>
    )
}
