import * as React from 'react'
import styled from 'styled-typed'
import { ActionButton } from 'uiComponents/buttons'
import { ModalDialog, ModalDialogTitle } from 'uiComponents/popups/modal'
import { StyledATag } from 'uiComponents/typography'

const Container = styled.div`
    width: 28em;
`
const NavigationSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2.5em;
`

const ContentBlock = styled.div`
    margin-bottom: 1.5em;
`

const ContentBody = styled.span`
    font-size: 0.875rem;
    line-height: 1.5rem;
`

const ContentFooter = styled.span`
    font-size: 0.75rem;
    line-height: 1rem;
`

interface DialogProps {
    audienceName: string
    title: string
    buttonText: string
    onDraft?: () => void
    onCancel?: () => void
    onConfirm: () => void
}

export function AudienceModal({ audienceName, title, buttonText, onConfirm, onCancel, onDraft }: DialogProps) {
    return (
        <ModalDialog onDismiss={onCancel} interactive fromTop="20%">
            <Container>
                <ModalDialogTitle>{title}</ModalDialogTitle>
                <ContentBlock>
                    <ContentBody>
                        The audience you selected, &quot;<strong>{audienceName},</strong>&quot; includes all users of
                        this audience.
                    </ContentBody>
                </ContentBlock>
                <ContentBlock>
                    <ContentBody>
                        <strong>
                            Depending on the character of your message, please ensure to select the necessary filters.
                        </strong>{' '}
                        According to the local execution of the GDPR, receiving or being subject to marketing messages
                        may require consent.
                    </ContentBody>
                </ContentBlock>
                <ContentFooter>
                    For more information visit our{' '}
                    <StyledATag target="_blank" href="https://support.convious.com/help/">
                        Knowledge Base
                    </StyledATag>
                    .
                </ContentFooter>
                <NavigationSection>
                    {!!onCancel && (
                        <ActionButton size="large" secondary onClick={onCancel}>
                            Cancel
                        </ActionButton>
                    )}
                    {!!onDraft && (
                        <ActionButton size="large" kind="default" style={{ marginLeft: '2em' }} onClick={onDraft}>
                            Save as draft
                        </ActionButton>
                    )}
                    <ActionButton size="large" kind="default" style={{ marginLeft: '2em' }} onClick={onConfirm}>
                        {buttonText}
                    </ActionButton>
                </NavigationSection>
            </Container>
        </ModalDialog>
    )
}
