import * as React from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import Svg from 'svg'
import { FieldType } from 'uiComponents/studio/form/fieldComponentSchema'
import { ProductName } from 'engageTools/studio/schema'
import { productURLSlugs } from 'engageTools/studio/shared'
import { IconProp } from '@fortawesome/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface MenuItemProps {
    product: ProductName | ''
    configurationId: string
    item: MenuItem
    highlighted: boolean
    disabled?: boolean
    accountSlug: string
    history: History
    onClick?: (item: string) => void
}

const Icon = styled(Svg)`
    height: 1.1em;
    min-width: 1.4em;
    max-width: 1.4em;
`
export interface MenuItem {
    icon?: SVGAElement
    fontAwesomeIcon?: IconProp
    name: string
    label: string
}

export interface SectionObject extends MenuItem {
    description: string
    header: string
    sections: ConfigurationUnit[]
    permission?: string
    feature?: string
}

export interface ConfigurationUnit {
    name: string
    label?: string
    sections?: ConfigurationUnit[]
    fields?: FieldType[]
    infotip?: string
}

export const MenuLink = styled.a`
    height: 5em;
    line-height: 5em;
    padding: 0 0.5em 0 0.8em;
    color: ${(props) => props.theme.colors.textDark};
    display: block;
    text-decoration: none;
    transition: color 0.2s ease-in;
    cursor: pointer;

    &.disabled:hover {
        cursor: not-allowed;
    }

    &:hover:not(.disabled):not(.active) {
        color: ${(props) => props.theme.colors.boyBlue};
        background: ${(props) => props.theme.colors.white};

        & svg {
            color: ${(props) => props.theme.colors.boyBlue};
        }
    }

    &.active {
        background: ${(props) => props.theme.colors.white};

        & svg {
            color: ${(props) => props.theme.colors.boyBlue};
        }
    }

    & svg {
        color: ${(props) => props.theme.colors.textDark};
        vertical-align: -2px !important;
        margin-right: 0.6em;
        transition: color 0.2s ease-in;
    }

    &.settings svg {
        color: #fff;
    }
`

function SidebarMenuItem(props: MenuItemProps) {
    function setActive(e: React.MouseEvent<any>) {
        const search = location.search
        e.preventDefault()
        if (!props.disabled) {
            if (!props.product && props.onClick) {
                props.onClick(props.item.name)
            } else {
                props.history.push(
                    `/account/${props.accountSlug}/engage/tools/${productURLSlugs[props.product]}/${
                        props.configurationId
                    }/${props.item.name}${search}`,
                )
            }
        }
    }

    const { item, highlighted, disabled } = props
    const highlightedClass = highlighted ? 'active' : ''
    const disabledClass = disabled ? 'disabled' : ''
    const classes = `${item.name} ${highlightedClass} ${disabledClass}`
    return (
        <>
            <li>
                <MenuLink onClick={setActive} className={classes}>
                    {item.icon && (
                        <>
                            <Icon src={item.icon} />
                            {item.label}
                        </>
                    )}
                    {item.fontAwesomeIcon && (
                        <>
                            <FontAwesomeIcon icon={item.fontAwesomeIcon} />
                            {item.label}
                        </>
                    )}
                </MenuLink>
            </li>
        </>
    )
}

export default SidebarMenuItem
