import { HttpService } from 'http/httpService'
import { LoggingService, EventType, ActionEventData } from 'http/loggingService'

export interface InventoryItem {
    id: string
    name: string
    account: string
    defaultAvailability?: number | null
    reservationPeriodType: 'Gate' | 'Venue'
}

export interface InventoryRule {
    id: string
    name: string | null
    availability: number | null
    priority: number
    effectiveFrom: string | null
    effectiveTo: string | null
    timeFrom: string | null
    timeTo: string | null
    weekdays: number[]
}

export interface InventoryDetails {
    id: string
    name: string
    account: string
    defaultAvailability: number
    availabilityRules: InventoryRule[]
    reservationPeriodType: 'Gate' | 'Venue'
}

export interface InventoryUrls {
    inventoryList(accountSlug: string): string
    inventoryDetails(accountSlug: string, id: string): string
    inventoryRuleDetails(accountSlug: string, inventoryId: string, ruleId: string): string
    inventoryRuleList(accountSlug: string, inventoryId: string): string
}

export class BackofficeInventoryUrls implements InventoryUrls {
    constructor(private backofficeEndpoint: string) {}

    inventoryList(accountSlug: string) {
        return `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/availability_pools/`
    }

    inventoryDetails(accountSlug: string, id: string) {
        return `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/availability_pools/${id}/`
    }

    inventoryRuleList(accountSlug: string, inventoryId: string) {
        return `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/availability_pools/${inventoryId}/rules/`
    }

    inventoryRuleDetails(accountSlug: string, inventoryId: string, ruleId: string) {
        return `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/availability_pools/${inventoryId}/rules/${ruleId}/`
    }
}

export class ReservationsInventoryUrls implements InventoryUrls {
    constructor(private reservationsEndpoint: string) {}

    inventoryList(accountSlug: string) {
        const url = new URL(`${this.reservationsEndpoint}v1/resources`)
        url.searchParams.set('account', accountSlug)
        return url.toString()
    }

    inventoryDetails(accountSlug: string, id: string) {
        return `${this.reservationsEndpoint}v1/resources/${id}`
    }

    inventoryRuleList(accountSlug: string, inventoryId: string) {
        return `${this.reservationsEndpoint}v1/resources/${inventoryId}/rules`
    }

    inventoryRuleDetails(accountSlug: string, inventoryId: string, ruleId: string) {
        return `${this.reservationsEndpoint}v1/resources/${inventoryId}/rules/${ruleId}`
    }
}

export class InventoryService {
    constructor(
        private httpService: HttpService,
        private loggingService: LoggingService,
        private urls: InventoryUrls,
    ) {}

    async getInventoryList(accountSlug: string): Promise<InventoryItem[]> {
        let response = await this.httpService.fetch(this.urls.inventoryList(accountSlug))

        if (!response.ok) {
            throw new Error('Unable to fetch inventory')
        }
        return await response.json()
    }

    async getInventoryDetails(accountSlug: string, id: string): Promise<InventoryDetails> {
        let response = await this.httpService.fetch(this.urls.inventoryDetails(accountSlug, id))
        if (!response.ok) {
            throw new Error('Unable to fetch inventory')
        }
        return await response.json()
    }

    async createInventoryItem(accountSlug: string, data: InventoryItem): Promise<InventoryItem> {
        const logEventType: EventType = 'inventory_created'
        const logEventData: ActionEventData = {
            category: 'inventory',
            payload: data,
        }

        let response = await this.httpService.fetch(this.urls.inventoryList(accountSlug), {
            method: 'POST',
            body: JSON.stringify({ ...data }),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to create inventory')
        }

        response = await this.httpService.fetch(response.headers.get('Location')!)
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to retrieve inventory')
        }

        const body = await response.json()
        this.loggingService.logAction(logEventType, {
            ...logEventData,
            payload: { ...logEventData.payload, id: body.id },
        })
        return body
    }

    async updateInventoryItem(accountSlug: string, data: InventoryItem): Promise<InventoryItem> {
        const logEventType: EventType = 'inventory_updated'
        const logEventData: ActionEventData = {
            category: 'inventory',
            payload: data,
        }

        let response = await this.httpService.fetch(this.urls.inventoryDetails(accountSlug, data.id), {
            method: 'PUT',
            body: JSON.stringify({ ...data }),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to update inventory')
        }

        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async getInventoryRuleDetails(accountSlug: string, inventoryId: string, ruleId: string): Promise<InventoryRule> {
        let response = await this.httpService.fetch(this.urls.inventoryRuleDetails(accountSlug, inventoryId, ruleId))

        if (!response.ok) {
            throw new Error('Unable to fetch inventory rule')
        }
        return await response.json()
    }

    async createInventoryRule(accountSlug: string, inventoryId: string, data: InventoryRule): Promise<InventoryRule> {
        const logEventType: EventType = 'inventory_rule_created'
        const logEventData: ActionEventData = {
            category: 'inventory',
            payload: { ...data, inventoryId },
        }

        let response = await this.httpService.fetch(this.urls.inventoryRuleList(accountSlug, inventoryId), {
            method: 'POST',
            body: JSON.stringify({ ...data }),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to create inventory rule')
        }

        response = await this.httpService.fetch(response.headers.get('Location')!)
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to retrieve inventory rule')
        }

        const body = await response.json()
        this.loggingService.logAction(logEventType, {
            ...logEventData,
            payload: { ...logEventData.payload, id: body.id },
        })
        return body
    }

    async updateInventoryRule(
        accountSlug: string,
        inventoryId: string,
        ruleId: string,
        data: InventoryRule,
    ): Promise<InventoryRule> {
        const logEventType: EventType = 'inventory_rule_updated'
        const logEventData: ActionEventData = {
            category: 'inventory',
            payload: { ...data, inventoryId },
        }

        let response = await this.httpService.fetch(this.urls.inventoryRuleDetails(accountSlug, inventoryId, ruleId), {
            method: 'PUT',
            body: JSON.stringify({ ...data }),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to update inventory rule')
        }

        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async deleteInventoryRule(accountSlug: string, inventoryId: string, ruleId: string): Promise<void> {
        const logEventType: EventType = 'inventory_rule_deleted'
        const logEventData: ActionEventData = {
            category: 'inventory',
            payload: { id: ruleId, inventoryId },
        }

        let response = await this.httpService.fetch(this.urls.inventoryRuleDetails(accountSlug, inventoryId, ruleId), {
            method: 'DELETE',
        })

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to delete inventory rule')
        }
        this.loggingService.logAction(logEventType, logEventData)
    }
}
