import React from 'react'
import styled from 'styled-typed'
import { ResponsiveImage } from 'uiComponents/image'
import conviousPinkLogo from 'images/convious-logo-pink.png'

const CenteredContainer = styled.div`
    display: flex;
    width: 100%;
    background-color: ${(props) => props.theme.colors.white};
    justify-content: center;
    flex-wrap: wrap;
`

const ContentContainer = styled.div`
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const LogoContainer = styled.div`
    padding-top: 2rem;
    display: flex;
    align-content: flex-start;
    justify-content: center;
    flex-wrap: wrap;
`

const TextContainer = styled.div`
    flex-basis: 100%;
    text-align: center;
`

const InlineTools: React.FC = ({ children }) => {
    return (
        <CenteredContainer>
            <ContentContainer>{children}</ContentContainer>
            <LogoContainer>
                <TextContainer>Powered by</TextContainer>
                <ResponsiveImage
                    className="convious-logo"
                    style={{ padding: '1em 1em', display: 'block' }}
                    src={conviousPinkLogo}
                    alt="Convious"
                    width="8.5em"
                />
            </LogoContainer>
        </CenteredContainer>
    )
}

export default InlineTools
