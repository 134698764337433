import { Session } from 'http/session'
import { HttpService } from 'http/httpService'

interface AccountDetails {
    companyName: string
    companyUrl: string
    termsAccepted: boolean
}

interface CreateAccountDetails {
    url: string
    companyName: string
    email: string
    password: string
    termsAccepted: boolean
}

export interface CreatedAccount {
    new_account: { [key: string]: string }
    err_code: string
}

export class SignUpService {
    constructor(
        private dashboardApiEndpoint: string,
        private session: Session,
        private clientId: string,
        private clientSecret: string,
        private httpService: HttpService,
    ) {}

    async createAccount(newAccount: CreateAccountDetails): Promise<CreatedAccount> {
        const response = await fetch(`${this.dashboardApiEndpoint}profile/create_account/`, {
            method: 'POST',
            body: JSON.stringify({
                ...newAccount,
                client_id: this.clientId,
                client_secret: this.clientSecret,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (response.status === 409) {
            throw new Error('email_registered')
        }
        const body = await response.json()
        const ticket = {
            accessToken: body.new_account.access_token,
            refreshToken: body.new_account.refresh_token,
        }
        await this.session.initialize(ticket)
        return body
    }

    async setAccountDetails(details: AccountDetails): Promise<void> {
        const response = await this.httpService.fetch(`${this.dashboardApiEndpoint}profile/account_details/`, {
            method: 'PUT',
            body: JSON.stringify(details),
            headers: {
                'Content-Type': 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`API returned status code: ${response.status}`)
        }
        await this.httpService.refreshToken()
    }
}
