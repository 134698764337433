import { format, getTime, isValid } from 'date-fns'
import {
    AddCampaignPayload,
    NotificationCampaignConfiguration,
    Translations,
    AddCampaignContentPayload,
    NotificationCampaignScheduleConfiguration,
    AddCampaignOneTimeSchedulePayload,
    AddCampaignRecurringSchedulePayload,
    AddCampaignRecurringWhenSchedulePayload,
    FetchNotificationCampaignResponse,
    NotificationCampaignResponseContent,
    ERRORS,
    TargetAudience,
} from './types'
import { parseISODate } from 'utils/dates'

export const stateToPayload = (
    state: NotificationCampaignConfiguration,
    translations: Translations,
): AddCampaignPayload => {
    let content: AddCampaignContentPayload[] = []
    Object.keys(translations).map((key) => {
        const langItem = {
            title: translations[key].title,
            body: translations[key].body,
            buttonLabel: translations[key].buttonLabel,
            locale: key,
            isDefault: !!(state.defaultLanguage === key),
        }

        content.push(langItem)
    })

    const result = {
        name: state.campaign?.name,
        notificationType: state.notification?.type,
        url: state.notification?.url,
        channels: state.target?.channel,
        content,
        schedule: getSchedule(state.schedule),
        status: state.status,
        consentRequired: !!state.target?.marketingConsent,
        audiences: [] as TargetAudience[],
    }

    if (state.target?.audience) {
        result.audiences = state.target.audience.map((audience) => ({ audience }))
    }

    return result
}

const oneTimeSchedule = (date: number, time: string): AddCampaignOneTimeSchedulePayload | undefined => {
    const splitTime = time.split(':')

    if (splitTime && splitTime.length > 1) {
        const dateTime = new Date(date)
        dateTime.setHours(parseInt(splitTime[0], 10))
        dateTime.setMinutes(parseInt(splitTime[1], 10))
        dateTime.setSeconds(0)

        return {
            scheduleType: 'one_time',
            date: dateTime.toISOString(),
        }
    }

    return
}

const recurringSchedule = (
    startDate?: number,
    startTime?: string,
    endDate?: number,
    endTime?: string,
): AddCampaignRecurringSchedulePayload | undefined => {
    const startSplitTime = startTime?.split(':')
    const endSplitTime = endTime?.split(':')

    if (startSplitTime && startSplitTime.length > 1 && startDate) {
        const startDateTime = new Date(startDate)
        startDateTime.setHours(parseInt(startSplitTime[0], 10))
        startDateTime.setMinutes(parseInt(startSplitTime[1], 10))
        startDateTime.setSeconds(0)

        let endDateTime

        if (endDate && endTime && endSplitTime && endSplitTime.length > 1) {
            endDateTime = new Date(endDate)
            endDateTime.setHours(parseInt(endSplitTime[0], 10))
            endDateTime.setMinutes(parseInt(endSplitTime[1], 10))
            endDateTime.setSeconds(0)
        }

        return {
            scheduleType: 'recurring',
            start: startDateTime.toISOString(),
            end: endDateTime?.toISOString(),
        }
    }

    return
}

const getSchedule = (
    schedule?: NotificationCampaignScheduleConfiguration,
): AddCampaignOneTimeSchedulePayload | AddCampaignRecurringSchedulePayload | undefined => {
    if (!schedule) {
        return
    }

    const when: AddCampaignRecurringWhenSchedulePayload = {
        unit: schedule.whenUnit,
        interval: schedule.interval || 0,
        monday: !!schedule.scheduleWhenMonday,
        tuesday: !!schedule.scheduleWhenTuesday,
        wednesday: !!schedule.scheduleWhenWednesday,
        thursday: !!schedule.scheduleWhenThursday,
        friday: !!schedule.scheduleWhenFriday,
        saturday: !!schedule.scheduleWhenSaturday,
        sunday: !!schedule.scheduleWhenSunday,
    }

    if (schedule.scheduleType && schedule.scheduleType.toLowerCase() === 'one_time' && schedule.date && schedule.time) {
        return { ...oneTimeSchedule(schedule.date, schedule.time), when }
    } else {
        return {
            ...recurringSchedule(schedule.startDate, schedule.startTime, schedule.endDate, schedule.endTime),
            when,
        }
    }
}

export const payloadToState = (payload: FetchNotificationCampaignResponse): NotificationCampaignConfiguration => {
    let date
    let startDate
    let endDate

    if (payload.schedule.date && isValid(new Date(payload.schedule.date))) {
        date = parseISODate(payload.schedule.date)
    }

    if (payload.schedule.start && isValid(new Date(payload.schedule.start))) {
        startDate = parseISODate(payload.schedule.start)
    }

    if (payload.schedule.end && isValid(new Date(payload.schedule.end))) {
        endDate = parseISODate(payload.schedule.end)
    }
    return {
        status: payload.status,
        campaign: {
            name: payload.name,
        },
        notification: {
            type: payload.notificationType,
            url: payload.url,
        },
        target: {
            audience: payload?.audiences?.map((audience) => audience.audience),
            marketingConsent: payload.consentRequired,
            channel: payload.channels,
        },
        schedule: {
            scheduleType: payload.schedule.scheduleType,
            date: date && getTime(date),
            time: date && format(date, 'HH:mm'),
            endDate: endDate && getTime(endDate),
            endTime: endDate && format(endDate, 'HH:mm'),
            startDate: startDate && getTime(startDate),
            startTime: startDate && format(startDate, 'HH:mm'),
            whenUnit: payload.schedule.when.unit,
            scheduleWhenMonday: payload.schedule.when.monday,
            scheduleWhenTuesday: payload.schedule.when.tuesday,
            scheduleWhenWednesday: payload.schedule.when.wednesday,
            scheduleWhenThursday: payload.schedule.when.thursday,
            scheduleWhenFriday: payload.schedule.when.friday,
            scheduleWhenSaturday: payload.schedule.when.saturday,
            scheduleWhenSunday: payload.schedule.when.sunday,
            interval: payload.schedule.when.interval,
        },
        orderFilter: {
            amountOfDays: payload?.orderFilter?.amountOfDays,
            direction: payload?.orderFilter?.direction,
        },
    }
}

export const payloadToTranslations = (translations: NotificationCampaignResponseContent[]): Translations => {
    const content = {}

    translations.forEach((translation) => {
        const item = {
            title: translation.title,
            body: translation.body,
            buttonLabel: translation.buttonLabel,
        }

        content[translation.locale] = item
    })

    return content
}

export const payloadToDefaultLanguage = (translations: NotificationCampaignResponseContent[]): string => {
    let defaultLanguage = 'en'

    translations.forEach((translation) => {
        if (translation.isDefault) {
            defaultLanguage = translation.locale
        }
    })

    return defaultLanguage
}

const ERROR_MESSAGE_MAP = {
    [ERRORS.CAMPAIGN_NAME_MISSING]: 'Name -> Campaign name is missing',
    [ERRORS.NOTIFICATION_CONTENT_MISSING]: 'Notifcation -> None of the languages are filled in',
    [ERRORS.START_DATE_MISSING]: 'Scheduling -> Start date / time is not set',
    [ERRORS.WRONG_INTERVAL]: 'Scheduling -> Interval is incorrectly configured',
}

export const getReadableError = (error?: string): string =>
    error ? ERROR_MESSAGE_MAP[error] : error || 'Unknown error'
