import { createAction } from 'typesafe-actions'

export const popoverDismissed = createAction(
    'PopoverDismissed',
    (action) => (popoverId: string) =>
        action({
            type: 'PopoverDismissed',
            popoverId,
        }),
)
