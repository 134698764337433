import { Audience } from '../schema'

export const audiencesList = [
    { name: 'Guests in the venue today', slug: 'visits_today' },
    { name: 'Guests in the venue tomorrow', slug: 'visits_tomorrow' },
    { name: 'Profiles with email', slug: 'has_email' },
    { name: 'Profiles with mobile device', slug: 'mobile_device' },
    { name: 'Profiles with purchase', slug: 'made_purchases' },
    {
        name: 'Profiles with purchase in the last 4 weeks',
        slug: 'purchases_in_last_4_weeks',
    },
    {
        name: 'Profiles with 2 visits in the last 12 months',
        slug: 'two_visits_in_last_12_months',
    },
    { name: 'Profiles with TAP app', slug: 'has_tap_app' },
    {
        name: 'Profiles with purchase and no TAP app',
        slug: 'made_purchases_no_tap_app_installed',
    },
    { name: 'Profiles with carts and no payments', slug: 'warm_prospects' },
    { name: 'Profiles with orders and no payments', slug: 'hot_prospects' },
] as Audience[]
