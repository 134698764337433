import { CursorPaginatedResponse } from '../../types'
import { pathUtils } from './pathnameFormatter'

interface FetchFullyPaginatedDataProps<T> {
    fetchData: (args: any) => Promise<CursorPaginatedResponse<T> | undefined>
    onError?: () => void
}

export const fetchFullyCursorPaginatedData = async <T>({
    fetchData,
    onError,
    ...args
}: FetchFullyPaginatedDataProps<T>): Promise<T[]> => {
    let allData = [] as T[]

    let response = await fetchData(args)
    if (!response) {
        return []
    }

    try {
        if (response.results) {
            allData = allData.concat(response.results)
        }

        while (response?.next) {
            const nextPath = new URL(response.next) as URL
            response = await fetchData({
                ...args,
                cursor: pathUtils.addQueryString(nextPath.pathname.replace('/', ''), nextPath.search),
            })

            if (!response) {
                onError?.()
                throw new Error('Error fetching paginated data')
            }

            if (response.results) {
                allData = allData.concat(response.results)
            }
        }
    } catch (error) {
        onError?.()
        throw new Error('Error fetching paginated data')
    }

    return allData
}
