import * as React from 'react'
import styled from 'styled-typed'
import { History } from 'history'
import { Row, Col } from 'uiComponents/flex'
import { ActionButton } from 'uiComponents/buttons'
import { DotNavigation } from 'uiComponents/navigation/dotNavigation'
import { productURLSlugs } from 'engageTools/studio/shared'
import {
    ProductName,
    WonderbarConfiguration,
    ModalWindowConfiguration,
    TriggerButtonConfiguration,
    CheckoutConfiguration,
    StudioStep,
} from 'engageTools/studio/schema'

interface FooterProps {
    product: ProductName | ''
    navPathOverride?: string
    history: History
    currentStep: StudioStep | ''
    currentStepOverride?: string
    menuItems: Array<string>
    formSubmit: (e: React.MouseEvent<any>) => void
    accountSlug: string
    ongoingPublish: boolean
    style?: React.CSSProperties
    configurationId: string
    componentValues:
        | WonderbarConfiguration
        | ModalWindowConfiguration
        | TriggerButtonConfiguration
        | CheckoutConfiguration
        | null
}

interface FooterState {
    activeIndex: number
}

const FooterWrapper = styled.div`
    flex: 0 0 6em;
    height: 6em;
    background: ${(props) => props.theme.colors.white};
    vertical-align: middle;
    line-height: 6em;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 30em;
    z-index: 1;
`

export class Footer extends React.Component<FooterProps, FooterState> {
    constructor(props: FooterProps) {
        super(props)
        const index = this.props.menuItems.indexOf(this.props.currentStepOverride || this.props.currentStep)
        this.state = {
            activeIndex: index,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: FooterProps) {
        const stepIndex = nextProps.menuItems.indexOf(nextProps.currentStepOverride || nextProps.currentStep)
        if (stepIndex !== this.state.activeIndex) {
            this.setState({ activeIndex: stepIndex })
        }
    }

    moveStepBack = (e: React.MouseEvent<any>) => {
        e.preventDefault()
        this.moveStep('backwards')
    }
    moveStepForward = (e: React.MouseEvent<any>) => {
        e.preventDefault()
        this.moveStep('forward')
    }

    moveStep = (direction: string) => {
        let nextIndex = -999
        if (direction === 'backwards') {
            nextIndex = this.state.activeIndex !== 0 ? this.state.activeIndex - 1 : 0
        } else if (direction === 'forward') {
            let previousIndex = this.props.menuItems.length - 1
            nextIndex = this.state.activeIndex !== previousIndex ? this.state.activeIndex + 1 : previousIndex
        }
        this.navigateStep(nextIndex)
    }

    navigateStep = (stepIndex: number) => {
        const step = this.props.menuItems[stepIndex]
        const search = location.search
        const path =
            this.props.navPathOverride && !this.props.product
                ? this.props.navPathOverride
                : `/account/${this.props.accountSlug}/engage/tools/${productURLSlugs[this.props.product]}/${
                      this.props.configurationId
                  }`
        this.props.history.push(`${path}/${step}${search}`)
        this.setState({ activeIndex: stepIndex })
    }

    LastButton = (props: { ongoingPublish: boolean }) => {
        const text = this.state.activeIndex === this.props.menuItems.length - 1 ? 'Publish' : 'Next'
        const action =
            this.state.activeIndex === this.props.menuItems.length - 1 ? this.props.formSubmit : this.moveStepForward
        const type = this.state.activeIndex === this.props.menuItems.length - 1 ? 'default' : 'action'

        return (
            <ActionButton id="next-step" size="large" kind={type} onClick={action} disabled={props.ongoingPublish}>
                {text}
            </ActionButton>
        )
    }

    render() {
        const configuration = this.props.navPathOverride ? {} : this.props.componentValues
        return (
            <FooterWrapper style={{ ...this.props.style }}>
                <Row>
                    <Col span={4}>
                        {this.state.activeIndex !== 0 && (
                            <ActionButton size="large" secondary onClick={this.moveStepBack}>
                                Back
                            </ActionButton>
                        )}
                    </Col>
                    <Col span={4}>
                        <DotNavigation
                            activeIndex={this.state.activeIndex}
                            amount={this.props.menuItems.length}
                            onClick={this.navigateStep}
                            disabled={!configuration}
                        />
                    </Col>
                    <Col span={4}>
                        <this.LastButton ongoingPublish={this.props.ongoingPublish} />
                    </Col>
                </Row>
            </FooterWrapper>
        )
    }
}
