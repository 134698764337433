/* eslint-disable id-denylist */
import { HttpService } from 'http/httpService'
import { LoggingService, EventType, EventCategoryType, ActionEventData } from 'http/loggingService'
import { AttachedProduct } from 'products/attachedCategoriesList'
import { pathUtils } from 'utils/pathnameFormatter'
import { CursorPaginatedResponse, ErrorResponse, PaginatedResponse } from '../../../types'
import { DiscountCodeBEForm, DiscountCodeDetails } from './types'

export type BarcodePoolType = 'Uploaded' | 'Generated' | 'Merac' | 'Elli' | 'ReservationVouchers'
export type BarcodePoolFormat = 'ean13' | 'ean8' | 'code128' | 'code39' | 'reservation_code' | 'qr_code'
export type UploadType = 'barcodes' | 'vouchers' | 'coupons' | 'reservations' | 'sold_tickets'

export interface RemoveUnusedCodesItem {
    poolId: string
    batchId: string | null
}

export interface Uploads {
    count: number
    next: string | null
    previous: string | null
    results: UploadDetails[]
}

export interface UploadDetails {
    uploadId: string
    filename: string
    createdAt: string
    status: string
    codeCount: number
    barcodeCount: number
    createdBy: string
}

export interface PoolsPage {
    totalCount: number
    depletedCount: number
    currentPage: number
    nextPage: number
    prevPage: number
    pageSize: number
}

export interface BarcodePool {
    id: string
    name: string
    format: BarcodePoolFormat
    poolType: BarcodePoolType
    barcodesUsedInLastDay: number
    barcodesLeft: number
    daysLeft: number
    uploadStatus: 'pending' | null
    products: AttachedProduct[]
}

export interface GenerateReservationCodespayload {
    name: string
    prefix: string
    barcodeScanningNote: string
    validFrom: string
    validTo: string
    articles: string[]
    amount: number
}

export interface ReservationCodesBatch {
    uploadId: string
    filename: string
    totalCodes: number
    redeemedCodes: number
    type: 'generate_reservation_codes' | 'vouchers_as_barcodes'
    status: string
}

export interface ReservationCodesPool {
    id: string
    name: string
    externalChannel: string
    hasImportRequests: boolean
    importRequests?: ReservationCodesBatch[]
    isScannable: boolean
}

export interface DiscountsPool {
    uuid: string
    name: string
    couponsLeft: number
    uploadStatus: 'pending' | null
    assignedToPostEventEmails: boolean
}

export interface BarcodePoolsPage extends PoolsPage {
    entries: BarcodePool[]
}

export interface DiscountsPoolsPage extends PoolsPage {
    entries: DiscountsPool[]
}

export interface ReservationCodesPoolPage extends PoolsPage {
    entries: ReservationCodesPool[]
}

export interface UsedBarcodesCount {
    usedBarcodesCount: number
}

interface DeleteUploadTypeMapData {
    path: string
    eventType: EventType
    eventCategory: EventCategoryType
}

interface DeleteUploadTypeMap {
    barcodes: DeleteUploadTypeMapData
    vouchers: DeleteUploadTypeMapData
    coupons: DeleteUploadTypeMapData
    reservations: DeleteUploadTypeMapData
}

const deleteUploadTypeMap: DeleteUploadTypeMap = {
    barcodes: {
        path: 'barcode_reservoirs_v2/import_request',
        eventType: 'barcodes_deleted',
        eventCategory: 'codes_barcodes',
    },
    vouchers: {
        path: 'vouchers_upload',
        eventType: 'vouchers_deleted',
        eventCategory: 'codes_vouchers',
    },
    coupons: {
        path: 'coupons_upload',
        eventType: 'coupons_deleted',
        eventCategory: 'codes_coupons',
    },
    reservations: {
        path: 'sold_barcodes_upload',
        eventType: 'reservation_codes_deleted',
        eventCategory: 'codes_reservations',
    },
}

export interface DiscountPoolUpload {
    uuid: string
    name: string
    type: 'csv' | 'single_code'
    uploadedBy?: string
    uploadedAt: string
    products?: DiscountCodeProducts[]
    usage: {
        used: number
        total: number
    }
    status: 'deleted' | 'completed' | 'error' | 'expired' | 'pending' | 'redeemed' | 'valid'
}

export interface DiscountPoolCode {
    id: string
    code: string
}

export interface DiscountPool {
    uuid: string
    name: string
    type: string
    usage: {
        used: number
        total: number
    }
    uploads: DiscountPoolUpload[]
    codes?: DiscountPoolCode[]
}

export interface DiscountCodeProducts {
    id: string
    number: string
    uuid: string
}

export interface DiscountCode {
    code: string
    orders?: string
    status: 'valid' | 'redeemed' | 'expired'
    usage?: {
        used: number
        total: number
    }
    validity?: {
        fromDate: string
        toDate: string
    }
    discount: {
        amount: string
        type: 'percentage' | 'flat'
    }
    products?: DiscountCodeProducts[]
    discountReservoirName: string
}

export interface GetDiscountUploadDetailsResponse {
    entry: DiscountPoolUpload
}

export interface DiscountPoolResponse extends PaginatedResponse<DiscountPool[]> {}
export interface DiscountCodeResponse extends PaginatedResponse<DiscountCode[]> {}
export interface DiscountCodeCursorPaginatedResponse extends CursorPaginatedResponse<DiscountCode[]> {}

export class BookingCodesService {
    constructor(
        private httpService: HttpService,
        private loggingService: LoggingService,
        private backofficeEndpoint: string,
    ) {}

    async getReservationCodePoolsList(accountSlug: string, query: string): Promise<ReservationCodesPoolPage> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/reservation_code_reservoirs/${query}`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch reservation code pools')
        }
        return await response.json()
    }

    async getReservationCodePoolDetails(accountSlug: string, poolId: string): Promise<ReservationCodesPool> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/reservation_code_reservoirs/${poolId}/`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch reservation code pools')
        }
        return await response.json()
    }

    async createReservationCodesPool(
        accountSlug: string,
        name: string,
        externalChannel: string,
        isScannable: boolean,
    ): Promise<void> {
        const logEventType: EventType = 'reservation_codes_pool_created'
        const logEventData: ActionEventData = {
            category: 'codes_reservations',
            payload: { name, externalChannel, isScannable },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/reservation_code_reservoirs/`,
            {
                method: 'POST',
                body: JSON.stringify({ name, externalChannel, isScannable }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to create a reservation codes pool')
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async editReservationCodesPool(accountSlug: string, id: string, payload: ReservationCodesPool): Promise<Response> {
        return this.httpService
            .fetch(`${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/reservation_code_reservoirs/${id}/`, {
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            })
            .then(
                this.loggingService.createLogHttpResponse(
                    'reservation_codes_pool_edited',
                    'Unable to edit a reservation codes pool',
                    {
                        category: 'codes_reservations',
                        payload,
                    },
                ),
            )
    }

    async updateReservationCodesPoolName(
        accountSlug: string,
        poolId: string,
        name: string,
        externalChannel: string,
    ): Promise<void> {
        const logEventType: EventType = 'reservation_codes_pool_name_updated'
        const logEventData: ActionEventData = {
            category: 'codes_reservations',
            payload: { pool_id: poolId, new_name: name, externalChannel },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/reservation_code_reservoirs/${poolId}/`,
            {
                method: 'PUT',
                body: JSON.stringify({ name, externalChannel }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to create a barcode pool')
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async deleteReservationPool(accountSlug: string, poolId: string): Promise<void> {
        const logEventType: EventType = 'reservation_codes_pool_archived'
        const logEventData: ActionEventData = {
            category: 'codes_reservations',
            payload: { archived_pool_id: poolId },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/reservation_code_reservoirs/${poolId}/`,
            {
                method: 'DELETE',
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to archive reservation codes pool')
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async generateReservationCodes(
        accountSlug: string,
        poolId: string,
        payload: GenerateReservationCodespayload,
    ): Promise<void> {
        const logEventType: EventType = 'reservation_codes_generate_request_placed'
        const logEventData: ActionEventData = {
            category: 'codes_reservations',
            payload: { pool_id: poolId, ...payload },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/reservation_code_reservoirs/${poolId}/generate/`,
            {
                method: 'POST',
                body: JSON.stringify({ ...payload }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to archive reservation codes pool')
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async getReservationsUploadHistoryList(
        accountSlug: string,
        poolId?: string,
        queryString?: string,
    ): Promise<Uploads> {
        const endpoint = poolId ? `reservation_code_reservoirs/${poolId}/upload` : 'sold_barcodes_upload/'
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/${endpoint}?${queryString}`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch upload history')
        }
        return await response.json()
    }

    async removeUnusedCodes(
        file: File,
        accountSlug: string,
        poolId: string,
        batchId: string | null,
    ): Promise<UploadDetails> {
        const logEventType: EventType = 'unused_reservation_codes_remove_request'
        const logEventData: ActionEventData = {
            category: 'codes_reservations',
            payload: { file_name: file.name },
        }

        const data = new FormData()
        data.append('file', file)
        const endpoint = batchId ? `batches/${batchId}/invalidate/` : 'invalidate/'
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/reservation_code_reservoirs/${poolId}/${endpoint}`,
            {
                method: 'POST',
                body: data,
            },
        )
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Oops! We could not upload your file. Please try again.')
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async uploadReservationCodes(file: File, accountSlug: string, poolId?: string): Promise<UploadDetails> {
        const logEventType: EventType = 'reservation_barcodes_upload_request'
        const logEventData: ActionEventData = {
            category: 'codes_reservations',
            payload: { file_name: file.name },
        }

        const data = new FormData()
        data.append('file', file)
        const endpoint = poolId ? `reservation_code_reservoirs/${poolId}/upload/` : 'sold_barcodes_upload/'
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/${endpoint}`,
            {
                method: 'POST',
                body: data,
            },
        )
        if (response.status !== 202) {
            if (response.status === 400) {
                const body = await response.json()
                this.loggingService.logError(body, logEventType, logEventData)
                throw new Error(
                    body.error && body.error.message
                        ? body.error.message
                        : 'Oops! We could not upload your file. Please try again.',
                )
            } else {
                this.loggingService.logResponseError(response, logEventType, logEventData)
            }
            throw new Error('Oops! We could not upload your file. Please try again.')
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async getVouchersUploadHistoryList(accountSlug: string): Promise<UploadDetails[]> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/vouchers_upload/`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch upload history')
        }
        return await response.json()
    }

    async uploadVouchersCodes(file: File, accountSlug: string): Promise<UploadDetails> {
        const logEventType: EventType = 'vouchers_upload_request'
        const logEventData: ActionEventData = {
            category: 'codes_vouchers',
            payload: { file_name: file.name },
        }

        const data = new FormData()
        data.append('file', file)
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/vouchers_upload/`,
            {
                method: 'POST',
                body: data,
            },
        )
        if (response.status !== 202) {
            if (response.status === 400) {
                const body = await response.json()
                this.loggingService.logError(body, logEventType, logEventData)
                throw new Error(
                    body.error && body.error.message
                        ? body.error.message
                        : 'Oops! We could not upload your file. Please try again.',
                )
            } else {
                this.loggingService.logResponseError(response, logEventType, logEventData)
            }
            throw new Error('Oops! We could not upload your file. Please try again.')
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async getCouponsUploadHistoryList(accountSlug: string, poolId?: string | null): Promise<UploadDetails[]> {
        const endpoint = poolId ? `discount_reservoirs/${poolId}/upload/` : 'coupons_upload/'
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/${endpoint}`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch upload history')
        }
        return await response.json()
    }

    async uploadCouponsCodes(file: File, accountSlug: string, poolId: string): Promise<UploadDetails> {
        const logEventType: EventType = 'coupons_upload_request'
        const logEventData: ActionEventData = {
            category: 'codes_coupons',
            payload: { file_name: file.name },
        }

        const data = new FormData()
        data.append('file', file)
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_reservoirs/${poolId}/upload/`,
            {
                method: 'POST',
                body: data,
            },
        )
        if (response.status !== 202) {
            if (response.status === 400) {
                const body = await response.json()
                this.loggingService.logError(body, logEventType, logEventData)
                throw new Error(
                    body.error && body.error.message
                        ? body.error.message
                        : 'Oops! We could not upload your file. Please try again.',
                )
            } else {
                this.loggingService.logResponseError(response, logEventType, logEventData)
            }
            throw new Error('Oops! We could not upload your file. Please try again.')
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async getSeasonPassesUploadHistoryList(accountSlug: string): Promise<UploadDetails[]> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/ticket_upload/`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch upload history')
        }
        return await response.json()
    }

    async uploadSeasonPasses(file: File, accountSlug: string): Promise<UploadDetails> {
        const logEventType: EventType = 'sold_tickets_upload_request'
        const logEventData: ActionEventData = {
            category: 'sold_tickets',
            payload: { file_name: file.name },
        }

        const data = new FormData()
        data.append('file', file)
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/ticket_upload/`,
            {
                method: 'POST',
                body: data,
            },
        )
        if (response.status !== 202) {
            if (response.status === 400) {
                const body = await response.json()
                this.loggingService.logError(body, logEventType, logEventData)
                throw new Error(
                    body.error && body.error.message
                        ? body.error.message
                        : 'Oops! We could not upload your file. Please try again.',
                )
            } else {
                this.loggingService.logResponseError(response, logEventType, logEventData)
            }
            throw new Error('Oops! We could not upload your file. Please try again.')
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async getBarcodePoolsList(accountSlug: string, query: string): Promise<BarcodePoolsPage> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/barcode_reservoirs_v2/${query}`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch barcode pools')
        }
        return await response.json()
    }

    async getBarcodesUploadHistoryList(accountSlug: string, poolId: string): Promise<UploadDetails[]> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/barcode_reservoirs_v2/${poolId}/uploads/`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch upload history')
        }
        return await response.json()
    }

    async uploadBarcodes(file: File, accountSlug: string, poolId: string): Promise<UploadDetails> {
        const logEventType: EventType = 'barcodes_upload_request'
        const logEventData: ActionEventData = {
            category: 'codes_barcodes',
            payload: { file_name: file.name, barcode_pool_id: poolId },
        }

        const data = new FormData()
        data.append('file', file)
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/barcode_reservoirs_v2/${poolId}/uploads/`,
            {
                method: 'POST',
                body: data,
            },
        )
        if (response.status !== 202) {
            if (response.status === 400) {
                const body = await response.json()
                this.loggingService.logError(body, logEventType, logEventData)
                throw new Error(body.error?.message ?? 'Oops! We could not upload your file. Please try again.')
            } else {
                this.loggingService.logResponseError(response, logEventType, logEventData)
            }
            throw new Error('Oops! We could not upload your file. Please try again.')
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async createBarcodePool(
        accountSlug: string,
        name: string,
        format: BarcodePoolFormat,
        poolType: BarcodePoolType,
        barcodePrefix: string,
    ): Promise<void> {
        const logEventType: EventType = 'barcode_pool_created'
        const logEventData: ActionEventData = {
            category: 'codes_barcodes',
            payload: {
                name,
                pool_format: format,
                pool_type: poolType,
                ...(barcodePrefix ? { barcode_prefix: barcodePrefix } : {}),
            },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/barcode_reservoirs_v2/`,
            {
                method: 'POST',
                body: JSON.stringify({
                    name,
                    format,
                    poolType,
                    ...(barcodePrefix ? { barcode_prefix: barcodePrefix } : {}),
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to create a barcode pool')
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async editBarcodePoolName(accountSlug: string, poolId: string, name: string): Promise<void> {
        const logEventType: EventType = 'barcode_pool_name_updated'
        const logEventData: ActionEventData = {
            category: 'codes_barcodes',
            payload: { pool_id: poolId, new_name: name },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/barcode_reservoirs_v2/${poolId}/`,
            {
                method: 'PUT',
                body: JSON.stringify({ name }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to create a barcode pool')
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async getUsedBarcodesCount(accountSlug: string, uploadId: string): Promise<UsedBarcodesCount> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/barcode_reservoirs_v2/import_request/${uploadId}/`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch used barcodes count')
        }
        return await response.json()
    }

    async deleteUploadedCodes(accountSlug: string, uploadType: UploadType, uploadId: string): Promise<void> {
        const logEventType: EventType = deleteUploadTypeMap[uploadType].eventType
        const logEventData: ActionEventData = {
            category: deleteUploadTypeMap[uploadType].eventCategory,
            payload: { import_request: uploadId },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/${deleteUploadTypeMap[uploadType].path}/${uploadId}/`,
            {
                method: 'DELETE',
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to delete codes')
        }
        this.loggingService.logAction(logEventType, logEventData)
    }

    async getDiscountPools({
        accountSlug,
        query,
    }: {
        accountSlug: string
        query?: string
    }): Promise<DiscountPoolResponse> {
        const logEventType: EventType = 'get_discount_pools'
        const logEventData: ActionEventData = {
            category: 'discount_codes',
            payload: { accountSlug, query },
        }

        const response = await this.httpService.fetch(
            pathUtils.addQueryString(`${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_pools/`, query),
            { method: 'GET' },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to get discount pools')
        }

        this.loggingService.logAction(logEventType, logEventData)

        return response.json()
    }

    async getDiscountCodes({
        accountSlug,
        query,
    }: {
        accountSlug: string
        query?: string
    }): Promise<DiscountCodeResponse> {
        const logEventType: EventType = 'get_discount_codes'
        const logEventData: ActionEventData = {
            category: 'discount_codes',
            payload: { accountSlug, query },
        }

        const response = await this.httpService.fetch(
            pathUtils.addQueryString(`${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_codes/`, query),
            { method: 'GET' },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to get discount codes')
        }

        this.loggingService.logAction(logEventType, logEventData)

        return response.json()
    }

    async getDiscountCodesCursorPaginated({
        accountSlug,
        query,
    }: {
        accountSlug: string
        query?: string
    }): Promise<DiscountCodeCursorPaginatedResponse> {
        const logEventType: EventType = 'get_discount_codes'
        const logEventData: ActionEventData = {
            category: 'discount_codes',
            payload: { accountSlug, query },
        }

        const response = await this.httpService.fetch(
            pathUtils.addQueryString(`${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_codes/`, query),
            { method: 'GET' },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to get discount codes')
        }

        this.loggingService.logAction(logEventType, logEventData)

        return response.json()
    }

    async getDiscountPoolsList(accountSlug: string, query: string): Promise<DiscountsPoolsPage> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_reservoirs/${query}`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch discount pools')
        }
        return await response.json()
    }

    async addDiscountPool({ accountSlug, name }: { accountSlug: string; name: string }): Promise<DiscountPool> {
        const logEventType: EventType = 'add_discount_pool'
        const logEventData: ActionEventData = {
            category: 'discount_codes',
            payload: { accountSlug, name },
        }

        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_pools/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({ name }),
            },
        )

        try {
            this.loggingService.logAction(logEventType, logEventData)

            return response.json()
        } catch (error) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to create discount code')
        }
    }

    async addCodeByForm({
        accountSlug,
        uuid,
        formData,
    }: {
        accountSlug: string
        uuid: string
        formData: DiscountCodeBEForm
    }): Promise<DiscountCodeDetails | ErrorResponse<DiscountCodeDetails>> {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_pools/${uuid}/discount_codes/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(formData),
            },
        )

        try {
            return response.json()
        } catch (error) {
            throw new Error('Unable to create discount code')
        }
    }

    async deleteDiscountPool({ accountSlug, uuid }: { accountSlug: string; uuid: string }) {
        const logEventType: EventType = 'delete_discount_pool'
        const logEventData: ActionEventData = {
            category: 'discount_codes',
            payload: { accountSlug, uuid },
        }

        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_pools/${uuid}/`,
            {
                method: 'DELETE',
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to delete discount pool')
        }

        this.loggingService.logAction(logEventType, logEventData)
    }
    async editDiscountPool({ accountSlug, uuid, name }: { accountSlug: string; uuid: string; name: string }) {
        const logEventType: EventType = 'edit_discount_pool'
        const logEventData: ActionEventData = {
            category: 'discount_codes',
            payload: { accountSlug, uuid },
        }

        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_pools/${uuid}/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({ name }),
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to edit discount pool')
        }

        this.loggingService.logAction(logEventType, logEventData)
    }

    async getDiscountCodeDetails({
        accountSlug,
        codeUuid,
        poolUuid,
    }: {
        accountSlug: string
        poolUuid: string
        codeUuid: string
    }) {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_pools/${poolUuid}/discount_codes/${codeUuid}/`,
            {
                method: 'GET',
            },
        )

        return this.loggingService.handleResponse<DiscountCodeDetails>({
            response,
            logEventType: 'get_discount_code_details',
            logEventData: {
                category: 'discount_codes',
                payload: {
                    accountSlug,
                    poolUuid,
                    codeUuid,
                },
            },
        })
    }

    async editDiscountCode({
        accountSlug,
        codeUuid,
        poolUuid,
        data,
    }: {
        accountSlug: string
        poolUuid: string
        codeUuid: string
        data: DiscountCodeBEForm
    }) {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_pools/${poolUuid}/discount_codes/${codeUuid}/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(data),
            },
        )

        return this.loggingService.handleResponse({
            response,
            logEventType: 'update_discount_code',
            logEventData: {
                category: 'discount_codes',
                payload: {
                    accountSlug,
                    poolUuid,
                    codeUuid,
                },
            },
        })
    }

    async redeemUnusedDiscountCodesFromUploads({ accountSlug, uuid }: { accountSlug: string; uuid: string }) {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_upload/${uuid}/redeem/`,
            {
                method: 'POST',
            },
        )

        return this.loggingService.handleResponse({
            response,
            logEventType: 'redeem_discount_codes',
            logEventData: {
                category: 'discount_codes',
                payload: { accountSlug, uuid },
            },
        })
    }

    async redeemUnusedDiscountCodesFromCode({ accountSlug, uuid }: { accountSlug: string; uuid: string }) {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_codes/${uuid}/redeem/`,
            {
                method: 'POST',
            },
        )

        return this.loggingService.handleResponse({
            response,
            logEventType: 'redeem_discount_codes',
            logEventData: {
                category: 'discount_codes',
                payload: { accountSlug, uuid },
            },
        })
    }

    async uploadDiscountCode({
        accountSlug,
        uuid,
        file,
    }: {
        accountSlug: string
        uuid: string
        file: File
    }): Promise<DiscountCodeDetails | ErrorResponse<DiscountCodeDetails>> {
        const formData = new FormData()
        formData.set('file', file)

        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_pools/${uuid}/upload/`,
            {
                method: 'POST',
                body: formData,
            },
        )

        return this.loggingService.handleResponse({
            logEventType: 'upload_discount_codes',
            logEventData: {
                category: 'discount_codes',
                payload: { accountSlug, uuid, file: file.name },
            },
            response,
        })
    }

    async createDiscountPool(accountSlug: string, name: string): Promise<void> {
        const logEventType: EventType = 'coupons_pool_created'
        const logEventData: ActionEventData = {
            category: 'codes_coupons',
            payload: { name },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_reservoirs/`,
            {
                method: 'POST',
                body: JSON.stringify({ name }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to create a discount pool')
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async editDiscountPoolName(accountSlug: string, poolUuid: string, name: string): Promise<void> {
        const logEventType: EventType = 'coupons_pool_name_updated'
        const logEventData: ActionEventData = {
            category: 'codes_coupons',
            payload: { pool_id: poolUuid, new_name: name },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_reservoirs/${poolUuid}/`,
            {
                method: 'PUT',
                body: JSON.stringify({ name }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to update a discount pool name')
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async getDiscountUploadDetails({
        accountSlug,
        id,
    }: {
        accountSlug: string
        id: string
    }): Promise<GetDiscountUploadDetailsResponse> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/discount_uploads/${id}/`,
            {
                method: 'GET',
            },
        )

        return this.loggingService.handleResponse({
            response,
            logEventType: 'get_discount_upload_details',
            logEventData: {
                category: 'discount_codes',
                payload: { accountSlug, id },
            },
        })
    }
}
