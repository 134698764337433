export class PasswordResetService {
    constructor(private identityEndpoint: string) {}

    createResetToken = async (email: string): Promise<boolean> => {
        const response = await fetch(this.identityEndpoint + 'oauth/password_reset/', {
            method: 'POST',
            body: JSON.stringify({
                username: email,
            }),
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (response.status === 500) {
            const reason = await response.text()
            throw new Error(`Server failure: ${reason}`)
        }
        return response.status >= 200 && response.status < 300
    }

    consumeResetToken = async (token: string, password: string): Promise<boolean> => {
        const response = await fetch(this.identityEndpoint + 'oauth/password_reset/', {
            method: 'POST',
            body: JSON.stringify({
                token,
                password,
            }),
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        if (response.status === 500) {
            const reason = await response.text()
            throw new Error(`Server failure: ${reason}`)
        }
        return response.status >= 200 && response.status < 300
    }
}
