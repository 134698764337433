import { HttpService } from 'http/httpService'

type Locale = {
    id: string
    short_date_format: string
    short_time_format: string
    currency_format: string
    decimal_separator: string
    thousand_separator: string
    character_order: 'left-to-right' | 'right-to-left' | 'top-to-bottom' | 'bottom-to-top'
}

export class LocaleService {
    private localeCache: { [key: string]: Locale } = {}
    constructor(private httpService: HttpService, private experimentsEndpoint: string) {}

    async getLocales(ids: string[]): Promise<Locale[]> {
        const cachedLocales: Locale[] = []
        const nonCachedIds: string[] = []
        for (let i = 0; i < ids.length; i++) {
            const id = ids[i]
            if (this.localeCache[id]) {
                cachedLocales.push(this.localeCache[id])
            } else {
                nonCachedIds.push(id)
            }
        }

        if (nonCachedIds.length > 0) {
            const response = await this.httpService.fetch(
                this.experimentsEndpoint + 'api/v1/cms/locales?ids=' + nonCachedIds.join(','),
            )
            if (!response.ok) {
                throw new Error('Unable to fetch locales')
            }

            const body = await response.json()
            const locales = body.locales

            for (let i = 0; i < locales.length; i++) {
                this.localeCache[locales[i].id] = locales[i]
            }

            return [...cachedLocales, ...locales]
        }
        return cachedLocales
    }
}
