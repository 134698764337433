import { getType } from 'typesafe-actions'

import { Audience } from './schema'
import { RootAction } from 'store'
import { setAudiences } from './actions'

export interface CrmState {
    audience: Audience[]
}

export function createInitialCrmState(): CrmState {
    return {
        audience: [],
    }
}

export function crmReducer(state: Readonly<CrmState>, action: RootAction): CrmState {
    state = state || createInitialCrmState()

    switch (action.type) {
        case getType(setAudiences):
            return {
                ...state,
                audience: action.payload.audience,
            }
        default:
            return state
    }
}
