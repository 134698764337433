import styled from 'styled-typed'

const gutter = 24
const gridSize = 12

export interface ColProps {
    span?: number | 'auto'
    offset?: number
}

export const Col = styled.div<ColProps>`
    ${(props) => {
        if (props.span === 'auto') {
            return 'flex-basis: auto;'
        }

        const value = (100 * (props.span || 1)) / gridSize
        return `
            flex-basis: ${value}%;
            max-width: ${value}%;
        `
    }}

    margin-left: ${(props) => (100 / gridSize) * (props.offset || 0)}%;
    ${(props) => (props.span === 12 ? 'padding: 0 0 !important;' : '')}
`

export interface RowProps {
    gutter?: number
    align?: 'flex-start' | 'center' | 'flex-end' | 'space-between'
    vAlign?: 'flex-start' | 'center' | 'flex-end' | 'space-between'
    bottomSpacing?: number
    wrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
}

export const Row = styled.div<RowProps>`
    display: flex;
    flex-direction: row;
    flex: 0 1 auto;

    ${(props) => (props.wrap ? `flex-wrap: ${props.wrap};` : 'flex-wrap: wrap;')}

    ${(props) => (props.bottomSpacing ? `padding-bottom: ${props.bottomSpacing}px;` : '')}

    & > ${Col} {
        padding: 0 ${(props) => (props.gutter ?? gutter) / 2}px;
    }

    & > ${Col}:first-child {
        padding-left: 0;
    }

    & > ${Col}:last-child {
        padding-right: 0;
    }

    ${(props) => (props.align ? `justify-content: ${props.align};` : '')}
    ${(props) => (props.vAlign ? `align-items: ${props.vAlign};` : '')}
`
