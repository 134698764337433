import { HttpService } from 'http/httpService'
import { LoggingService, EventType, ActionEventData } from 'http/loggingService'

export type DiscountRuleType = 'BUY_X_GET_Y_FREE'

export interface DiscountRuleDetails {
    cmsKey: string
    numberOfItemsFree: number | null
    numberOfItemsPaid: number | null
    productUuidFree: string
    productUuidPaid: string
    type: DiscountRuleType
}

export interface DiscountRule {
    categoryUuid: string
    description?: string
    enabled: boolean
    id: number
    name: string
    productUuid: string
    rulePayload: DiscountRuleDetails
    validFrom?: string | null
    validTo?: string | null
}

export class DiscountRulesService {
    constructor(
        private httpService: HttpService,
        private loggingService: LoggingService,
        private pandoraEndpoint: string,
    ) {}

    async getDiscountRuleList(accountSlug: string): Promise<DiscountRule[]> {
        let response = await this.httpService.fetch(`${this.pandoraEndpoint}api/v1/admin/${accountSlug}/business/rule/`)

        if (!response.ok) {
            throw new Error('Unable to fetch discount rules')
        }
        const body = await response.json()
        return body.result.businessRules.filter((rule: DiscountRule) => rule.rulePayload.type === 'BUY_X_GET_Y_FREE')
    }

    async getDiscountRuleDetails(accountSlug: string, id: string): Promise<DiscountRule> {
        let response = await this.httpService.fetch(
            `${this.pandoraEndpoint}api/v1/admin/${accountSlug}/business/rule/${id}`,
        )

        if (!response.ok) {
            throw new Error('Unable to fetch discount rule details')
        }
        const body = await response.json()
        return body.result.businessRule
    }

    async createDiscountRule(accountSlug: string, payload: DiscountRule): Promise<void> {
        const logEventType: EventType = 'discount_rule_created'
        const logEventData: ActionEventData = {
            category: 'discount_rules',
            payload: [{ ...payload }],
        }

        let response = await this.httpService.fetch(
            `${this.pandoraEndpoint}api/v1/admin/${accountSlug}/business/rule/`,
            {
                method: 'POST',
                body: JSON.stringify({ ...payload }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to create discount rule')
        }
    }

    async updateDiscountRule(accountSlug: string, payload: DiscountRule): Promise<void> {
        const logEventType: EventType = 'discount_rule_updated'
        const logEventData: ActionEventData = {
            category: 'discount_rules',
            payload: [payload],
        }

        let response = await this.httpService.fetch(
            `${this.pandoraEndpoint}api/v1/admin/${accountSlug}/business/rule/${payload.id}`,
            {
                method: 'PATCH',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to update discount rule')
        }
    }

    async updateDiscountRuleName(accountSlug: string, id: number, newName: string): Promise<void> {
        const logEventType: EventType = 'discount_rule_updated'
        const logEventData: ActionEventData = {
            category: 'discount_rules',
            payload: { ruleId: id, newName },
        }

        let response = await this.httpService.fetch(
            `${this.pandoraEndpoint}api/v1/admin/${accountSlug}/business/rule/${id}`,
            {
                method: 'PATCH',
                body: JSON.stringify({ name: newName }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to update discount rule name.')
        }

        this.loggingService.logAction(logEventType, logEventData)
    }

    async updateDiscountRuleStatus(accountSlug: string, id: number, enabled: boolean): Promise<void> {
        const logEventType: EventType = 'discount_rule_updated'
        const logEventData: ActionEventData = {
            category: 'discount_rules',
            payload: { ruleId: id, enabled },
        }

        let response = await this.httpService.fetch(
            `${this.pandoraEndpoint}api/v1/admin/${accountSlug}/business/rule/${id}`,
            {
                method: 'PATCH',
                body: JSON.stringify({ enabled }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to update discount rule status.')
        }

        this.loggingService.logAction(logEventType, logEventData)
    }

    async deleteDiscountRule(accountSlug: string, id: number): Promise<void> {
        const logEventType: EventType = 'discount_rule_deleted'
        const logEventData: ActionEventData = {
            category: 'discount_rules',
            payload: { ruleId: id },
        }

        let response = await this.httpService.fetch(
            `${this.pandoraEndpoint}api/v1/admin/${accountSlug}/business/rule/${id}`,
            {
                method: 'DELETE',
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to delete discount rule.')
        }

        this.loggingService.logAction(logEventType, logEventData)
    }
}
