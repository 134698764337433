import React from 'react'
import styled from 'styled-typed'
import { ValidationMessage } from 'auth/components'
import { FormItem, FormItemName } from 'uiComponents/form/formElements'

export type FieldStatus = 'error' | 'normal' | 'success' | 'highlight'

export type FieldVariant = 'default' | 'inline' | 'inline-reverse'

interface FieldContainerProps {
    variant?: FieldVariant
}

const FieldContainer = styled.div<FieldContainerProps>`
    display: ${(props) => (!!props.variant ? 'flex' : 'inherit')};

    ${(props) =>
        props.variant === 'inline' || props.variant === 'inline-reverse'
            ? `
            & > .label {
                margin: 0;
            }
        `
            : ''}

    ${(props) =>
        props.variant === 'inline'
            ? `
            & > .label {
                padding-right: 1rem;
            }
        `
            : ''}
`

export interface FieldWrapperProps {
    error?: string
    label?: string | React.ReactNode
    id?: string
    status: FieldStatus
    validationMessageProps?: any
    variant?: FieldVariant
    className?: string
    labelProps?: any
}

const FieldWrapper: React.FC<FieldWrapperProps> = ({
    children,
    error,
    label,
    id,
    status,
    validationMessageProps = {},
    variant,
    className,
    labelProps,
}) => {
    const shouldShowError = status === 'error'

    return (
        <FormItem className={className} htmlFor={id}>
            <FieldContainer variant={variant}>
                {variant === 'inline-reverse' ? (
                    <>
                        {children}
                        {!!label && (
                            <FormItemName weight="medium" size={1} className="label" {...labelProps}>
                                {label}
                            </FormItemName>
                        )}
                    </>
                ) : (
                    <>
                        {!!label && (
                            <FormItemName weight="medium" size={1} className="label" {...labelProps}>
                                {label}
                            </FormItemName>
                        )}
                        {children}
                    </>
                )}
            </FieldContainer>
            <ValidationMessage
                {...validationMessageProps}
                className={shouldShowError ? 'validation-message-visible' : ''}
            >
                {shouldShowError && error}
            </ValidationMessage>
        </FormItem>
    )
}

export default FieldWrapper
