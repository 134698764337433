export interface Shades {
    '5': string
    '10': string
    '20': string
    '30': string
    '40': string
    '50': string
    '60': string
    '70': string
    '80': string
    '90': string
}

export interface StatusColors {
    success: string
    error: string
    warn: string
    disabled: string
}

export interface Colors {
    convious: string
    boyBlue: string
    aluminium: string
    textDark: string
    textLight: string
    border: string
    background: string
    tableRow: string
    richBlack: string
    white: string
    sunrise: string
    tiger: string
    sun: string
    emerald: string
    aluminiumShades: Shades
    boyBlueShades: Shades
    emeraldShades: Shades
    chartRotatingColors: string[]
    status: StatusColors
}

export interface Fonts {
    primary: string
    secondary: string
}

export interface BreakPoints {
    md: string
    sm: string
    xs: string
}

export interface DashboardTheme {
    breakPoints: BreakPoints
    fonts: Fonts
    colors: Colors
}

export const defaultTheme: DashboardTheme = {
    breakPoints: {
        md: '900px',
        sm: '768px',
        xs: '0',
    },
    fonts: {
        primary: 'Roboto, sans-serif',
        secondary: 'Roboto Condensed, sans-serif',
    },
    colors: {
        convious: '#FF155B',
        boyBlue: '#5298F4',
        aluminium: '#6A7E92',
        textDark: '#3D4854',
        textLight: '#97A5B4',
        border: '#D1D8DF',
        background: '#F5F7FA',
        tableRow: '#FAFAFB',
        white: '#FFFFFF',
        richBlack: '#0D151E',
        sunrise: '#DE4545',
        tiger: '#FF9145',
        sun: '#FFC800',
        emerald: '#19CA80',
        status: {
            success: '#19CA80',
            error: '#DE4545',
            warn: '#FFC800',
            disabled: '#D1D8DF',
        },
        aluminiumShades: {
            '5': '#EDEFF3',
            '10': '#D1D8DF',
            '20': '#B4BFC9',
            '30': '#97A5B4',
            '40': '#8091A2',
            '50': '#6A7E92',
            '60': '#5D6F81',
            '70': '#4C5B6A',
            '80': '#3D4854',
            '90': '#2B333C',
        },
        boyBlueShades: {
            '5': '#E5F2FE',
            '10': '#C1DFFD',
            '20': '#9BCBFB',
            '30': '#78B7F9',
            '40': '#61A7F7',
            '50': '#5298F4',
            '60': '#4E8AE6',
            '70': '#4878D2',
            '80': '#4366BF',
            '90': '#3948A0',
        },
        emeraldShades: {
            '5': '#E4F8ED',
            '10': '#BDECD2',
            '20': '#91E0B5',
            '30': '#5BD497',
            '40': '#19CA80',
            '50': '#00C069',
            '60': '#00B05E',
            '70': '#009d51',
            '80': '#008C45',
            '90': '#006B2F',
        },
        chartRotatingColors: [
            '#61A7F7',
            '#3CC4CA',
            '#99E1E4',
            '#38E0BE',
            '#42C495',
            '#1CB2B9',
            '#93E7EB',
            '#38AFE0',
            '#36D68B',
            '#01969D',
        ],
    },
}
