import * as React from 'react'
import { Col } from 'uiComponents/flex'
import { MessageKind } from 'uiComponents/messages'
import { ConfigurationUnit } from 'uiComponents/studio/menuItem'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { FormFieldComponent } from 'uiComponents/studio/form/fieldComponents'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { FieldType } from 'uiComponents/studio/form/fieldComponentSchema'
import { OnChangeFunc } from 'engageTools/studio/schema'
import { LanguageValue, Translations } from 'notificationCampaigns/types'

interface Props {
    section: ConfigurationUnit
    languages: LanguageValue[]
    translations: Translations
    activeLanguage: string
    defaultLanguage: string
    handleInputChange: OnChangeFunc
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

type SelectType = 'select'
type TextType = 'text'

export function LanguagesSection(props: Props) {
    const { section, languages, translations, activeLanguage, defaultLanguage, handleInputChange } = props

    const defaultLanguageField = {
        type: 'select' as SelectType,
        name: 'default-language',
        label: 'Default language',
        path: 'defaultLanguage',
        defaultValue: 'en',
        availableOptions: languages,
        infotip: 'The main language used on your website.',
        span: 12,
    }

    const languageField = {
        type: 'select' as SelectType,
        name: 'active-language',
        label: 'Select language',
        path: 'activeLanguage',
        defaultValue: 'en',
        availableOptions: languages,
        span: 12,
    }

    const titleField = {
        name: 'title',
        type: 'text' as TextType,
        label: 'Title',
        span: 12,
        path: 'title',
        defaultValue: '',
        placeholder: 'Title',
        maxLength: 65,
    }

    const bodyField = {
        name: 'body',
        type: 'text' as TextType,
        label: 'Body',
        span: 12,
        path: 'body',
        defaultValue: '',
        placeholder: 'Body',
    }

    const buttonLabelField = {
        name: 'button-label',
        type: 'text' as TextType,
        label: 'Button label',
        span: 12,
        path: 'buttonLabel',
        defaultValue: '',
        placeholder: 'Button label',
    }

    const renderDefaultLangField = (field: FieldType) => (
        <FormFieldComponent field={field} handleChange={handleInputChange} value={defaultLanguage} />
    )

    const renderActiveLangField = (field: FieldType) => (
        <div key="language-field">
            <FormItem>
                <Col span={languageField.span}>
                    <label htmlFor={languageField.name}>{languageField.label}</label>
                </Col>
                <Col span={languageField.span} className="justify-right">
                    <FormFieldComponent field={field} handleChange={handleInputChange} value={activeLanguage} />
                </Col>
            </FormItem>
        </div>
    )

    const renderTitleLangField = (field: FieldType) => {
        const value = translations[activeLanguage]?.title || ''
        return (
            <div key="language-title-field">
                <FormItem>
                    <Col span={titleField.span}>
                        <label htmlFor={titleField.name}>{titleField.label}</label>
                    </Col>
                    <Col span={titleField.span} className="justify-right">
                        <FormFieldComponent
                            field={field}
                            handleChange={handleInputChange}
                            value={value}
                            locale={activeLanguage}
                        />
                    </Col>
                </FormItem>
            </div>
        )
    }

    const renderBodyLangField = (field: FieldType) => {
        const value = translations[activeLanguage]?.body || ''
        return (
            <div key="language-body-field">
                <FormItem>
                    <Col span={bodyField.span}>
                        <label htmlFor={bodyField.name}>{bodyField.label}</label>
                    </Col>
                    <Col span={bodyField.span} className="justify-right">
                        <FormFieldComponent
                            field={field}
                            handleChange={handleInputChange}
                            value={value}
                            locale={activeLanguage}
                        />
                    </Col>
                </FormItem>
            </div>
        )
    }

    const renderButtonLabelLangField = (field: FieldType) => {
        const value = translations[activeLanguage]?.buttonLabel || ''
        return (
            <div key="language-button-label-field">
                <FormItem>
                    <Col span={buttonLabelField.span}>
                        <label htmlFor={buttonLabelField.name}>{buttonLabelField.label}</label>
                    </Col>
                    <Col span={buttonLabelField.span} className="justify-right">
                        <FormFieldComponent
                            field={field}
                            handleChange={handleInputChange}
                            value={value}
                            locale={activeLanguage}
                        />
                    </Col>
                </FormItem>
            </div>
        )
    }

    return (
        <>
            <SectionWrapper key={`${section.name}-1`} title={section.name}>
                <div>
                    <FormItem>
                        <Col span={defaultLanguageField.span}>
                            <label htmlFor={defaultLanguageField.name}>{defaultLanguageField.label}</label>
                        </Col>
                        <Col span={defaultLanguageField.span} className="justify-right">
                            {renderDefaultLangField(defaultLanguageField)}
                        </Col>
                    </FormItem>
                </div>
            </SectionWrapper>
            <SectionWrapper key={`${section.name}-2`} title={section.name}>
                {renderActiveLangField(languageField)}
                {renderTitleLangField(titleField)}
                {renderBodyLangField(bodyField)}
                {renderButtonLabelLangField(buttonLabelField)}
            </SectionWrapper>
        </>
    )
}
