import { BarcodeRedeemErrorCode, SingleBarcodeRedeemErrorCode, Status } from './types'
import { Component } from 'react'
import checkIcon from './icons/colored/check.svg'
import warningIcon from './icons/colored/warning.svg'
import searchOffIcon from './icons/colored/search_off.svg'
import pastValidityIcon from './icons/colored/past_validity.svg'
import qrCodeIcon from './icons/colored/qr_code.svg'
import futureValidityIcon from './icons/colored/future_validity.svg'
import timeLimitReachedIcon from './icons/colored/time_limit_reached.svg'
import whiteSpinner from './icons/white/spinner.svg'
import whiteCheckIcon from './icons/white/check.svg'
import whiteWarningIcon from './icons/white/warning.svg'
import whiteSearchOffIcon from './icons/white/search_off.svg'
import whitePastValidityIcon from './icons/white/past_validity.svg'
import whiteQrCodeIcon from './icons/white/qr_code.svg'
import whiteFutureValidityIcon from './icons/white/future_validity.svg'
import whiteTimeLimitReachedIcon from './icons/white/time_limit_reached.svg'

import { Colors, DashboardTheme } from '../../theme'
import { CreateExceptionBarcodeError } from '../ordersService'

export type ColorName = keyof { [Prop in keyof Colors as Colors[Prop] extends string ? Prop : never]: Colors[Prop] }

type StatusSettings = {
    color: ColorName
    bgColor?: ColorName
    historyIcon?: Component
    statusIcon: Component
    message: string
}

const statusSettingsMap: Record<Status, StatusSettings> = {
    initial: {
        color: 'boyBlue',
        statusIcon: whiteQrCodeIcon,
        message: 'Ready to scan',
    },
    loading: {
        color: 'aluminium',
        statusIcon: whiteSpinner,
        message: 'Waiting for scan data',
    },
    success: {
        color: 'emerald',
        historyIcon: checkIcon,
        statusIcon: whiteCheckIcon,
        message: 'Successfully scanned',
    },
    questionsPending: {
        color: 'aluminium',
        statusIcon: whiteQrCodeIcon,
        message: 'Please choose the number of tickets to redeem',
    },
    notFound: {
        color: 'tiger',
        historyIcon: searchOffIcon,
        statusIcon: whiteSearchOffIcon,
        message: 'Not found',
    },
    error: {
        color: 'sunrise',
        bgColor: 'aluminium',
        historyIcon: warningIcon,
        statusIcon: whiteWarningIcon,
        message: 'Error',
    },
    pastValidity: {
        color: 'sunrise',
        historyIcon: pastValidityIcon,
        statusIcon: whitePastValidityIcon,
        message: 'The ticket has expired',
    },
    futureValidity: {
        color: 'sun',
        historyIcon: futureValidityIcon,
        statusIcon: whiteFutureValidityIcon,
        message: 'Validity in the future',
    },
    alreadyRedeemed: {
        color: 'tiger',
        historyIcon: qrCodeIcon,
        statusIcon: whiteQrCodeIcon,
        message: 'Already redeemed',
    },
    alreadyScannedIn: {
        color: 'tiger',
        historyIcon: qrCodeIcon,
        statusIcon: whiteQrCodeIcon,
        message: 'Already scanned in',
    },
    timeLimitReached: {
        color: 'sunrise',
        historyIcon: timeLimitReachedIcon,
        statusIcon: whiteTimeLimitReachedIcon,
        message: 'Time limit reached',
    },
    notScannableCode: {
        color: 'sunrise',
        historyIcon: warningIcon,
        statusIcon: whiteWarningIcon,
        message: 'Not scannable code',
    },
    scanInRequired: {
        color: 'tiger',
        historyIcon: qrCodeIcon,
        statusIcon: whiteQrCodeIcon,
        message: 'Scan in required',
    },
    barcodeNotGroupScannable: {
        color: 'sunrise',
        historyIcon: warningIcon,
        statusIcon: whiteWarningIcon,
        message: 'Barcode not group scannable',
    },
    noRedeemableBarcodesFound: {
        color: 'tiger',
        historyIcon: searchOffIcon,
        statusIcon: whiteSearchOffIcon,
        message: 'No redeemable barcodes found',
    },
}

export function getStatusSettings(status: Status): StatusSettings {
    return statusSettingsMap[status] || statusSettingsMap.error
}

export const getColorForStatus = (
    status: Status,
    theme: DashboardTheme,
    colorType: 'color' | 'bgColor' = 'color',
): string | null => {
    const colorName = statusSettingsMap[status][colorType]
    if (colorName) {
        return theme.colors[colorName]
    }
    return null
}

const statusMap: Record<BarcodeRedeemErrorCode, Status> = {
    notFound: 'notFound',
    alreadyRedeemed: 'alreadyRedeemed',
    alreadyScannedIn: 'alreadyScannedIn',
    timeLimitReached: 'timeLimitReached',
    futureValidity: 'futureValidity',
    pastValidity: 'pastValidity',
    notScannableCode: 'notScannableCode',
    scanInRequired: 'scanInRequired',

    // group scanning codes
    barcode_not_found: 'notFound',
    no_redeemable_barcodes_found: 'noRedeemableBarcodesFound',
    barcode_not_group_scannable: 'barcodeNotGroupScannable',
}

export function getStatusByErrorCode(errorCode: BarcodeRedeemErrorCode): Status {
    return statusMap[errorCode] ?? 'error'
}

export const getErrorKey = (response: CreateExceptionBarcodeError) =>
    Object.keys(response).find(
        (key) => response[key] && !['type', 'barcodes', 'error'].includes(key),
    ) as SingleBarcodeRedeemErrorCode
