import { RootAction } from 'store'
import { getType } from 'typesafe-actions'
import { createInitialPopupsState, PopupsState } from './state'
import { popoverDismissed } from 'uiComponents/popups/actions'

export function popupsReducer(state: Readonly<PopupsState>, action: RootAction): PopupsState {
    state = state || createInitialPopupsState()
    switch (action.type) {
        case getType(popoverDismissed):
            return {
                ...state,
                [action.payload.popoverId]: 'dismissed',
            }
        default:
            return state
    }
}
