import { FeatureToggle } from './featureService'

type ReadOnlyToggles = ReadonlyArray<Readonly<FeatureToggle>>

export interface FeatureState {
    readonly defaults: ReadOnlyToggles
    readonly forAccount: { [key: string]: ReadOnlyToggles }
}

export function createInitialFeatureState(): FeatureState {
    return {
        defaults: [],
        forAccount: {},
    }
}
