import * as React from 'react'
import { OnClickMenu, OnClickMenuItem } from 'uiComponents/menus/'
import { Text } from 'uiComponents/typography'
import { faTrash, faPencilAlt, faPause, faPlay } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Cell } from 'uiComponents/table'
import { CAMPAIGN_STATE } from 'notificationCampaigns/types'

type FunctionType = () => void

interface MenuProps {
    state: CAMPAIGN_STATE
    onEditInfo: FunctionType
    onDelete: FunctionType
    onActive: FunctionType
    onInactive: FunctionType
}

export function ActionMenu(props: MenuProps) {
    return (
        <Cell className="overflow-allowed" align="right">
            <OnClickMenu title="Actions" kind="action" width="12em" secondary alignRight>
                <OnClickMenuItem onClick={props.onEditInfo}>
                    <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: '0.5em' }} />
                    Edit info
                </OnClickMenuItem>
                {props.state === CAMPAIGN_STATE.ACTIVE && (
                    <OnClickMenuItem onClick={props.onInactive}>
                        <Text>
                            <FontAwesomeIcon icon={faPause} style={{ marginRight: '0.5em' }} />
                            Pause
                        </Text>
                    </OnClickMenuItem>
                )}
                {props.state === CAMPAIGN_STATE.DRAFT && (
                    <OnClickMenuItem onClick={props.onActive}>
                        <Text>
                            <FontAwesomeIcon icon={faPlay} style={{ marginRight: '0.5em' }} />
                            Activate
                        </Text>
                    </OnClickMenuItem>
                )}
                <OnClickMenuItem onClick={props.onDelete}>
                    <Text status="error">
                        <FontAwesomeIcon icon={faTrash} style={{ marginRight: '0.5em' }} />
                        Delete
                    </Text>
                </OnClickMenuItem>
            </OnClickMenu>
        </Cell>
    )
}
