import throttle from 'lodash/throttle'
import { Store } from 'redux'
import { createReduxStore, StoreServices } from './store'
import { applySavedState } from './state'
import { State } from './interface'

function loadState(): Partial<State> | undefined {
    try {
        const serializedState = localStorage.getItem('state')
        if (serializedState === null) {
            return undefined
        }

        return JSON.parse(serializedState)
    } catch (_) {
        return undefined
    }
}

function saveState(state: Partial<State>) {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    } catch (_) {}
}

export function createPersistedStore(services: StoreServices, persistedState?: State): Store<State> {
    const savedState = loadState()
    const state = persistedState ?? applySavedState(savedState)
    const store = createReduxStore(state, services)
    store.subscribe(
        throttle(() =>
            saveState({
                auth: store.getState().auth,
                preferences: store.getState().preferences,
                profile: store.getState().profile,
                popups: store.getState().popups,
                orderTable: store.getState().orderTable,
            }),
        ),
    )
    return store
}
