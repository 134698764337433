import { createListenerMiddleware } from '@reduxjs/toolkit'
import { chunk, isEqual } from 'lodash'

import {
    getPendingVisibleDiscountUploads,
    getVisiblePoolIds,
    getVisibleUploads,
    setVisibleData,
    UploadsQueueState,
} from './redux'
import { endpoints } from './reduxQuery'

export const discountUploadMiddleware = createListenerMiddleware()

const MAX_ACTIVE_REQUEST = 3

const fetchPoolData = async (pendingUploads: UploadsQueueState[], state: any, listenerApi: any) => {
    const chunks = chunk(pendingUploads, MAX_ACTIVE_REQUEST)
    const accountSlug = state.preferences.activeAccount

    for await (const chunkGroup of chunks) {
        await Promise.all(
            chunkGroup.map((upload) => {
                if (upload.type === 'upload') {
                    return listenerApi.dispatch(
                        endpoints.getDiscountUploadInfo.initiate({
                            accountSlug,
                            id: upload.id,
                        }),
                    )
                }

                return listenerApi.dispatch(
                    endpoints.getDiscountCodeDetails.initiate({
                        accountSlug,
                        codeUuid: upload.id,
                        poolUuid: upload.poolId as string,
                    }),
                )
            }),
        )
    }
}

discountUploadMiddleware.startListening({
    predicate: (action, newState: any, oldState: any) =>
        action.type === setVisibleData.type && !isEqual(getVisibleUploads(newState), getVisibleUploads(oldState)),
    effect: async (action, listenerApi) => {
        listenerApi.cancelActiveListeners()
        const state = listenerApi.getState() as any
        const visiblePools = getVisiblePoolIds(state)

        for (const poolId of visiblePools) {
            const visibleData = getPendingVisibleDiscountUploads(poolId)(state)

            fetchPoolData(visibleData, state, listenerApi)
        }
    },
})
