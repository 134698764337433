import { RootAction } from 'store'
import { getType } from 'typesafe-actions'
import { createInitialMessagesState, MessagesState } from './state'
import { addMessage, removeMessage, removeAllMessages, addInlineMessage, removeAllTagMessages } from './actions'
import { Message } from 'uiComponents/messages'

export function messagesReducer(state: Readonly<MessagesState>, action: RootAction): MessagesState {
    state = state || createInitialMessagesState()

    switch (action.type) {
        case getType(addMessage):
            const newMessage: Message = {
                id: action.payload.id,
                status: action.payload.status,
                text: action.payload.text,
                visible: true,
            }
            return {
                messages: [...state.messages, newMessage],
            }
        case getType(removeMessage):
            const updatedMessages = state.messages.filter((m) => m.id !== action.payload.id)
            return {
                messages: [...updatedMessages],
            }
        case getType(removeAllMessages):
            return {
                messages: [],
            }
        case getType(addInlineMessage):
            const msg = action.payload.payload as unknown as Message
            return {
                messages: [...state.messages, msg],
            }
        case getType(removeAllTagMessages):
            const tag = action.payload.tag
            return {
                messages: [...state.messages].filter((_msg) => _msg.tag !== tag),
            }
        default:
            return state
    }
}
