import { Store } from 'redux'
import { invalidatePLCache } from '../products/reduxApi'

export class StoreService {
    constructor(private store: Store) {}

    invalidatePLCache() {
        const accountSlug = this.store.getState()?.preferences?.activeAccount

        invalidatePLCache(this.store.dispatch, accountSlug)
    }
}

export type IStoreService = StoreService
