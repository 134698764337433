import queries from './queries'
import { PlacementListItem, PlacementPayload, PlacementResponse, UpdatePlacementWeightPayload } from './schema'
import { sortBy } from 'lodash'
import { HttpService } from 'http/httpService'

export class TapPlacementService {
    constructor(private httpService: HttpService, private endpoint: string) {}

    async fetchAll(slug: string): Promise<PlacementListItem[]> {
        const query = queries.getPlacements

        const body = JSON.stringify({
            operationName: 'GetPlacements',
            query,
            variables: {
                slug: slug,
            },
        })

        const payload = await this.handleFetch(body)
        const placements = sortBy(payload.data.getPlacements, (placement) => placement.weight)

        return placements as PlacementListItem[]
    }

    async fetch(id: string, slug: string): Promise<PlacementResponse> {
        const query = queries.getPlacement

        const body = JSON.stringify({
            operationName: 'GetPlacement',
            query,
            variables: {
                id: id,
                slug: slug,
            },
        })

        const payload = await this.handleFetch(body)
        return payload.data.getPlacement as PlacementResponse
    }

    async delete(id: string, accountSlug: string): Promise<boolean> {
        const query = queries.deletePlacement

        const body = JSON.stringify({
            operationName: 'DeletePlacement',
            query,
            variables: {
                slug: accountSlug,
                id: id,
            },
        })

        const payload = await this.handleFetch(body)
        return payload
    }

    async updatePlacementsWeights(
        slug: string,
        placements: UpdatePlacementWeightPayload[],
    ): Promise<PlacementListItem[]> {
        const query = queries.updatePlacementsWeight

        const body = JSON.stringify({
            operationName: 'UpdatePlacementsWeight',
            query,
            variables: {
                slug: slug,
                placements: placements,
            },
        })

        const payload = await this.handleFetch(body)
        return payload.data.updatePromotionsWeight as PlacementListItem[]
    }

    async add(placement: PlacementPayload): Promise<boolean> {
        const query = queries.addPlacement

        const body = JSON.stringify({
            operationName: 'AddPlacement',
            query,
            variables: {
                placement,
            },
        })

        const payload = await this.handleFetch(body)
        return payload
    }

    async update(placement: PlacementPayload): Promise<boolean> {
        const query = queries.updatePlacement

        const body = JSON.stringify({
            operationName: 'UpdatePlacement',
            query,
            variables: {
                placement,
            },
        })

        const payload = await this.handleFetch(body)
        return payload
    }

    private async handleFetch(body: BodyInit): Promise<any> {
        const response = await this.httpService.fetch(`${this.endpoint}`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Request failed with status code: ${response.status}`)
        }

        const payload = await response.json()
        return payload
    }
}
