import React from 'react'
import { RegularText, Bold, SecondaryText } from 'uiComponents/typography'
import { PushNotificationContainer, PushNotificationHeader } from './ui'

interface Props {
    title?: string
    message?: string
}

export const PushNotification = ({ title, message }: Props) => {
    const renderHeader = () => (
        <PushNotificationHeader>
            <SecondaryText>TAP</SecondaryText>
            <SecondaryText>now</SecondaryText>
        </PushNotificationHeader>
    )

    return (
        <PushNotificationContainer>
            {renderHeader()}
            <Bold>{title}</Bold>
            <RegularText>{message}</RegularText>
        </PushNotificationContainer>
    )
}

export default PushNotification
