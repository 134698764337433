import { setAudiences } from 'crm/actions'
import { AudienceAvailableResponse } from 'crm/schema'
import { crmSelectors } from 'crm/selectors'
import { find } from 'lodash'
import { AppServices } from 'middleware'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFetch } from 'utils/useFetch'
import { useHasFeature } from '../../../features'
import { audiencesList } from '../consts'

export const useFetchAudiences = () => {
    const data = useSelector(crmSelectors.getAudiences)
    const dispatch = useDispatch()

    const shouldShowNewData = useHasFeature('CRMAudiencesPage')
    const { fetch: fetchAudiences, loading } = useFetch<AudienceAvailableResponse>({
        api: AppServices.crmService.getAudiences,
    })

    const loadData = useCallback(async () => {
        if (shouldShowNewData) {
            const response = await fetchAudiences()
            dispatch(setAudiences(response.available))
        } else {
            dispatch(setAudiences(audiencesList))
        }
    }, [shouldShowNewData, fetchAudiences])

    const nameFromSlug = (slug?: string) => {
        const audienceName = find(data, { slug: slug })
        return audienceName?.name || slug || ''
    }

    return { loading, fetch: loadData, nameFromSlug, data }
}
