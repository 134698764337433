import React, { useMemo, FunctionComponent } from 'react'
import TreeLeaf from './treeLeaf'
import { createDataTree, FieldValue, TreeLeafComponentProps } from './utils'

interface TreeProps {
    data: any[]
    idField: FieldValue
    parentField: FieldValue
    component: FunctionComponent<TreeLeafComponentProps>
}

const Tree: React.FC<TreeProps> = ({ data, idField, parentField, component, ...props }) => {
    const treeData = useMemo(() => createDataTree(data, idField, parentField), [data, idField, parentField])

    return <TreeLeaf depth={0} idField={idField} component={component} data={treeData} />
}

export default Tree
