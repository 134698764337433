import { createAction } from 'typesafe-actions'
import { Audience } from './schema'

export const setAudiences = createAction(
    'setAudience',
    (action) => (data: Audience[]) =>
        action({
            type: 'setAudience',
            audience: data,
        }),
)
