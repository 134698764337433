import { HttpService } from 'http/httpService'
import { LoggingService, EventType, ActionEventData } from 'http/loggingService'

interface ProductDetails {
    internalId: string
    uuid: string
    name: string
}

export interface Translation {
    text: string
    language: string
}

export interface VenueDetails {
    locationId: string
    locationName: string
    googlePlaceId: string
    products: ProductDetails[]
}

export interface RawProductData {
    accountSlug: string
    internalId: string
    uuid: string
    title: Translation[]
    description: Translation[]
    location: {
        id: string
        name: string
        googlePlaceId: string
        address: string
        latitude: string
        longitude: string
    }
}

interface ProductOption {
    articleUUID: string
    category: string[]
}

export interface GoogleListingItem {
    id: string
    slug: string
    locationId: string
    googlePlaceId: string
    includeInFeed: boolean
    title: string
    image: string
    description: Translation[]
    options: ProductOption[]
}

export class GoogleTTDService {
    constructor(
        private httpService: HttpService,
        private loggingService: LoggingService,
        private backofficeEndpoint: string,
        private aionEndpoint: string,
    ) {}

    getProducts = async (accountSlug: string): Promise<RawProductData[]> => {
        const response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/tickets/articles?account=${accountSlug}&enabled=true&archived=false&things_to_do=true&include=location`,
            {
                method: 'GET',
            },
        )
        if (!response.ok) {
            throw new Error('Unable to get account details')
        }
        return await response.json()
    }

    getGoogleTTDItems = async (accountSlug: string): Promise<GoogleListingItem[]> => {
        const response = await this.httpService.fetch(`${this.aionEndpoint}ttd/products?slug=${accountSlug}`, {
            method: 'GET',
        })
        if (!response.ok) {
            throw new Error('Unable to get Google TTD items')
        }
        return await response.json()
    }

    createGoogleTTDItem = async (accountSlug: string, payload: GoogleListingItem): Promise<GoogleListingItem> => {
        const logEventType: EventType = 'google_ttd_item_created'
        const logEventData: ActionEventData = {
            category: 'google_ttd',
            payload: { payload },
        }

        let response = await this.httpService.fetch(`${this.aionEndpoint}ttd/product?slug=${accountSlug}`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to create a Google TTD item')
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    updateGoogleTTDItem = async (accountSlug: string, id: string, payload: GoogleListingItem): Promise<void> => {
        const logEventType: EventType = 'google_ttd_item_updated'
        const logEventData: ActionEventData = {
            category: 'google_ttd',
            payload: { googleTTDItemId: id, payload },
        }

        let response = await this.httpService.fetch(
            `${this.aionEndpoint}ttd/product?slug=${accountSlug}&id=${encodeURIComponent(id)}`,
            {
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to update the Google TTD item')
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async deleteGoogleTTDItem(accountSlug: string, id: string): Promise<void> {
        const logEventType: EventType = 'google_ttd_item_deleted'
        const logEventData: ActionEventData = {
            category: 'google_ttd',
            payload: { googleTTDItemId: id },
        }

        let response = await this.httpService.fetch(
            `${this.aionEndpoint}ttd/product?slug=${accountSlug}&id=${encodeURIComponent(id)}`,
            {
                method: 'DELETE',
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to delete Google TTD item')
        }
        this.loggingService.logAction(logEventType, logEventData)
    }
}
