interface ReCaptcha {
    action: string
    challenge_ts: Date
    hostname: string
    score: number
    success: boolean
}

export class ReCaptchaService {
    constructor(private dashboardApiEndpoint: string) {}
    async verifyReCaptchaToken(token: string): Promise<ReCaptcha> {
        const response = await fetch(`${this.dashboardApiEndpoint}oauth/recaptcha/`, {
            method: 'POST',
            body: JSON.stringify({ token }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return await response.json()
    }
}
