import { HttpService } from 'http/httpService'
import { LoggingService } from 'http/loggingService'
import {
    AdyenRedirectResponse,
    AdyenErrorResponse,
    PaypalStatusResponse,
    AdyenResponse,
    AdyenAccountHolderInfo,
} from './schema'

export class IntegrationsService {
    constructor(private httpService: HttpService, private loggingService: LoggingService, private endpoint: string) {}

    async getMollieStatus({ accountSlug, requestId }: { accountSlug: string; requestId: string }) {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/integration_management/mollie_connect/${accountSlug}/status/?state=${requestId}`,
            {
                headers: {
                    'Content-type': 'application/json',
                },
            },
        )

        return this.loggingService.handleResponse({
            response,
            logEventData: {
                category: 'integrations',
                payload: { accountSlug, requestId },
            },
            logEventType: 'get_mollie_status',
        })
    }

    async sendMollieAuth({
        accountSlug,
        requestId,
        authorizationResponse,
    }: {
        accountSlug: string
        requestId: string
        authorizationResponse: string
    }) {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/integration_management/mollie_connect/${accountSlug}/authorize/`,
            {
                headers: {
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    state: requestId,
                    authorizationResponse,
                }),
            },
        )

        return this.loggingService.handleResponse({
            response,
            logEventData: {
                category: 'integrations',
                payload: { accountSlug, requestId, authorizationResponse },
            },
            logEventType: 'send_mollie_auth',
        })
    }

    async deleteMollieAuth({ accountSlug }: { accountSlug: string }) {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/integration_management/mollie_connect/${accountSlug}/authorize/`,
            {
                method: 'DELETE',
            },
        )

        return this.loggingService.handleResponse({
            response,
            logEventData: {
                category: 'integrations',
                payload: { accountSlug },
            },
            logEventType: 'delete_mollie_auth',
        })
    }

    async initPaypalAuthorization({ accountSlug, email }: { accountSlug: string; email: string }) {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/integration_management/paypal_marketplace/${accountSlug}/authorize/init/`,
            {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                }),
            },
        )

        return this.loggingService.handleResponse({
            response,
            logEventData: {
                category: 'integrations',
                payload: { accountSlug },
            },
            logEventType: 'paypal_init_auth',
        })
    }

    async checkPaypalStatus({ accountSlug }: { accountSlug: string }) {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/integration_management/paypal_marketplace/${accountSlug}/status/`,
        )

        return this.loggingService.handleResponse<PaypalStatusResponse>({
            response,
            logEventData: {
                category: 'integrations',
                payload: { accountSlug },
            },
            logEventType: 'paypal_get_status',
        })
    }

    async postbackPaypal() {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/integration_management/paypal_marketplace/authorize/postback/`,
            {
                method: 'POST',
            },
        )

        return this.loggingService.handleResponse<PaypalStatusResponse>({
            response,
            logEventData: {
                category: 'integrations',
                payload: {},
            },
            logEventType: 'paypal_postback',
        })
    }

    async deletePaypalAuth({ accountSlug }: { accountSlug: string }) {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/integration_management/paypal_marketplace/${accountSlug}/authorize/init/`,
            {
                method: 'DELETE',
            },
        )

        return this.loggingService.handleResponse({
            response,
            logEventData: {
                category: 'integrations',
                payload: { accountSlug },
            },
            logEventType: 'delete_paypal_auth',
        })
    }

    async getAdyenAccountInformation({ accountSlug }: { accountSlug: string }): Promise<AdyenResponse> {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/integration_management/adyen/${accountSlug}/abp_onboarding/`,
        )

        return this.loggingService.handleResponse({
            response,
            logEventData: {
                category: 'integrations',
                payload: { accountSlug },
            },
            logEventType: 'get_adyen_account_holder',
        })
    }

    async getAdyenClassicAccountInformation({ accountSlug }: { accountSlug: string }) {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/integration_management/adyen/${accountSlug}/account_holder/`,
        )

        return this.loggingService.handleResponse({
            response,
            logEventData: {
                category: 'integrations',
                payload: { accountSlug },
            },
            logEventType: 'get_adyen_account_holder',
        })
    }

    async createAdyenAccountInformation({ accountSlug, form }: { accountSlug: string; form: any }) {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/integration_management/adyen/${accountSlug}/account_holder/`,
            {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(form),
            },
        )

        return this.loggingService.handleResponse<AdyenAccountHolderInfo | AdyenErrorResponse>({
            response,
            logEventData: {
                category: 'integrations',
                payload: { accountSlug },
            },
            logEventType: 'get_adyen_account_holder',
        })
    }

    async getAdyenOnboardingLink({ accountSlug }: { accountSlug: string }) {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/integration_management/adyen/${accountSlug}/onboarding/`,
        )

        return this.loggingService.handleResponse<AdyenRedirectResponse>({
            response,
            logEventData: {
                category: 'integrations',
                payload: { accountSlug },
            },
            logEventType: 'get_adyen_account_holder',
        })
    }

    async getVoucherifyStatus({ accountSlug }: { accountSlug: string }) {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/integration_management/voucherify/${accountSlug}/status/`,
        )

        return this.loggingService.handleResponse<any>({
            response,
            logEventData: {
                category: 'integrations',
                payload: { accountSlug },
            },
            logEventType: 'voucherify_get_status',
        })
    }

    async connectVoucherify({ accountSlug, appId, apiKey }: { accountSlug: string; appId: string; apiKey: string }) {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/integration_management/voucherify/${accountSlug}/connect/`,
            {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify({ appId, apiKey }),
            },
        )

        return this.loggingService.handleResponse<any>({
            response,
            logEventData: {
                category: 'integrations',
                payload: { accountSlug },
            },
            logEventType: 'voucherify_connect',
        })
    }

    async disconnectVoucherify({ accountSlug }: { accountSlug: string }) {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/integration_management/voucherify/${accountSlug}/connect/`,
            {
                method: 'DELETE',
            },
        )

        return this.loggingService.handleResponse({
            response,
            logEventData: {
                category: 'integrations',
                payload: { accountSlug },
            },
            logEventType: 'voucherify_disconnect',
        })
    }
}
