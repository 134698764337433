import { Message } from 'uiComponents/messages'

export interface MessagesState {
    messages: Message[]
}

export function createInitialMessagesState(): MessagesState {
    return {
        messages: [],
    }
}
