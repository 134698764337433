import { createAction } from 'typesafe-actions'
import { FeatureToggle } from 'features'

export const defaultFeaturesLoaded = createAction(
    'DefaultFeaturesLoaded',
    (action) => (features: FeatureToggle[]) =>
        action({
            type: 'DefaultFeaturesLoaded',
            features,
        }),
)

export const accountFeaturesLoaded = createAction(
    'AccountFeaturesLoaded',
    (action) => (accountSlug: string, features: FeatureToggle[]) =>
        action({
            type: 'AccountFeaturesLoaded',
            accountSlug,
            features,
        }),
)
