import { HttpService } from 'http/httpService'
import { LoggingService, EventType, ActionEventData } from 'http/loggingService'
import { Parkmap, Category, POI, CategoryPositionUpdatePayload } from './models'

export class ParkMapService {
    constructor(
        private httpService: HttpService,
        private loggingService: LoggingService,
        private backtapEndpoint: string,
    ) {}

    async getParkMaps(slug: string): Promise<Parkmap[]> {
        const logEventType: EventType = 'get_park_map_list'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload: { slug },
        }

        let response = await this.httpService.fetch(`${this.backtapEndpoint}v1/dashboard/parkmaps?slug=${slug}`)

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async getParkMap(id: string): Promise<Parkmap> {
        const logEventType: EventType = 'get_park_map'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload: { id },
        }

        let response = await this.httpService.fetch(`${this.backtapEndpoint}v1/dashboard/parkmap?id=${id}`)

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async createParkMap(parkMap: Parkmap): Promise<Parkmap> {
        const logEventType: EventType = 'create_park_map'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload: { parkMap },
        }

        let response = await this.httpService.fetch(`${this.backtapEndpoint}v1/dashboard/parkmap`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(parkMap),
        })

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async updateParkMap(parkMap: Parkmap): Promise<Parkmap> {
        const logEventType: EventType = 'update_park_map'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload: { parkMap },
        }

        let response = await this.httpService.fetch(`${this.backtapEndpoint}v1/dashboard/parkmap`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(parkMap),
        })

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async deleteParkMap(parkMapId: string): Promise<void> {
        const logEventType: EventType = 'delete_park_map'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload: { parkMapId },
        }

        let response = await this.httpService.fetch(`${this.backtapEndpoint}v1/dashboard/parkmap?id=${parkMapId}`, {
            method: 'DELETE',
        })

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async getParkMapCategories(locationId: string): Promise<Category[]> {
        const logEventType: EventType = 'get_park_map_categories'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload: { locationId },
        }

        let response = await this.httpService.fetch(
            `${this.backtapEndpoint}v1/dashboard/categories?locationId=${locationId}`,
        )

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async getCategory(category_id: string): Promise<Category> {
        const logEventType: EventType = 'get_park_map_category'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload: { category_id },
        }

        let response = await this.httpService.fetch(`${this.backtapEndpoint}v1/dashboard/category?id=${category_id}`)

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async createCategory(category: Category): Promise<Category> {
        const logEventType: EventType = 'create_park_map_category'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload: { category },
        }

        let response = await this.httpService.fetch(`${this.backtapEndpoint}v1/dashboard/category`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(category),
        })

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async updateCategory(category: Category): Promise<Category> {
        const logEventType: EventType = 'update_park_map_category'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload: { category },
        }

        let response = await this.httpService.fetch(`${this.backtapEndpoint}v1/dashboard/category`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(category),
        })

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async updateCategoriesPosition(
        payload: CategoryPositionUpdatePayload[],
        slug: string,
        locationId: string,
    ): Promise<void> {
        const logEventType: EventType = 'update_park_map_categories_positions'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload,
        }

        let response = await this.httpService.fetch(
            `${this.backtapEndpoint}v1/dashboard/category/positions?slug=${slug}&locationId=${locationId}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({ payload }),
            },
        )

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async deleteCategory(categoryId: string): Promise<void> {
        const logEventType: EventType = 'delete_category'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload: { categoryId },
        }

        let response = await this.httpService.fetch(`${this.backtapEndpoint}v1/dashboard/category?id=${categoryId}`, {
            method: 'DELETE',
        })

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async getParkMapPois(locationId: string, categoryId: string): Promise<POI[]> {
        const logEventType: EventType = 'get_park_map_pois'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload: { locationId, categoryId },
        }

        let response = await this.httpService.fetch(
            `${this.backtapEndpoint}v1/dashboard/pois?locationId=${locationId}&categoryId=${categoryId}`,
        )

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async getPoi(poiId: string): Promise<POI> {
        const logEventType: EventType = 'get_park_map_poi'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload: { poiId },
        }

        let response = await this.httpService.fetch(`${this.backtapEndpoint}v1/dashboard/poi?id=${poiId}`)

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async createPoi(poi: POI): Promise<POI> {
        const logEventType: EventType = 'create_park_map_poi'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload: { poi },
        }

        let response = await this.httpService.fetch(`${this.backtapEndpoint}v1/dashboard/poi`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(poi),
        })

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async updatePoi(poi: POI): Promise<POI> {
        const logEventType: EventType = 'update_park_map_poi'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload: { poi },
        }

        let response = await this.httpService.fetch(`${this.backtapEndpoint}v1/dashboard/poi`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(poi),
        })

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }

    async deletePoi(poiId: string): Promise<void> {
        const logEventType: EventType = 'delete_poi'
        const logEventData: ActionEventData = {
            category: 'tap_park_map',
            payload: { poiId },
        }

        let response = await this.httpService.fetch(`${this.backtapEndpoint}v1/dashboard/poi?id=${poiId}`, {
            method: 'DELETE',
        })

        return await this.loggingService.handleResponse({ response, logEventType, logEventData })
    }
}
