import { AuthenticatedHttpService } from 'http/httpService'

export type CheckoutFlow = 'dated' | 'undated' | 'rtp'

export interface ProductsListCreateRequest {
    parentId: string | null
    title: {
        key: string
        text: {
            [lang: string]: string
        }
    }
    flow: CheckoutFlow
    priority: number
    enabled: boolean
}

export interface ProductsList {
    id: string
}

export class ProductsListService {
    constructor(private httpService: AuthenticatedHttpService, private backofficeEndpoint: string) {}

    async createProductsList(accountSlug: string, data: ProductsListCreateRequest): Promise<ProductsList> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/products_lists/`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            throw new Error('Unable to create products list')
        }

        response = await this.httpService.fetch(response.headers.get('Location')!)

        if (!response.ok) {
            throw new Error('Unable to retrieve products list')
        }
        return await response.json()
    }
}
