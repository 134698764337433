import React from 'react'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome'
import styled from 'styled-typed'
import ActionButton from '../buttons'

const Icon = styled(FontAwesomeIcon)`
    font-size: 0.85rem;
    padding-bottom: 0.05rem;
`

const SelectedCountBox = styled.div`
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.textLight};
    width: 2em;
    height: 2em;
    border-radius: 50%;
    margin-left: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
`

interface MenuToggleProps {
    menuName: string | React.ReactNode
    selectedCount?: number
    loading: boolean
    expanded: boolean
    toggleOptionsContainer: () => void
    icon?: IconProp
    open?: boolean
    arrowHidden?: boolean
}

export function MenuToggleComponent(props: MenuToggleProps) {
    return (
        <ActionButton
            onClick={props.toggleOptionsContainer}
            className={props.loading ? 'loading' : props.expanded ? 'expanded' : ''}
            size="medium"
            secondary
            kind="settings"
        >
            {!!props.icon && <Icon style={{ marginRight: '0.5rem' }} icon={props.icon} className="visible" />}
            {props.menuName}
            {!!props.selectedCount && <SelectedCountBox>{props.selectedCount}</SelectedCountBox>}
            {!props.arrowHidden &&
                (props.expanded ? (
                    <Icon style={{ marginLeft: '0.5rem' }} icon={faCaretUp} />
                ) : (
                    <Icon style={{ marginLeft: '0.5rem' }} icon={faCaretDown} />
                ))}
        </ActionButton>
    )
}
