import { RootAction } from 'store'
import { getType } from 'typesafe-actions'
import { createInitialAuthState, AuthState } from './state'
import { userLoggedIn, userDataRetrieved, userLoggedOut, impersonationStart, impersonationEnd } from './actions'

export function authReducer(state: Readonly<AuthState>, action: RootAction): AuthState {
    state = state || createInitialAuthState()

    switch (action.type) {
        case getType(userLoggedIn):
            return {
                ...state,
                ticket: action.payload.ticket,
            }
        case getType(userDataRetrieved):
            return {
                ...state,
                user: action.payload.user,
            }
        case getType(userLoggedOut):
            return {
                ...state,
                impersonatorTicket: null,
                ticket: null,
                user: null,
            }
        case getType(impersonationStart):
            return {
                ...state,
                impersonatorTicket: action.payload.ticket,
            }
        case getType(impersonationEnd):
            return {
                ...state,
                impersonatorTicket: null,
            }
        default:
            return state
    }
}
