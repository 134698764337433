import React, { createElement } from 'react'
import { FieldValue, getField } from './utils'

interface TreeProps {
    component: React.FunctionComponent<any>
    idField: FieldValue
    data: any[]
    depth: number
}

const TreeLeaf: React.FC<TreeProps> = ({ data, component, idField, depth }) => {
    const childDepth = depth + 1

    return (
        <>
            {data.map((leaf) => {
                const key = getField(idField, leaf)

                const childrenComponent = !!leaf.childNodes?.length && (
                    <TreeLeaf depth={childDepth} data={leaf.childNodes} component={component} idField={idField} />
                )

                return createElement(component, { key, data: leaf, depth: childDepth }, childrenComponent)
            })}
        </>
    )
}

export default TreeLeaf
