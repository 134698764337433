export type NotificationCampaignListItem = {
    id: string
    name: string
    title: string
    message: string
    state: CAMPAIGN_STATE
    endDate: Date
    scheduleType: SCHEDULE_TYPE
    scheduleDescription: string
}

export enum CAMPAIGN_STATE {
    ACTIVE = 'active',
    DRAFT = 'draft',
}

export enum SCHEDULE_TYPE {
    ONE_TIME = 'one_time',
    RECURRING = 'recurring',
}

export type NotificationCampaignListResponse = {
    caid: string
    name: string
    status: 'Active' | 'Draft'
    changedDate: string
    createdDate: string
    schedule: Schedule
    content: NotificationCampaignListContentResponse[]
    target: Target
}

export interface Schedule {
    scheduleType: string
    end: string
    date: string
    description: string
    start?: string
}

export interface NotificationCampaignListContentResponse {
    title: string
    body: string
    buttonLabel: string
    locale: string
    isDefault: boolean
}

export interface Target {
    users: number
    channels: string
    targetType?: string
}

export interface NotificationCampaignsService {
    fetchAll(slug: string): Promise<NotificationCampaignListItem[]>
    addCampaign(data: AddCampaignPayload, accountSlug: string): Promise<boolean>
    updateCampaign(data: AddCampaignPayload, accountSlug: string, caid: string): Promise<boolean>
    setCampaignState(data: any, accountSlug: string): Promise<boolean>
    deleteCampaign(slug: string, caid: string): Promise<boolean>
    fetchCampaign(slug: string, caid: string): Promise<any>
}

export type TargetAudience = {
    audience: string
}

export type NotificationCampaignConfiguration = {
    status?: string
    defaultLanguage?: string
    campaign?: {
        name: string
    }
    notification?: {
        type?: string
        url?: string
    }
    target?: {
        audience?: string[]
        marketingConsent?: boolean
        channel?: string
    }
    schedule?: NotificationCampaignScheduleConfiguration
    orderFilter?: {
        amountOfDays?: number
        direction?: string
    }
}

export type NotificationCampaignScheduleConfiguration = {
    scheduleType?: string
    date?: number
    time?: string
    startDate?: number
    startTime?: string
    endDate?: number
    endTime?: string
    whenUnit?: string
    interval?: number
    scheduleWhenFriday: boolean
    scheduleWhenMonday: boolean
    scheduleWhenSaturday: boolean
    scheduleWhenSunday: boolean
    scheduleWhenThursday: boolean
    scheduleWhenTuesday: boolean
    scheduleWhenWednesday: boolean
}

export type LanguageValue = {
    value: string
    name: string
}

export type Translations = {
    [key: string]: {
        title?: string
        body?: string
        buttonLabel?: string
    }
}

export type AddCampaignPayload = {
    name?: string
    notificationType?: string
    url?: string
    channels?: string
    content: AddCampaignContentPayload[]
    schedule?: AddCampaignSchedulePayload
    status?: string
}

export type AddCampaignContentPayload = {
    title?: string
    body?: string
    buttonLabel?: string
    locale?: string
    isDefault: boolean
}

export type AddCampaignOrderFilterPayload = {
    direction?: string
    amountOfDays?: number
}

export interface AddCampaignSchedulePayload {
    scheduleType?: string
    when?: AddCampaignRecurringWhenSchedulePayload
}

export interface AddCampaignOneTimeSchedulePayload extends AddCampaignSchedulePayload {
    date?: string
}

export interface AddCampaignRecurringSchedulePayload extends AddCampaignSchedulePayload {
    start?: string
    end?: string
}

export type AddCampaignRecurringWhenSchedulePayload = {
    unit?: string
    interval: number
    monday: boolean
    tuesday: boolean
    wednesday: boolean
    thursday: boolean
    friday: boolean
    saturday: boolean
    sunday: boolean
}

export type FetchNotificationCampaignResponse = {
    name: string
    audiences: [{ audience: string }]
    consentRequired: boolean
    notificationType: string
    url: string
    targetType?: string
    segmentType: string
    channels: string
    status: string
    caid: string
    content: NotificationCampaignResponseContent[]
    orderFilter: NotificationCampaignResponseOrderFilter
    schedule: NotificationCampaignResponseSchedule
}

export interface NotificationCampaignResponseContent {
    title: string
    body: string
    locale: string
    isDefault: boolean
    buttonLabel?: string
}

export interface NotificationCampaignResponseOrderFilter {
    direction: string
    amountOfDays: number
}

export interface NotificationCampaignResponseWhen {
    unit: string
    interval: number
    monday: boolean
    tuesday: boolean
    wednesday: boolean
    thursday: boolean
    friday: boolean
    saturday: boolean
    sunday: boolean
}

export interface NotificationCampaignResponseSchedule {
    scheduleType: string
    start?: string
    end?: string
    date?: string
    when: NotificationCampaignResponseWhen
}

export enum ERRORS {
    CAMPAIGN_NAME_MISSING = 'CAMPAIGN_NAME_MISSING',
    NOTIFICATION_CONTENT_MISSING = 'NOTIFICATION_CONTENT_MISSING',
    START_DATE_MISSING = 'START_DATE_MISSING',
    WRONG_INTERVAL = 'WRONG_INTERVAL',
}
