import { RootAction } from 'store'
import { getType } from 'typesafe-actions'
import { createInitialUserPrefsState, UserPreferences } from './state'
import { activeAccountChanged, dateLocaleChanged } from './actions'
import { DateFormats } from 'utils/dates'

export function userPrefsReducer(state: Readonly<UserPreferences>, action: RootAction): UserPreferences {
    state = state || createInitialUserPrefsState()

    switch (action.type) {
        case getType(activeAccountChanged):
            return {
                ...state,
                activeAccount: action.payload.slug,
            }
        case getType(dateLocaleChanged):
            const dateLocale = action.payload.dateLocale
            DateFormats.setLocale(dateLocale)
            return {
                ...state,
                dateLocale,
            }
        default:
            return state
    }
}
