import { HttpService } from 'http/httpService'
import { CustomerProfileListPage, CustomerDetails, Audience, ExportAudienceBody } from './schema'

export class CRMService {
    constructor(private httpService: HttpService, private crmApiEndpoint: string) {}

    async getProfileList(account: string, query: string): Promise<CustomerProfileListPage> {
        const response = await this.httpService.fetch(`${this.crmApiEndpoint}customer/${account}/cookie_id${query}`, {
            method: 'GET',
        })

        if (!response.ok) {
            throw new Error(`Profiles Api has returned status code: ${response.status}`)
        }
        return await response.json()
    }

    async getProfileDetails(account: string, cookieId: string): Promise<CustomerDetails> {
        const response = await this.httpService.fetch(
            `${this.crmApiEndpoint}customer/${account}/cookie_id/${cookieId}`,
            {
                method: 'GET',
            },
        )

        if (!response.ok) {
            throw new Error(`Profiles Api has returned status code: ${response.status}`)
        }
        return await response.json()
    }

    getAudiences = async (): Promise<Audience[]> => {
        const response = await this.httpService.fetch(`${this.crmApiEndpoint}customer/audiences/available`, {
            method: 'GET',
        })

        if (!response.ok) {
            throw new Error('Failed to fetch audiences')
        }

        return await response.json()
    }

    getExportAudienceEndpoint = (accountSlug: string, audience: Audience) => {
        return `${this.crmApiEndpoint}customer/${accountSlug}/audience/${audience.slug}/export`
    }

    exportAudience = async ({
        accountSlug,
        audience,
        body,
    }: {
        accountSlug: string
        audience: Audience
        body: ExportAudienceBody
    }): Promise<void> => {
        const response = await this.httpService.fetch(
            `${this.crmApiEndpoint}customer/${accountSlug}/audience/${audience.slug}/export`,
            {
                method: 'POST',
                body: JSON.stringify(body),
            },
        )

        if (!response.ok) {
            throw new Error('Failed to fetch audiences')
        }

        return await response.json()
    }
}
