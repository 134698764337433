import styleIcon from 'uiComponents/studio/icons/style.svg'
import copyIcon from 'uiComponents/studio/icons/copy.svg'
import restrictionsIcon from 'uiComponents/studio/icons/target.svg'
import settingsIcon from 'uiComponents/studio/icons/settings.svg'
import { SectionObject } from 'uiComponents/studio/menuItem'
import { FieldType } from 'uiComponents/studio/form/fieldComponentSchema'
import { Audience } from 'crm/schema'

export interface FormDefinitionSchema {
    name: SectionObject
    notification: SectionObject
    target: SectionObject
    scheduling: SectionObject
}

export const formDefinition = (audiences: Audience[]): FormDefinitionSchema => {
    return {
        name: {
            name: 'name',
            label: 'Text',
            header: 'Create your campaign',
            icon: copyIcon,
            description: 'Create your campaigns to reach out to your customers.',
            sections: [
                {
                    name: 'Name',
                    fields: [
                        {
                            name: 'name',
                            type: 'text',
                            label: 'Campaign name',
                            span: 12,
                            path: 'campaign.name',
                            defaultValue: '',
                            placeholder: 'Campaign name',
                        },
                    ],
                },
            ],
        },
        target: {
            name: 'target',
            label: 'Target',
            header: 'Create your campaign',
            feature: 'CRMAudiencesPage',
            icon: restrictionsIcon,
            description: 'Choose the pages you want your Trigger Button to be shown and/or excluded.',
            sections: [
                {
                    name: 'Targeting',
                    infotip: 'Decide on which how you want your messages to be devilvered.',
                    fields: [
                        {
                            name: 'targetType',
                            type: 'multiSelect',
                            availableOptions: audiences.map((audience) => {
                                return {
                                    name: audience.name,
                                    value: audience.slug,
                                }
                            }),
                            label: 'Audience',
                            span: 12,
                            path: 'target.audience',
                            defaultValue: '',
                            infotip: 'Users that match with the selected audience.',
                        },
                        {
                            name: 'marketingConsent',
                            type: 'checkbox',
                            label: 'Marketing consent provided',
                            path: 'target.marketingConsent',
                            defaultValue: false,
                        },
                        {
                            name: 'channels',
                            type: 'select',
                            availableOptions: [
                                {
                                    name: 'Push',
                                    value: 'Push',
                                },
                                {
                                    name: 'Chat',
                                    value: 'Chat',
                                },
                                {
                                    name: 'Push and chat',
                                    value: 'Push_and_Chat',
                                },
                            ],
                            label: 'Channels',
                            span: 12,
                            path: 'target.channel',
                            defaultValue: '',
                        },
                    ],
                },
            ],
        },
        notification: {
            name: 'notification',
            label: 'Notification',
            header: 'Create your campaign',
            icon: styleIcon,
            description: 'This is the text the user will see when the message is received.',
            sections: [
                {
                    name: 'Type',
                    fields: [
                        {
                            name: 'notificationType',
                            type: 'select',
                            availableOptions: [
                                {
                                    name: 'Plain',
                                    value: 'Plain',
                                },
                                {
                                    name: 'Web',
                                    value: 'Web',
                                },
                            ],
                            label: 'Notification type',
                            span: 12,
                            path: 'notification.type',
                            defaultValue: '',
                        },
                        {
                            name: 'url',
                            type: 'text',
                            label: 'URL',
                            span: 12,
                            path: 'notification.url',
                            defaultValue: '',
                            placeholder: 'URL',
                        },
                    ],
                },
                {
                    name: 'Languages',
                    fields: [],
                },
            ],
        },
        scheduling: {
            name: 'scheduling',
            label: 'Scheduling',
            header: 'Create your campaign',
            icon: settingsIcon,
            description: "Set the scheduling for your campaign to start sending message's to your users.",
            sections: [
                {
                    name: 'Scheduling',
                    fields: [
                        {
                            name: 'type',
                            type: 'select',
                            availableOptions: [
                                {
                                    name: 'One time',
                                    value: 'one_time',
                                },
                                {
                                    name: 'Recurring',
                                    value: 'recurring',
                                },
                            ],
                            label: 'Schedule type',
                            span: 12,
                            path: 'schedule.scheduleType',
                            defaultValue: '',
                        },
                        {
                            name: 'schedule.type',
                            type: 'select',
                            availableOptions: [
                                {
                                    name: 'Days',
                                    value: 'Days',
                                },
                                {
                                    name: 'Weeks',
                                    value: 'Weeks',
                                },
                            ],
                            label: 'Repeat every',
                            span: 12,
                            path: 'schedule.whenUnit',
                            defaultValue: '',
                        },
                        {
                            name: 'scheduleWhenMonday',
                            type: 'toggle',
                            label: 'Monday',
                            span: 12,
                            path: 'schedule.scheduleWhenMonday',
                            defaultValue: false,
                        },
                        {
                            name: 'scheduleWhenTuesday',
                            type: 'toggle',
                            label: 'Tuesday',
                            span: 12,
                            path: 'schedule.scheduleWhenTuesday',
                            defaultValue: false,
                        },
                        {
                            name: 'scheduleWhenWednesday',
                            type: 'toggle',
                            label: 'Wednesday',
                            span: 12,
                            path: 'schedule.scheduleWhenWednesday',
                            defaultValue: false,
                        },
                        {
                            name: 'scheduleWhenThursday',
                            type: 'toggle',
                            label: 'Thursday',
                            span: 12,
                            path: 'schedule.scheduleWhenThursday',
                            defaultValue: false,
                        },
                        {
                            name: 'scheduleWhenFriday',
                            type: 'toggle',
                            label: 'Friday',
                            span: 12,
                            path: 'schedule.scheduleWhenFriday',
                            defaultValue: false,
                        },
                        {
                            name: 'scheduleWhenSaturday',
                            type: 'toggle',
                            label: 'Saturday',
                            span: 12,
                            path: 'schedule.scheduleWhenSaturday',
                            defaultValue: false,
                        },
                        {
                            name: 'scheduleWhenSunday',
                            type: 'toggle',
                            label: 'Sunday',
                            span: 12,
                            path: 'schedule.scheduleWhenSunday',
                            defaultValue: false,
                        },
                        {
                            name: 'date',
                            type: 'date',
                            label: 'Send on date',
                            span: 12,
                            path: 'schedule.date',
                            defaultValue: '',
                        },
                        {
                            name: 'date-time',
                            type: 'time',
                            label: 'at time',
                            span: 12,
                            path: 'schedule.time',
                            defaultValue: '',
                        },
                        {
                            name: 'start-date',
                            type: 'date',
                            label: 'Start date',
                            span: 12,
                            path: 'schedule.startDate',
                            defaultValue: '',
                        },
                        {
                            name: 'start-date-time',
                            type: 'time',
                            label: 'at time',
                            span: 12,
                            path: 'schedule.startTime',
                            defaultValue: '',
                        },
                        {
                            name: 'end-date',
                            type: 'date',
                            label: '(optional) Repeat until',
                            span: 12,
                            path: 'schedule.endDate',
                            defaultValue: '',
                        },
                        {
                            name: 'end-date-time',
                            type: 'time',
                            label: 'at time',
                            span: 12,
                            path: 'schedule.endTime',
                            defaultValue: '',
                        },
                    ],
                },
            ],
        },
    }
}

export const intervalField: FieldType = {
    name: 'schedule.interval',
    type: 'number',
    label: 'Interval',
    span: 6,
    path: 'schedule.interval',
    defaultValue: '',
    placeholder: 'interval',
    min: 0,
    max: 10,
    unit: '',
}
