export function getBrowserInfo(): any {
    let browserInfo = {}
    try {
        browserInfo = {
            appCodeName: navigator.appCodeName,
            appName: navigator.appName,
            appMinorVersion: navigator['appMinorVersion'],
            cpuClass: navigator['cpuClass'],
            platform: navigator.platform,
            opsProfile: navigator['opsProfile'],
            userProfile: navigator['userProfile'],
            systemLanguage: navigator['systemLanguage'],
            userLanguage: navigator['userLanguage'],
            appVersion: navigator.appVersion,
            userAgent: navigator.userAgent,
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
            referrer: document.referrer,
            full_url: window.location.href,
            doNotTrack: navigator.doNotTrack,
            screen: {
                height: window.screen ? window.screen.height : 'unknown',
                width: window.screen ? window.screen.width : 'unknown',
                orientation:
                    window.screen && window.screen['orientation'] ? window.screen['orientation'].type : 'unknown',
            },
        }
    } catch (error) {
        // :/
    }

    return browserInfo
}

export function isUserOnMacOS(): boolean {
    const platform = navigator.platform
    switch (platform) {
        case 'Mac68K':
            return true
        case 'MacPPC':
            return true
        case 'MacIntel':
            return true
        default:
            return false
    }
}
