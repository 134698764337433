import { HttpService } from 'http/httpService'
import { PaypalDisputesPage, DisputeDetails } from 'orders/paypalDisputes/schema'

export class PaypalService {
    constructor(private httpService: HttpService, private endpoint: string) {}

    async getDisputesList(accountSlug: string | null, query: string): Promise<PaypalDisputesPage> {
        const account = accountSlug ? `${accountSlug}/` : ''
        const response = await this.httpService.fetch(`${this.endpoint}api/v1/disputes/${account}${query}`, {
            method: 'GET',
        })

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        return await response.json()
    }

    async getDisputeDetails(accountSlug: string, id: string): Promise<DisputeDetails> {
        const response = await this.httpService.fetch(`${this.endpoint}api/v1/disputes/${accountSlug}/${id}/`, {
            method: 'GET',
        })

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }

        return await response.json()
    }

    async sendMessageToBuyer(accountSlug: string, id: string, message: string): Promise<void> {
        const response = await this.httpService.fetch(
            `${this.endpoint}api/v1/disputes/${accountSlug}/${id}/send_message/`,
            {
                method: 'POST',
                body: JSON.stringify({ message }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            },
        )

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
    }

    async rejectDispute(accountSlug: string, id: string, note: string, file: File): Promise<void> {
        const data = new FormData()
        data.append('file', file)
        data.append('note', note)
        const response = await this.httpService.fetch(`${this.endpoint}api/v1/disputes/${accountSlug}/${id}/reject/`, {
            method: 'POST',
            body: data,
        })

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
    }
}
