import styled from 'styled-typed'
import { Row, Col } from 'uiComponents/flex'

export const FormItem = styled(Row)`
    font-size: 0.7em;
    margin-bottom: 1em;
    & > ${Col} {
        margin-bottom: 0.6em;
        & > label {
            display: flex;
            position: relative;
        }
    }
    margin-bottom: 1.2em;
`
