import { useField } from 'formik'
import { toNumber } from 'lodash'
import React from 'react'
import styled from 'styled-typed'
import { useGetComponentState } from '../_shared/hooks'
import NumberInputField, { NumberInputFieldProps } from './numberInputField'
import FieldWrapper from '../fieldWrapper'

interface Props extends Omit<NumberInputFieldProps, 'value' | 'onChange'> {
    addButtons?: boolean
    validationMessageProps?: any
}

const Button = styled.button`
    user-select: none;
    outline: none;
`

const NumberInputFieldFormik: React.FC<Props> = ({
    name,
    label,
    addButtons = false,
    min = 0,
    max = Infinity,
    validationMessageProps,
    ...props
}) => {
    const [{ onChange, ...field }, { error }, { setValue }] = useField({ name })
    const state = useGetComponentState(name)

    const canDecrement = () => toNumber(field.value) > toNumber(min)
    const canIncrement = () => toNumber(field.value) < toNumber(max)
    const getStepSize = (event: any) => (event.shiftKey ? 10 : event.ctrlKey ? 100 : 1)

    const handleDecrement = (event: any) => {
        const step = getStepSize(event)
        const newValue = Math.max(toNumber(field.value) - step, toNumber(min))
        setValue(newValue)
    }

    const handleIncrement = (event: any) => {
        const step = getStepSize(event)
        const newValue = Math.min(toNumber(field.value) + step, toNumber(max))
        setValue(newValue)
    }

    return (
        <FieldWrapper
            label={label}
            error={error}
            status={state}
            validationMessageProps={{
                ...validationMessageProps,
                style: { position: 'relative', ...validationMessageProps?.style },
            }}
        >
            {addButtons && (
                <Button type="button" className="decrement" onClick={handleDecrement} disabled={!canDecrement()}>
                    -
                </Button>
            )}
            <NumberInputField
                onChange={(e) => setValue(e.target.value === '' ? null : +e.target.value)}
                state={state}
                min={min}
                max={max}
                {...props}
                {...field}
                value={field.value ?? ''}
            />
            {addButtons && (
                <Button type="button" className="increment" onClick={handleIncrement} disabled={!canIncrement()}>
                    +
                </Button>
            )}
        </FieldWrapper>
    )
}

export default NumberInputFieldFormik
