import { createInitialAuthState, applySavedAuthState } from 'auth/state'
import { createInitialFeatureState } from '../features/state'
import { createInitialUserPrefsState } from 'preferences/state'
import { createInitialProfileState } from 'settings/state'
import { createInitialPopupsState } from 'uiComponents/popups/state'
import { createInitialMessagesState } from 'uiComponents/messages/state'
import { createInitialCrmState } from 'crm/state'
import { getInitialState as getInitialSidebarState } from 'sidebar/reducer'
import { State } from './interface'
import { getOrdersTableInitialState, applySavedOrderTableState } from 'orders/orders/redux'

export function createInitialState(): State {
    return {
        auth: createInitialAuthState(),
        features: createInitialFeatureState(),
        preferences: createInitialUserPrefsState(),
        profile: createInitialProfileState(),
        popups: createInitialPopupsState(),
        messages: createInitialMessagesState(),
        crm: createInitialCrmState(),
        sidebar: getInitialSidebarState(),
        orderTable: getOrdersTableInitialState(),
    } as any
}

export function applySavedState(saved: Partial<State> | undefined): State {
    const initial = createInitialState()
    if (!saved) {
        return initial
    }

    return {
        ...initial,
        ...saved,
        auth: applySavedAuthState(saved?.auth),
        orderTable: applySavedOrderTableState(saved.orderTable),
    }
}
