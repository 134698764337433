import * as React from 'react'
import styled from 'styled-typed'

interface ImageContainerProps {
    height?: string
    width?: string
    maxHeight?: string
    maxWidth?: string
    className?: string
    style?: React.CSSProperties
}

const ImageContainer = styled.div<ImageContainerProps>`
    ${(props) => (props.height ? `height: ${props.height};` : '')}
    ${(props) => (props.width ? `width: ${props.width};` : '')}
  display: inline-block;

    & > img {
        margin: 0 auto;
        display: block;
        max-height: ${(props) => props.maxHeight || '100%'};
        max-width: ${(props) => props.maxWidth || '100%'};
        ${(props) => (!props.height ? 'height: auto;' : '')}
        ${(props) => (!props.width ? 'width: auto;' : '')}
    }
`

interface ImageProps {
    src: string
    alt: string
}

export function ResponsiveImage(props: ImageContainerProps & ImageProps) {
    return (
        <ImageContainer
            className={props.className}
            style={props.style}
            height={props.height}
            width={props.width}
            maxHeight={props.maxHeight}
            maxWidth={props.maxWidth}
        >
            <img src={props.src} alt={props.alt} />
        </ImageContainer>
    )
}
