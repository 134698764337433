import { AuthenticatedHttpService } from 'http/httpService'
import { Session } from './session'
import { AuthTicket, encodeFormData, OAuthSettings } from './oauthService'
import { LoggingService } from 'http/loggingService'
import { History } from 'history'

export class ImpersonationService {
    constructor(
        private session: () => Session,
        private loggingService: () => LoggingService,
        private httpService: AuthenticatedHttpService,
        private settings: OAuthSettings,
        private history: History,
    ) {}

    getImpersonationToken = async (
        targetUser: string,
        impersonatedBy: string,
        account: string | null,
    ): Promise<AuthTicket> => {
        const response = await this.httpService.fetch(this.settings.tokenEndpoint + 'oauth/token/', {
            method: 'POST',
            body: encodeFormData({
                grant_type: 'impersonate',
                client_id: this.settings.clientId,
                client_secret: this.settings.clientSecret,
                target_username: targetUser,
                account: account ? account : '',
            }),
            mode: 'cors',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                ...this.settings.extraHeaders,
            },
        })

        if (response.status !== 200) {
            const reason = await response.text()
            this.loggingService().logError({ error: reason }, 'impersonation_start', {
                category: 'impersonation',
                payload: { targetUser, impersonatedBy },
            })
            throw new Error(`Impersonation failed: ${reason}`)
        }

        const body = await response.json()
        return {
            accessToken: body.access_token,
            refreshToken: body.refresh_token,
        }
    }

    impersonate = async (targetUser: string, impersonatedBy: string, account: string | null): Promise<void> => {
        const ticket = await this.getImpersonationToken(targetUser, impersonatedBy, account)
        this.loggingService().logAction('impersonation_start', {
            category: 'impersonation',
            payload: { targetUser, impersonatedBy },
        })
        await this.session().initialize(ticket)
        this.history.push('/')
    }

    endImpersonation = async (ticket: AuthTicket | null, targetUser: string, impersonatedBy: string): Promise<void> => {
        this.loggingService().logAction('impersonation_end', {
            category: 'impersonation',
            payload: { targetUser, impersonatedBy },
        })
        if (!ticket) {
            this.session().abandon()
            return
        }
        await this.session().initialize(ticket)
        this.history.push('/')
    }
}
