import { HttpService } from 'http/httpService'

export interface StatsItems {
    name: string
    value: number
}

export interface EngageToolStats {
    name?: string
    values: StatsItems[]
}

interface StatsResult {
    dataSeriesDictionary: { details: EngageToolStats }
}

export interface EngageToolMultiVersionsStatsResponse {
    [key: string]: StatsResult
}

export class EngageToolsService {
    constructor(private httpService: HttpService, private statsApiEndpoint: string) {}

    async getEngageToolStats(
        widget: string,
        metric: string,
        dateFrom: string,
        dateTo: string,
        configurationIDs: string[] | null,
    ): Promise<EngageToolStats[]> {
        const query = `
      query CampaignStats($widget: String, $metric: StatsMetric,
        $dateFrom: Date, $dateTo: Date, $configurationIDs: [String]) {
        stats(widget: $widget, metric: $metric, dateFrom: $dateFrom, dateTo: $dateTo, filters: [
          {attribute: "configuration_id", values: $configurationIDs}
        ]) {
          dataSeriesDictionary {
            details {
              name,
              values {
                name,
                value
              }
            }
          }
        }
      }
    `

        const body = JSON.stringify({
            query: query,
            variables: { widget, metric, dateFrom, dateTo, configurationIDs },
            operationName: 'CampaignStats',
        })

        const response = await this.httpService.fetch(`${this.statsApiEndpoint}`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Stats Api has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        return payload.data.stats.dataSeriesDictionary.details
    }

    createNamedQueryParts(ids: string[]) {
        let queryString = ''
        ids.forEach((id, i) => {
            const idName = id.replace(/-+/g, '_')
            queryString += `
        _${idName}: stats(widget: $widget, metric: $metric, dateFrom: $dateFrom, dateTo: $dateTo,
                    filters: [ {attribute: "configuration_id", values: ["${id}"]} ]) {
          dataSeriesDictionary {
            details {
              name,
              values {
                name,
                value
              }
            }
          }
        },
      `
        })
        return queryString
    }

    async getEngageToolStatsCombined(
        widget: string,
        metric: string,
        dateFrom: string,
        dateTo: string,
        configurationIDs: string[],
    ): Promise<EngageToolMultiVersionsStatsResponse> {
        const query = `
      query CampaignStats($widget: String, $metric: StatsMetric, $dateFrom: Date, $dateTo: Date) {
        ${this.createNamedQueryParts(configurationIDs)}
      }
    `
        const body = JSON.stringify({
            query: query,
            variables: { widget, metric, dateFrom, dateTo },
            operationName: 'CampaignStats',
        })

        const response = await this.httpService.fetch(`${this.statsApiEndpoint}`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Stats Api has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        return payload.data
    }

    async getTAPAppStats(widget: string, dateFrom: string, dateTo: string): Promise<EngageToolStats[]> {
        const query = `
      query TAPAppStats($widget: String, $metric: StatsMetric,
        $dateFrom: Date, $dateTo: Date) {
        stats(widget: $widget, metric: $metric, dateFrom: $dateFrom, dateTo: $dateTo) {
          dataSeriesDictionary {
            details {
              name,
              values {
                name,
                value
              }
            }
          }
        }
      }
    `

        const body = JSON.stringify({
            query: query,
            variables: { widget, dateFrom, dateTo, metric: 'app_summary' },
            operationName: 'TAPAppStats',
        })

        const response = await this.httpService.fetch(`${this.statsApiEndpoint}`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Stats Api has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        return payload.data.stats.dataSeriesDictionary.details
    }
}
