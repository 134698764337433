import * as React from 'react'
import styled, { css } from 'styled-typed'
import DayPicker from 'react-day-picker'
import { NextLink, PrevLink } from './links'
import { transparency } from 'utils/css'
import { CONTAINER_BOX_SHADOW } from 'uiComponents/settingsContainer'

interface DayPickerModifiers {
    selected: boolean
    selectedFirst: boolean
    selectedLast: boolean
}

const selectionHighlight = css`
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => transparency(props.theme.colors.boyBlue, 0.6)};
`

const DatePickerDay = styled.div<DayPickerModifiers>`
    width: 1.875em;
    height: 1.875em;
    margin: 0 auto;
    border-radius: 50%;
    transition: background 0.1s ease-in, color 0.1s ease-in;
    color: ${(props) => (props.selected ? props.theme.colors.white : '')};
    background: ${(props) => (props.selected ? props.theme.colors.boyBlue : '')};
`

// tslint:disable
const DayPickerStyles = css`
    background: ${(props) => props.theme.colors.tableRow};
    & .DayPicker-wrapper:focus {
        outline: none;
        border-radius: 0.43em;
    }

    .DayPicker-Day:focus {
        outline: none;
    }

    .DayPicker-wrapper {
        padding: 0;
    }

    .DayPicker-Month,
    .DayPicker-Weekdays,
    .DayPicker-WeekdaysRow,
    .DayPicker-Weekday,
    .DayPicker-Body,
    .DayPicker-Week,
    .DayPicker-Day {
        display: block;
    }

    .DayPicker-Months {
        flex-wrap: nowrap;
    }

    .DayPicker-Month {
        margin: 0;
        padding-bottom: 1em;
        width: 17.5em;
        border-radius: 0.375em;
        box-shadow: ${CONTAINER_BOX_SHADOW};
        border: 1px solid rgba(57, 73, 84, 0.5);
    }

    .DayPicker-Month + .DayPicker-Month {
        margin-left: 1.875em;
    }

    .DayPicker-Weekdays {
        font-size: 0.714em;
        line-spacing: 0.2em;
        margin: 0 1.5em;
    }

    .DayPicker-Weekday,
    .DayPicker-Day {
        flex: 1;
        line-height: 1.875em;
        text-align: center;
        padding: 0;
        position: relative;
    }

    /* clear hover states */
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover,
    .DayPicker:not(.DayPicker--interactionDisabled)
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background: none;
        border-radius: 0;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
        background: ${(props) => props.theme.colors.boyBlue};
        color: ${(props) => props.theme.colors.white};
        border-radius: 0;
    }

    /* rounded edge of the first selected day */
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--selectedFirst,
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--selectedFirst:hover {
        border-radius: 1em 0 0 1em;
    }

    /* rounded edge of the last delected day */
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--selectedLast,
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--selectedLast:hover {
        border-radius: 0 1em 1em 0;
    }

    /* rounded edge of the only delected day */
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--selectedFirst.DayPicker-Day--selectedLast,
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--selectedFirst.DayPicker-Day--selectedLast:hover {
        border-radius: 1em;
    }

    /* remove all background if left and right selection edges match or selecting the first or last day */
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--selectedFirst.DayPicker-Day--selectedOnly,
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--selectedLast.DayPicker-Day--selectedOnly,
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--selectedFirst.DayPicker-Day--selectedLast {
        background: none;
    }

    /* set up hover states on child circle */
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover
        > ${DatePickerDay},
        .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--selectedFirst):not(.DayPicker-Day--selectedLast):first-child:hover
        > ${DatePickerDay},
        .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--selectedFirst):not(.DayPicker-Day--selectedLast):last-child:hover
        > ${DatePickerDay} {
        ${selectionHighlight}
    }

    .DayPicker-Day:first-child {
        padding-left: 1.2em;
    }

    .DayPicker-Day:last-child {
        padding-right: 1.2em;
    }

    .DayPicker-Day.DayPicker-Day--selectedFirst:first-child {
        padding-left: 0;
        margin-left: 1.2em;
    }

    .DayPicker-Day.DayPicker-Day--selectedLast:last-child {
        padding-right: 0;
        margin-right: 1.2em;
    }

    .DayPicker-Weekday {
        color: ${(props) => props.theme.colors.textLight};
        font-weight: bold;
        font-size: 1em;
    }

    .DayPicker-Day:not(.DayPicker-Day--disabled) {
        color: ${(props) => props.theme.colors.textDark};
    }

    .DayPicker-Week {
        margin-top: 0.875em;
    }

    .DayPicker-Body {
        font-size: 0.86em;
    }

    .DayPicker-Week:first-child {
        margin: 0;
    }

    .DayPicker-WeekdaysRow,
    .DayPicker-Week {
        display: flex;
        justify-content: space-between;
    }
`
// tslint:enable

export const Picker = styled(DayPicker)`
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in, visibility 0.3s ease-in;
    height: 0;
    position: relative;
    top: -0.6em;

    &.visible {
        height: auto;
        visibility: visible;
        opacity: 1;
    }
    ${DayPickerStyles}
`

const DatePickerCaptionContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0.3em 1em 0.3em 1em;
`

const navLinkCss = css`
    display: block;
    width: 1.875em;
    text-align: center;
    line-height: 100%;
    padding: 0.5625em 0;

    & > * {
        display: block;
        margin: 0 auto;
    }
`

const DatePickerNextLink = styled(NextLink)`
    ${navLinkCss}
`
const DatePickerPrevLink = styled(PrevLink)`
    ${navLinkCss}
`

const DatePickerMonthName = styled.div`
    font-size: 0.714em;
    letter-spacing: 0.2em;
    line-height: 100%;
    text-align: center;
    color: ${(props) => props.theme.colors.textDark};
    font-weight: 500;
    text-transform: uppercase;
`

export function renderDay(day: Date, modifiers: any) {
    return <DatePickerDay {...modifiers}>{day.getDate()}</DatePickerDay>
}

export interface DatePickerCaptionProps {
    date: Date
    localeUtils: any
}

interface CaptionContainerProps {
    onClickPrev: () => void
    onClickNext: () => void
    date: Date
    localeUtils: any
    hideNext?: boolean
    hidePrev?: boolean
}

export function CaptionContainer({
    onClickPrev,
    onClickNext,
    localeUtils,
    date,
    hidePrev,
    hideNext,
}: CaptionContainerProps) {
    const monthName = localeUtils.getMonths()[date.getMonth()]
    const year = date.getFullYear()
    return (
        <DatePickerCaptionContainer role="heading">
            {!hidePrev && <DatePickerPrevLink onClick={onClickPrev} />}
            {!hideNext && (
                <DatePickerMonthName>
                    {monthName} {year}
                </DatePickerMonthName>
            )}
            <DatePickerNextLink onClick={onClickNext} />
        </DatePickerCaptionContainer>
    )
}
