import * as React from 'react'

interface SvgProps {
    src: any
    style?: React.CSSProperties
    className?: string
}

const Svg = ({ src: SvgComponent, style, className, ...rest }: SvgProps) => {
    return <SvgComponent style={style} className={className} {...rest} />
}

export default Svg
