import { AuthenticatedHttpService } from 'http/httpService'

export interface TapAccountDetails {
    channelLogo: string
    brandColor: string
    channelTitle: string
}

export default class ChannelService {
    constructor(private httpService: AuthenticatedHttpService, private backofficeEndpoint: string) {}

    async getTapAccountDetails(accountSlug: string): Promise<TapAccountDetails> {
        const url = `${this.backofficeEndpoint}/dashboard/v1/channels/${accountSlug}/styles`
        const response = await this.httpService.fetch(url, {
            method: 'GET',
        })
        if (!response.ok) {
            throw new Error('Failed to fetch channel data')
        }
        const data = await response.json()
        return data
    }
}
