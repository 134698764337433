import * as React from 'react'
import { ConfigurationUnit, SectionObject } from 'uiComponents/studio/menuItem'
import { GeneralSection } from './generalSection'
import { MessageKind } from 'uiComponents/messages'
import {
    FormContainer,
    FormContent,
    SidebarMenu,
    Form,
    FormBody,
    ScrollArea,
    Menu,
    SidebarTitle,
} from 'uiComponents/studio/form/formComponents'
import StudioConviousLogo from 'uiComponents/studio/studioLogo'
import MenuItem from 'uiComponents/studio/menuItem'
import { History } from 'history'
import { PageLoader } from 'uiComponents/loaders'
import { Footer } from 'uiComponents/studio/form/footer'
import { FormDescription, FormHeader } from 'notificationCampaigns/components/ui'
import { OnChangeFunc, SetConfigValueFunc } from 'engageTools/studio/schema'
import { getConfigValue, handleInputChange } from 'engageTools/studio/shared'
import { LanguageValue, NotificationCampaignConfiguration, Translations } from 'notificationCampaigns/types'
import { LanguagesSection } from './langaugesSection'
import { formDefinition } from './config'
import { useHasFeatureFunc } from 'utils/useHasFeature'

export type StudioStep = 'name' | 'notification' | 'target' | 'scheduling' | 'review'

interface FormProps {
    accountSlug: string
    section: SectionObject
    currentStep: StudioStep
    history: History
    loading: boolean
    componentValues: NotificationCampaignConfiguration
    languages: LanguageValue[]
    translations: Translations
    activeLanguage: string
    defaultLanguage: string
    updateConfiguration: (path: string, value: string | number | boolean | string[]) => void
    updateLangaugeComponent: (path: string, value: string | number | boolean | string[]) => void
    updateActiveLanguage: (language: string) => void
    updateDefaultLanguage: (language: string) => void
    setCurrentStep: (step: StudioStep) => void
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    onFormSubmit: () => void
}

const StudioForm = (props: FormProps) => {
    const {
        section,
        accountSlug,
        currentStep,
        history,
        loading,
        componentValues,
        languages,
        translations,
        setCurrentStep,
    } = props
    const hasFeature = useHasFeatureFunc()

    const MENU_ITEMS = React.useMemo(() => {
        return Object.values(formDefinition([]))
            .filter((_section: SectionObject) => {
                if (!!_section.feature) {
                    return hasFeature(_section.feature)
                }

                return true
            })
            .map((_section: SectionObject, index) => {
                return {
                    name: _section.name,
                    label: `${index + 1}. ${_section.label}`,
                    icon: _section.icon,
                }
            })
    }, [hasFeature])

    const onInputChange: OnChangeFunc = (
        value: React.ChangeEvent<HTMLInputElement> | string | boolean,
        path: string,
    ) => {
        handleInputChange(value, path, setConfigValue)
    }

    const onLanguageInputChange: OnChangeFunc = (
        value: React.ChangeEvent<HTMLInputElement> | string | boolean,
        path: string,
    ) => {
        if (path === 'activeLanguage') {
            props.updateActiveLanguage(value as string)
        } else if (path === 'defaultLanguage') {
            props.updateDefaultLanguage(value as string)
        } else {
            if (props.activeLanguage !== '') {
                handleInputChange(value, path, setLanguageConfigValue)
            }
        }
    }

    const setConfigValue: SetConfigValueFunc = (path: string, value: boolean | string | number) => {
        if (path) {
            props.updateConfiguration(path, value)
        }
    }

    const setLanguageConfigValue: SetConfigValueFunc = (path: string, value: boolean | string | number) => {
        if (path) {
            props.updateLangaugeComponent(path, value)
        }
    }

    const menuItems = () => {
        return (
            <nav>
                <Menu>
                    {MENU_ITEMS.map((item, index) => {
                        return (
                            <MenuItem
                                key={`${index}`}
                                product=""
                                accountSlug={accountSlug}
                                item={item}
                                highlighted={currentStep === item.name}
                                history={history}
                                onClick={setCurrentStep}
                                configurationId=""
                            />
                        )
                    })}
                </Menu>
            </nav>
        )
    }

    const renderLanguagesSection = (innerSection: ConfigurationUnit) => (
        <LanguagesSection
            key={`${innerSection.name}`}
            section={innerSection}
            replaceTopMessages={props.replaceTopMessages}
            removeAllMessages={props.removeAllMessages}
            handleInputChange={onLanguageInputChange}
            languages={languages}
            translations={translations}
            activeLanguage={props.activeLanguage}
            defaultLanguage={props.defaultLanguage}
        />
    )

    const renderGenericSection = (innerSection: ConfigurationUnit) => (
        <GeneralSection
            key={`${innerSection.name}`}
            section={innerSection}
            replaceTopMessages={props.replaceTopMessages}
            removeAllMessages={props.removeAllMessages}
            handleInputChange={onInputChange}
            configuration={componentValues}
            getConfigValue={(item: any) => getConfigValue(item, componentValues)}
        />
    )

    return (
        <FormContainer>
            <FormContent>
                <SidebarMenu>
                    <StudioConviousLogo />
                    <SidebarTitle>{section.header}</SidebarTitle>
                    {menuItems()}
                </SidebarMenu>
                <Form noValidate>
                    <FormBody>
                        {loading && <PageLoader />}
                        {!loading && (
                            <>
                                <FormHeader>
                                    <span>{section.header}</span>
                                </FormHeader>
                                <FormDescription>
                                    <span>{section.description}</span>
                                </FormDescription>
                                <ScrollArea id="scroll-area">
                                    {section.sections.map((innerSection) => {
                                        if (innerSection.name.toLowerCase() === 'languages') {
                                            return renderLanguagesSection(innerSection)
                                        } else {
                                            return renderGenericSection(innerSection)
                                        }
                                    })}
                                </ScrollArea>
                            </>
                        )}
                    </FormBody>
                </Form>
            </FormContent>
            <Footer
                accountSlug={accountSlug}
                menuItems={MENU_ITEMS.map((item) => item.name)}
                formSubmit={() => {
                    props.onFormSubmit()
                }}
                history={history}
                navPathOverride={`/account/${accountSlug}/engage/notification-campaigns/studio`}
                ongoingPublish={false}
                currentStepOverride={currentStep}
                currentStep=""
                product=""
                configurationId=""
                componentValues={null}
            />
        </FormContainer>
    )
}

export default StudioForm
