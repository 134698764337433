import { createAction } from 'typesafe-actions'
import { AuthTicket } from 'http/oauthService'
import { User } from './state'

export const userLoggedIn = createAction(
    'UserLoggedIn',
    (action) => (ticket: AuthTicket) =>
        action({
            type: 'UserLoggedIn',
            ticket,
        }),
)

export const userDataRetrieved = createAction(
    'UserDataRetrieved',
    (action) => (user: User) =>
        action({
            type: 'UserDataRetrieved',
            user,
        }),
)

export const userLoggedOut = createAction('UserLoggedOut')

export const impersonationStart = createAction(
    'ImpersonationStart',
    (action) => (ticket: AuthTicket | null) =>
        action({
            type: 'ImpersonationStart',
            ticket,
        }),
)

export const impersonationEnd = createAction('ImpersonationEnd')
