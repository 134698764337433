import * as React from 'react'
import styled from 'styled-typed'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'
import { DisabledItemHoverInfotip } from 'uiComponents/infotip'
import { MessageKind } from 'uiComponents/messages'
import { delay } from 'utils'
import { parseSearch } from 'navigation'
import { ActionButton } from 'uiComponents/buttons'
import { PreviewSizeControls } from 'uiComponents/studio/preview/previewSizeControls'
import { PreviewSize } from 'uiComponents/studio/preview/components'

const HeaderRow = styled.div`
    position: fixed;
    width: calc(100vw - 30em);
    z-index: 1;
    font-family: ${(props) => props.theme.fonts.primary};
    background: ${(props) => props.theme.colors.white};
    flex: 0 0 6em;
    height: 6em;
    display: flex;
`

const Spacer = styled.div`
    flex: 1;
    padding: 0 1.875em;
`

const Controls = styled.div`
    flex: 1;
    padding: 0 1.875em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${(props) => props.theme.colors.textLight};
`

interface HeaderProps {
    accountSlug: string
    previewSize: PreviewSize
    studioType: 'engageTools' | 'emailTemplates' | 'tapPlacements' | 'apf' | 'notificationCampaigns' | 'tapPromotions'
    onPreviewSizeChange?: (size: PreviewSize) => void
    targetDevice: string
    onPublish: () => void
    onSaveDraft?: () => void
    replaceMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
    forceClosePath?: string
    configIsPublished?: boolean
}

interface HeaderState {
    ongoingPublish: boolean
}

export class Header extends React.Component<HeaderProps & RouteComponentProps<{}>, HeaderState> {
    private timeoutHandler: any = null
    constructor(props: HeaderProps & RouteComponentProps<{}>) {
        super(props)
        this.state = {
            ongoingPublish: false,
        }
    }

    componentWillUnmount() {
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler)
        }
    }

    forceClose = () => {
        if (this.props.forceClosePath) {
            this.props.history.push(this.props.forceClosePath)
        } else {
            this.props.studioType === 'emailTemplates'
                ? this.closeEmailTemplatesStudio()
                : this.closeEngageToolsStudio()
        }
    }

    closeEngageToolsStudio = () => {
        const origin = parseSearch(location.search).from
        const destination = origin ? (origin === 'modal_window' ? 'pop_up' : origin) : 'checkout'

        const summaryLink = `/account/${this.props.accountSlug}/engage/tools/home/${destination}`
        this.props.history.push(summaryLink)
    }

    closeEmailTemplatesStudio = () => {
        this.props.history.push(`/account/${this.props.accountSlug}/engage/email_templates/home/`)
    }

    flashErrorMessage = async (message: string) => {
        this.props.replaceMessages('form_error', 'error', message)
        await delay(6000)
        this.props.removeAllMessages()
    }

    publish = () => {
        this.props.onPublish()
    }

    saveDraft = () => {
        if (this.props.onSaveDraft) {
            this.props.onSaveDraft()
        }
    }

    render() {
        const { previewSize, onPreviewSizeChange, targetDevice, studioType } = this.props
        return (
            <HeaderRow>
                <Spacer />
                {studioType !== 'apf' && (
                    <PreviewSizeControls
                        previewSize={previewSize}
                        onPreviewSizeChange={(size) => onPreviewSizeChange && onPreviewSizeChange(size)}
                        targetDevice={targetDevice}
                        studioType={studioType}
                    />
                )}
                <Controls>
                    <ActionButton secondary size="large" id="close-button" onClick={this.forceClose}>
                        Exit
                    </ActionButton>
                    {this.props.onSaveDraft && (
                        <DisabledItemHoverInfotip
                            active={this.props.studioType === 'engageTools' && this.props.configIsPublished}
                            infotipText="You are editing a published version, therefore you can not save its draft."
                            width="35em"
                            toTheLeft
                            bottom
                        >
                            <ActionButton
                                size="large"
                                secondary
                                kind="action"
                                style={{ marginLeft: '1em', width: '8em' }}
                                id="save-draft-button"
                                disabled={
                                    this.state.ongoingPublish ||
                                    (this.props.studioType === 'engageTools' && this.props.configIsPublished)
                                }
                                onClick={this.saveDraft}
                            >
                                Save draft
                            </ActionButton>
                        </DisabledItemHoverInfotip>
                    )}
                    <ActionButton
                        size="large"
                        style={{ marginLeft: '1em' }}
                        id="publish-wizard-button"
                        disabled={this.state.ongoingPublish}
                        onClick={this.publish}
                    >
                        Publish
                    </ActionButton>
                </Controls>
            </HeaderRow>
        )
    }
}

export default withRouter(Header)
