import * as React from 'react'
import { Col } from 'uiComponents/flex'
import { MessageKind } from 'uiComponents/messages'
import { ConfigurationUnit } from 'uiComponents/studio/menuItem'
import { SectionWrapper } from 'uiComponents/studio/form/sectionWrapper'
import { FormFieldComponent } from 'uiComponents/studio/form/fieldComponents'
import { FormItem } from 'uiComponents/studio/form/formItem'
import { FieldType } from 'uiComponents/studio/form/fieldComponentSchema'
import { GetConfigValueFunc, OnChangeFunc } from 'engageTools/studio/schema'
import { NotificationCampaignConfiguration } from 'notificationCampaigns/types'
import { intervalField } from './config'
import Infotip from 'uiComponents/infotip'
import { CheckboxWrapper } from 'tapPlacements/studio/components'
import { Checkbox } from 'uiComponents/input'
import { FormLabel } from 'uiComponents/studio/form/formComponents'

interface GeneralSectionProps {
    section: ConfigurationUnit
    configuration: NotificationCampaignConfiguration
    handleInputChange: OnChangeFunc
    getConfigValue: GetConfigValueFunc
    replaceTopMessages: (id: string, status: MessageKind, text: string) => void
    removeAllMessages: () => void
}

export function GeneralSection(props: GeneralSectionProps) {
    const { section, configuration, handleInputChange, getConfigValue } = props
    const renderField = (field: FieldType) => {
        return (
            <FormFieldComponent
                field={field}
                handleChange={handleInputChange}
                value={getConfigValue(field, configuration)}
            />
        )
    }

    const onToggle = (field: FieldType) => {
        const value = !!getConfigValue(field, configuration)
        handleInputChange(!value, field.path)
    }

    const renderConsentField = (field: FieldType) => (
        <div style={{ marginBottom: '1.2em' }}>
            <CheckboxWrapper>
                <Checkbox
                    checked={!!getConfigValue(field, configuration)}
                    onChange={() => {
                        onToggle(field)
                    }}
                    style={{ cursor: 'pointer', marginRight: '0.5em' }}
                />
                <span
                    onClick={() => {
                        onToggle(field)
                    }}
                >
                    Marketing consent provided
                </span>
            </CheckboxWrapper>
        </div>
    )

    const renderLabel = (field: FieldType) => {
        return (
            <Col span={field.span}>
                <FormLabel htmlFor={field.name}>
                    {field.label}
                    {field.infotip && (
                        <Infotip bottom={true} pointer="left" maxWidth="16em" smallBoxText>
                            {field.infotip}
                        </Infotip>
                    )}
                </FormLabel>
            </Col>
        )
    }

    const renderRecurringScheduleElement = (field: FieldType) => {
        return (
            <FormItem>
                {renderLabel(field)}
                <div style={{ display: 'flex', flex: '1', marginBottom: '0.6em' }}>
                    <Col span={6}>{renderField(intervalField)}</Col>
                    <Col span={6} offset={0.25}>
                        {renderField(field)}
                    </Col>
                </div>
            </FormItem>
        )
    }

    const shouldRenderField = (field: FieldType) => {
        if (
            field.name === 'url' &&
            (configuration?.notification?.type === 'Plain' || configuration?.notification?.type === undefined)
        ) {
            return false
        }

        if (
            (field.name === 'date' || field.name === 'date-time') &&
            configuration?.schedule?.scheduleType === 'recurring'
        ) {
            return false
        }

        if (
            (field.name === 'start-date' ||
                field.name === 'start-date-time' ||
                field.name === 'end-date' ||
                field.name === 'end-date-time') &&
            configuration?.schedule?.scheduleType === 'one_time'
        ) {
            return false
        }

        if (
            field.name.startsWith('scheduleWhen') &&
            (configuration?.schedule?.whenUnit === 'Days' ||
                !configuration?.schedule?.whenUnit ||
                configuration?.schedule?.scheduleType === 'one_time')
        ) {
            return false
        }

        if (
            (field.name === 'schedule.type' || field.name === 'schedule.interval') &&
            (configuration?.schedule?.scheduleType === 'one_time' || !configuration?.schedule?.scheduleType)
        ) {
            return false
        }

        return true
    }

    if (section.fields) {
        return (
            <SectionWrapper key={section.name} title={section.name}>
                {section.fields.map((field, j) => {
                    if (!shouldRenderField(field)) {
                        return null
                    }

                    if (field.name === 'schedule.type') {
                        return <div key={`${j}`}>{renderRecurringScheduleElement(field)}</div>
                    }

                    if (field.name === 'marketingConsent') {
                        return <div key={`${j}`}>{renderConsentField(field)}</div>
                    }

                    return (
                        <div key={`${j}`}>
                            <FormItem>
                                {renderLabel(field)}
                                <Col span={field.span}>{renderField(field)}</Col>
                            </FormItem>
                        </div>
                    )
                })}
            </SectionWrapper>
        )
    } else {
        return null
    }
}
