import styled, { css } from 'styled-typed'

export const INPUT_HEIGHT = '2.5rem'

export const blockCss = css`
    display: block;
    width: 100%;
`

export const CharCount = styled.div`
    font-size: 0.7rem;
    text-align: right;
    margin-top: 1em;
`

export const successCss = css`
    border: 1px solid ${(props) => props.theme.colors.status.success};
`

export const errorCss = css`
    border: 1px solid ${(props) => props.theme.colors.status.error};
    font-weight: normal;
`

export const commonStyles = css`
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.textDark};
    transition: border 0.1s ease-in;

    &:focus {
        outline: none;
        border: 1px solid ${(props) => props.theme.colors.boyBlue};
    }
    font-size: 0.875rem;
    line-height: 1.5rem;
    height: ${INPUT_HEIGHT};
    font-weight: 400;
    border-radius: 0.25em;
    padding: 0 0.75em;
    ::placeholder {
        color: ${(props) => props.theme.colors.textLight};
        font-weight: 300;
    }

    &:disabled {
        color: ${(props) => props.theme.colors.textLight};
        cursor: not-allowed;
        background-color: ${(props) => props.theme.colors.aluminiumShades[10]};
    }

    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`
