import { State } from 'store'

export const crmSelectors = {
    getAudiences: (state: State) => state.crm.audience,
    findAudienceBySlug: (slug?: string) => (state: State) =>
        state.crm.audience.find((audience) => audience.slug === slug),
    findAudienceBySlugArray: (slugs?: string[]) => (state: State) => {
        if (!Array.isArray(slugs)) {
            return []
        }

        return slugs.map((slug) => crmSelectors.findAudienceBySlug(slug)(state))
    },
}
