export interface Language {
    value: string
    name: string
}

export const languagesDict: Language[] = [
    {
        name: 'English',
        value: 'en',
    },
    {
        name: 'Dutch',
        value: 'nl',
    },
    {
        name: 'German',
        value: 'de',
    },
    {
        name: 'French',
        value: 'fr',
    },
    {
        name: 'Spanish',
        value: 'es',
    },
    {
        name: 'Catalan',
        value: 'ca',
    },
    {
        name: 'Portuguese',
        value: 'pt',
    },
    {
        name: 'Finnish',
        value: 'fi',
    },
    {
        name: 'Russian',
        value: 'ru',
    },
    {
        name: 'Swedish',
        value: 'sv',
    },
    {
        name: 'Italian',
        value: 'it',
    },
    {
        name: 'Polish',
        value: 'pl',
    },
    {
        name: 'English American',
        value: 'en-US',
    },
    {
        name: 'Dutch (Belgium)',
        value: 'nl-BE',
    },
    {
        name: 'Czech',
        value: 'cs',
    },
    {
        name: 'Maltese',
        value: 'mt',
    },
    {
        name: 'Chinese',
        value: 'zh',
    },
    {
        name: 'Arabic',
        value: 'ar',
    },
    {
        name: 'Arabic (Saudi Arabia)',
        value: 'ar-SA',
    },
    {
        name: 'Arabic (Saudi Arabia)',
        value: 'ar-sa',
    },
    {
        name: 'Slovene',
        value: 'sl',
    },
    {
        name: 'Greek',
        value: 'el',
    },
    {
        name: 'Albanian',
        value: 'sq',
    },
    {
        name: 'Croatian',
        value: 'hr',
    },
]

export const rtlLanguages = ['ar', 'ar-sa', 'ar-SA']
