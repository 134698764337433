import * as React from 'react'
import styled from 'styled-typed'

const DotNavigationContainer = styled.div`
    & .dotstyle ul {
        position: relative;
        display: inline-block;
        margin: 0;
        padding: 0;
        list-style: none;
        cursor: default;
    }

    & .dotstyle li {
        position: relative;
        display: block;
        float: left;
        margin: 0 4px;
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    & .dotstyle li a {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        outline: none;
        border-radius: 50%;
        text-indent: -999em;
        cursor: pointer; /* make the text accessible to screen readers */
        position: absolute;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.2);
        transition: background 0.3s;
    }

    & .dotstyle li a::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 0;
        left: 0;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 0 1px #fff;
        transition: height 0.3s;
    }

    & .dotstyle li.disabled a:hover {
        cursor: not-allowed;
    }

    & .dotstyle li:not(.disabled) a:hover:focus {
        background-color: ${(props) => props.theme.colors.boyBlue};
    }

    & .dotstyle li.current a::after {
        height: 100%;
        background-color: ${(props) => props.theme.colors.boyBlue};
    }
`

interface DotNavigationItemProps {
    active: boolean
    onClick: (index: number) => void
    index: number
    disabled: boolean
}
export const DotNavigationItem: React.SFC<DotNavigationItemProps> = (props) => {
    const handleNavigation = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        var index = props.index
        if (!props.disabled) {
            props.onClick(index)
        }
    }
    const currentClass = props.active ? 'current' : ''
    const disabledClass = props.disabled ? 'disabled' : ''
    const classes = `${currentClass} ${disabledClass}`
    return (
        <li className={classes}>
            <a href="#" onClick={handleNavigation}>
                0
            </a>
        </li>
    )
}

interface DotNavigationProps {
    activeIndex: number
    amount: number
    onClick: (index: number) => void
    disabled: boolean
}
export class DotNavigation extends React.Component<DotNavigationProps> {
    constructor(props: DotNavigationProps) {
        super(props)
    }

    render() {
        let dots = []
        for (let i = 0; i < this.props.amount; ++i) {
            dots.push(
                <DotNavigationItem
                    key={i}
                    index={i}
                    active={i === this.props.activeIndex}
                    onClick={this.props.onClick}
                    disabled={this.props.disabled}
                />,
            )
        }
        return (
            <DotNavigationContainer>
                <div className="dotstyle dotstyle-fillup">
                    <ul>{dots}</ul>
                </div>
            </DotNavigationContainer>
        )
    }
}
