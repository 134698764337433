import { totals, totalsComparison, timeseries, timeseriesAndTotals, dataSeries } from 'reports/queryGenerator'

export const salesTotalsByGroupQuery = `
  query StatsTotalsByGroup(
      $widget: String,
      $metric: StatsMetric,
      $dateFrom: Date,
      $dateTo: Date,
      $filters: [FilterDictionary]) {
    paymentMethod: stats(
      widget: $widget, metric: $metric, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters,
      groupBy: "payment_method") {
      ${totals}
    }
    deviceType: stats(
      widget: $widget, metric: $metric, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters,
      groupBy: "device_type") {
      ${totals}
    }
    acquisitionChannel: stats(
      widget: $widget, metric: $metric, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters,
      groupBy: "website_traffic_acquisition_channel") {
      ${totals}
    }
    itemName: stats(
      widget: $widget, metric: $metric, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters,
      groupBy: "product_name") {
      ${totals}
    }
  },
`

export const trafficTotalsByGroupQuery = `
  query TrafficTotalsByGroup(
      $widget: String,
      $dateFrom: Date,
      $dateTo: Date,
      $filters: [FilterDictionary]) {
    returningVisitors: stats(
      widget: $widget, metric: traffic, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters,
      groupBy: "visitor_type") {
      ${totals}
    }
    deviceType: stats(
      widget: $widget, metric: website_sessions, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters,
      groupBy: "platform") {
      ${totals}
    }
    acquisitionChannel: stats(
      widget: $widget, metric: website_sessions, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters,
      groupBy: "website_traffic_acquisition_channel") {
      ${totals}
    }
    weekday: stats(
      widget: $widget, metric: website_sessions_averages, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters,
      groupBy: "weekday") {
      ${totals}
    }
    hour: stats(
      widget: $widget, metric: website_sessions_averages, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters,
      groupBy: "hour") {
      ${totals}
    }
  },
`

export const emailTotalsByGroupQuery = `
  query StatsEmailTotalsByGroup(
      $widget: String,
      $metric: StatsMetric,
      $dateFrom: Date,
      $dateTo: Date,
      $filters: [FilterDictionary]) {
    clickPerCountry: stats(
      widget: $widget, metric: $metric, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters,
      groupBy: "country_name") {
      ${totals}
    }
    topProviders: stats(
      widget: $widget, metric: $metric, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters,
      groupBy: "email_provider") {
      ${totals}
    }
  },
`

export const totalsForOverviewQuery = `
  query OverviewTotals(
      $widget: String,
      $dateFrom: Date,
      $dateTo: Date,
      $filters: [FilterDictionary]) {
    avgCartSize: stats(
      widget: $widget, metric: avg_cart_size, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters) {
      ${totals}
    }
    avgCartValue: stats(
      widget: $widget, metric: avg_order_size, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters) {
      ${totals}
    }
    checkoutConvRate: stats(
      widget: $widget, metric: checkout_conversion_rate_new, dateFrom: $dateFrom, dateTo: $dateTo) {
      ${totals}
    }
    websiteConvRate: stats(
      widget: $widget, metric: website_conversion_rate, dateFrom: $dateFrom, dateTo: $dateTo) {
      ${totals}
    }
  },
`

export const totalsForEmailsQuery = `
  query EmailsTotals(
      $widget: String,
      $dateFrom: Date,
      $dateTo: Date) {
    emailRevenue: stats(
      widget: $widget, metric: marketing_emails_revenue, dateFrom: $dateFrom, dateTo: $dateTo) {
      ${totals}
    }
    emailOrders: stats(
      widget: $widget, metric: marketing_emails_orders, dateFrom: $dateFrom, dateTo: $dateTo) {
      ${totals}
    }
    emailAvgOrderValue: stats(
      widget: $widget, metric: marketing_emails_av_order_value, dateFrom: $dateFrom, dateTo: $dateTo) {
      ${totals}
    }
  },
`

export const datedBarcodesQuery = `
  query Stats(
      $widget: String,
      $granularity: StatsGranularity,
      $filters: [FilterDictionary],
      $dateFrom: Date,
      $dateTo: Date) {
    allBarcodes: stats(
      widget: $widget, metric: redeemable_products_booked_by_event_date, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters) {
        ${timeseriesAndTotals}
      },
    redeemedBarcodes: stats(
      widget: $widget, metric: dated_barcodes_redeemed, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters) {
        ${timeseriesAndTotals}
      },
    allBarcodesRevenue: stats(
      widget: $widget, metric: redeemable_products_revenue_by_event_date, dateFrom: $dateFrom, dateTo: $dateTo,
      filters: $filters) {
        ${timeseriesAndTotals}
      },
    redeemedBarcodesRevenue: stats(
      widget: $widget, metric: dated_barcode_revenue, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters) {
        ${timeseriesAndTotals}
      },
  },
`

export const undatedBarcodesQuery = `
  query Stats(
      $widget: String,
      $granularity: StatsGranularity,
      $filters: [FilterDictionary],
      $dateFrom: Date,
      $dateTo: Date) {
    allBarcodes: stats(
      widget: $widget, metric: undated_barcodes_redeemed, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters) {
        ${timeseriesAndTotals}
      },
    allBarcodesRevenue: stats(
      widget: $widget, metric: undated_barcode_revenue, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters) {
        ${timeseriesAndTotals}
      },
  },
`

export const statsOverviewQuery = `
  query Overview(
      $widget: String,
      $filters: [FilterDictionary],
      $dateFrom: Date,
      $dateTo: Date,
      $timeIdentifierType: TimeIdentifierType) {
    defaultOverview(widget: $widget, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters, timeIdentifierType: $timeIdentifierType) {
      dataSeries {
        ticketType
        amount
        avgPrice
        avgPricePaid
        collectedRevenue
        revenue
      },
      totals {
        amount
        avgPrice
        avgPricePaid
        collectedRevenue
        revenue
        refundedAmount
        refundedMoney
        reservationsBooked
      }
    }
  },
`

const emailsData = `
  number
  share
`

const emailsDataPartialQuery = `
  emailName
  emailsSent
  emailsDelivered { ${emailsData} }
  emailsOpened { ${emailsData} }
  emailsClicked { ${emailsData} }
  emailsBounced { ${emailsData} }
`

export const emailsMarketingStatsQuery = `
  query EmailStats(
      $widget: String,
      $dateFrom: Date,
      $dateTo: Date,
    ) {
    marketingOverview(widget: $widget, dateFrom: $dateFrom, dateTo: $dateTo) {
      dataSeries {
        ${emailsDataPartialQuery}
        emailsConverted { ${emailsData} }
        emailsUnsubscribed { ${emailsData} }
      }
    }
  },
`

export const emailsTransactionalStatsQuery = `
  query EmailStats(
      $widget: String,
      $dateFrom: Date,
      $dateTo: Date,
    ) {
    transactionalOverview(widget: $widget, dateFrom: $dateFrom, dateTo: $dateTo) {
      dataSeries {
        ${emailsDataPartialQuery}
      }
    }
  },
`

export const statsRevenueWithDiscountsTimeSeriesQuery = `
  query StatsTimeSeries(
      $widget: String,
      $metric: StatsMetric,
      $granularity: StatsGranularity,
      $groupBy: String,
      $dateFrom: Date,
      $dateTo: Date,
      $filters: [FilterDictionary]) {
    grouppedData: stats(widget: $widget, metric: $metric, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters,
      groupBy: $groupBy) {
        ${timeseriesAndTotals}
      }
    totals: stats(widget: $widget, metric: $metric, dateFrom: $dateFrom, dateTo: $dateTo,
      filters: $filters) {
        ${timeseriesAndTotals}
      }
    discounts: stats(widget: $widget, metric: voucher_revenue, dateFrom: $dateFrom, dateTo: $dateTo,
      filters: $filters) {
        ${totals}
      }
    collected: stats(widget: $widget, metric: collected_revenue, dateFrom: $dateFrom, dateTo: $dateTo,
      filters: $filters) {
        ${totals}
      }
  },
`

export const statsSalesTimeSeriesQuery = `
  query StatsTimeSeries(
      $widget: String,
      $metric: StatsMetric,
      $granularity: StatsGranularity,
      $groupBy: String,
      $dateFrom: Date,
      $dateTo: Date,
      $filters: [FilterDictionary]) {
    salesGroupped: stats(widget: $widget, metric: $metric, dateFrom: $dateFrom, dateTo: $dateTo, filters: $filters,
      groupBy: $groupBy) {
        ${timeseriesAndTotals}
      },
    salesTotals: stats(widget: $widget, metric: $metric, dateFrom: $dateFrom, dateTo: $dateTo,
      filters: $filters) {
        ${timeseriesAndTotals}
      },
  },
`

export const forecastSingleDataStats = `
  query Stats(
      $widget: String,
      $today: Date,
      $tomorrow: Date,
      $nextRangeTo: Date) {
    comparisonToday: comparisonMetrics(
      accountSlug: $widget, metric: actual_and_expected_visitors, dateFrom: $today, dateTo: $today,
      comparisonType: compare_with_usual, delayType: weeks, delay: 1) {
      ${totalsComparison}
    }
    comparisonTomorrow: comparisonMetrics(
      accountSlug: $widget, metric: actual_and_expected_visitors, dateFrom: $tomorrow, dateTo: $tomorrow,
      comparisonType: compare_with_usual, delayType: weeks, delay: 1) {
      ${totalsComparison}
    }
    comparisonNextRange: comparisonMetrics(
      accountSlug: $widget, metric: actual_and_expected_visitors, dateFrom: $tomorrow, dateTo: $nextRangeTo,
      comparisonType: compare_with_usual, delayType: weeks, delay: 1) {
      ${totalsComparison}
    }
    todayDelta: stats(widget: $widget, metric: visitors_forecast_delta, dateFrom: $today, dateTo: $today) {
      dataSeries{ columns, data }
    }
    tomorrowDelta: stats(widget: $widget, metric: visitors_forecast_delta, dateFrom: $tomorrow, dateTo: $tomorrow) {
      dataSeries{ columns, data }
    }
    nextRangeDelta: stats(widget: $widget, metric: visitors_forecast_delta, dateFrom: $tomorrow, dateTo: $nextRangeTo) {
      dataSeries{ columns, data }
    }
  },
`

export const forecastTimeseriesStats = `
  query Stats(
      $widget: String,
      $granularity: StatsGranularity,
      $dateFrom: Date,
      $dateTo: Date,
      $forecastDateFrom: Date,
      $forecastDateTo: Date) {
    actualSales: stats(widget: $widget, metric: visitors, dateFrom: $dateFrom, dateTo: $dateTo) {
      ${timeseries}
    }
    actualSalesSoFar: stats(widget: $widget, metric: visitors, timeFilter: next_7_days_so_far,
      dateFrom: $dateFrom, dateTo: $dateTo) {
      ${timeseries}
    }
    estimateSales: stats(widget: $widget, metric: actual_and_expected_visitors, dateFrom: $forecastDateFrom, dateTo: $forecastDateTo) {
      ${timeseries}
    }
    estimateLowerBound: stats(widget: $widget, metric: expected_visitors_lowest, dateFrom: $forecastDateFrom, dateTo: $forecastDateTo) {
      ${timeseries}
    }
    estimateUpperBound: stats(widget: $widget, metric: expected_visitors_highest, dateFrom: $forecastDateFrom, dateTo: $forecastDateTo) {
      ${timeseries}
    }
    forecastValidation: stats(widget: $widget, metric: visitors_forecast_validation, dateFrom: $forecastDateFrom, dateTo: $forecastDateTo) {
      ${dataSeries}
    }
    weatherIcon: stats(widget: $widget, metric: weather_icon, dateFrom: $dateFrom, dateTo: $dateTo) {
      ${dataSeries}
    }
    weatherTemperature: stats(widget: $widget, metric: weather_temperature, dateFrom: $dateFrom, dateTo: $dateTo) {
      ${dataSeries}
    }
    weatherRainChance: stats(widget: $widget, metric: weather_chance_of_rain, dateFrom: $dateFrom, dateTo: $dateTo) {
      ${dataSeries}
    }
    weatherRainIntensity: stats(widget: $widget, metric: weather_rain_intensity, dateFrom: $dateFrom, dateTo: $dateTo) {
      ${dataSeries}
    }
  },
`

export const notificationCampaignOverviewStatsQuery = `
  query NotificationCampaignStats(
      $widget: String,
      $dateFrom: Date,
      $dateTo: Date,
      $filters: [FilterDictionary]
    ) {
    stats(widget: $widget, dateFrom: $dateFrom, dateTo: $dateTo, metric: app_notifications_summary, filters: $filters) {
      ${dataSeries}
    }
  },
`
