/* eslint-disable react/display-name */
import * as React from 'react'
import { ReactComponent } from 'reactUtils'
import { FeatureState } from 'features/state'
import { State } from 'store'
import { connect, useSelector } from 'react-redux'
import { Subtract } from 'utility-types'
import { useParams } from 'react-router-dom'
import { BaseRouteParams } from 'hocs'

interface StateProps {
    features: FeatureState
    conviousAdmin: boolean
}

export interface FeatureProps {
    hasFeature: (feature: string, accountSlug?: string) => boolean
    getComponentValue?: (component: string, accountSlug?: string) => any
}

export function hasFeature({
    accountSlug,
    features,
    featureName,
    conviousAdmin,
}: {
    accountSlug?: string
    features: FeatureState
    featureName: string
    conviousAdmin: boolean
}) {
    const accountFeatures = accountSlug ? features.forAccount[accountSlug] : []
    const allAplicableFeatures = accountFeatures && accountFeatures.length > 0 ? accountFeatures : features.defaults
    const feature = allAplicableFeatures.filter((x) => x.name === featureName)
    return feature.length > 0 && (feature[0].value === 'enabled' || (feature[0].value === 'convious' && conviousAdmin))
}

export const useHasFeature = (name: string) => {
    const features = useSelector<State, FeatureState>((state) => state.features)
    const conviousAdmin = useSelector<State, boolean>((state) => (state.auth.user ? state.auth.user.isAdmin : false))
    const { accountSlug } = useParams<BaseRouteParams>()

    return hasFeature({
        conviousAdmin,
        accountSlug,
        featureName: name,
        features: features,
    })
}

export function withFeatures<TProps extends FeatureProps>(
    Component: ReactComponent<TProps>,
): ReactComponent<Subtract<TProps, FeatureProps>> {
    class WithFeatures extends React.Component<TProps & StateProps> {
        hasFeature = (featureName: string, accountSlug?: string): boolean => {
            return hasFeature({
                accountSlug,
                featureName: featureName,
                features: this.props.features,
                conviousAdmin: this.props.conviousAdmin,
            })
        }

        getComponentValue = (componentName: string, accountSlug?: string): any => {
            const accountComponents = accountSlug ? this.props.features.forAccount[accountSlug] : []
            const allAplicableComponents =
                accountComponents && accountComponents.length > 0 ? accountComponents : this.props.features.defaults
            const component = allAplicableComponents.find((x) => x.name === componentName)
            return component ? component.value : null
        }

        render() {
            // @ts-ignore
            return <Component hasFeature={this.hasFeature} getComponentValue={this.getComponentValue} {...this.props} />
        }
    }

    function mapStateToProps(state: State) {
        return {
            features: state.features,
            conviousAdmin: state.auth.user ? state.auth.user.isAdmin : false,
        }
    }

    // @ts-ignore
    const ConnectedComponent = connect(mapStateToProps, () => ({}))(WithFeatures)

    return function (props: Subtract<TProps, FeatureProps>) {
        // @ts-ignore
        return <ConnectedComponent {...props} />
    }
}
