import 'draft-js/dist/Draft.css'
import React from 'react'
import styled, { css } from 'styled-typed'
import {
    Editor,
    EditorState,
    RichUtils,
    ContentState,
    ContentBlock,
    SelectionState,
    DraftInlineStyle,
    CompositeDecorator,
} from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML, InlineCreators } from 'draft-js-import-html'
import { Debounce } from 'debounce'
import { FinishTypingHandler } from 'uiComponents/input'
import { Toolbar } from './toolbar'
import { delay, flatMap } from 'utils'
import { findLinkEntities, Link } from './link'
import { ValidationMessage } from 'uiComponents/form/formElements'
import createStyles from 'draft-js-custom-styles'

interface RichTextEditorState {
    editorState: EditorState
    focused: boolean
    errorMessage: string
}

export type FontStyle = 'regular' | 'bold' | 'italic' | 'bold-italic'

export interface RichTextEditorProps {
    id: string
    value: string
    maxLength?: number
    onChange?: (html: string, text: string, fontStyles: FontStyle[]) => void
    onFinishTyping?: FinishTypingHandler
    finishTypingDelay?: number
    status?: 'error' | 'normal' | 'success'
    onFocus?: () => void
    onBlur?: () => void
    style?: React.CSSProperties
    withLink?: boolean
    withColor?: boolean
    withOrderedList?: boolean
    withUnorderedList?: boolean
    hideAlignmentOption?: boolean
    colorPickerOnRight?: boolean
    customVariables?: string[]
    showCharsLeft?: boolean
}

const EditorWrapper = styled.div`
    position: relative;

    & svg {
        fill: ${(props) => props.theme.colors.textDark};
    }
`

const CharCount = styled.div`
    font-size: 0.7rem;
    text-align: right;
    margin-top: 1em;
`

const errorCss = css`
    border: 1px solid ${(props) => props.theme.colors.status.error};
`
interface EditorFieldProps {
    status?: 'error' | 'normal' | 'success'
}

const ErrorMessage = styled(ValidationMessage)`
    position: absolute;
    top: unset;
    bottom: 0em;
    left: 1.5em;
    font-size: 0.75rem;
    font-weight: normal;
    margin-left: 0;
`
const EditorField = styled.div`
    font-size: 0.875rem;
    font-weight: lighter;
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.textDark};
    border-radius: 0.375em;
    transition: border 0.1s ease-in;
    cursor: text;
    min-height: 4.8em;
    position: relative;
    z-index: 1;

    & .public-DraftEditor-content {
        padding: 0.5rem;
    }

    ${(props: EditorFieldProps) => (props.status === 'error' ? errorCss : '')};

    &.focused {
        outline: 1px solid ${(props) => props.theme.colors.boyBlue};
        ${(props: EditorFieldProps) => (props.status === 'error' ? errorCss : '')};
    }

    & .align-left div {
        text-align: left;
    }

    & .align-right div {
        text-align: right;
    }

    & .align-center div {
        text-align: center;
    }

    & .align-justify div {
        text-align: justify;
    }
`

const styleMap = {
    'font-roboto': {
        fontFamily: "'Roboto', sans-serif",
    },
    'font-roboto-slab': {
        fontFamily: "'Roboto Slab', serif",
    },
    'font-open-sans': {
        fontFamily: "'Open Sans', sans-serif",
    },
    'font-lato': {
        fontFamily: "'Lato', sans-serif",
    },
    'font-montserrat': {
        fontFamily: "'Montserrat', sans-serif",
    },
}

const { styles, customStyleFn, exporter } = createStyles(['color'], styleMap)

function getBlockStyle(block: ContentBlock) {
    const type = block.getType() as string
    switch (type) {
        case 'paragraph-align-left':
            return {
                style: {
                    textAlign: 'left',
                },
                attributes: {
                    class: 'paragraph-align-left',
                },
            }
        case 'paragraph-align-center':
            return {
                style: {
                    textAlign: 'center',
                },
                attributes: {
                    class: 'paragraph-align-center',
                },
            }
        case 'paragraph-align-right':
            return {
                style: {
                    textAlign: 'right',
                },
                attributes: {
                    class: 'paragraph-align-right',
                },
            }
        case 'paragraph-align-justify':
            return {
                style: {
                    textAlign: 'justify',
                },
                attributes: {
                    class: 'paragraph-align-justify',
                },
            }
        case 'unordered-list-item':
            return {
                attributes: {
                    class: 'unordered-list-item',
                },
            }
        case 'ordered-list-item':
            return {
                attributes: {
                    class: 'ordered-list-item',
                },
            }
        default:
            return {}
    }
}

function getHtml(editorState: EditorState): string {
    const contentState = editorState.getCurrentContent()
    if (contentState.hasText()) {
        const inlineStyles = exporter(editorState)
        const duplicateStyles = ['BOLD', 'ITALIC', 'UNDERLINE']
        duplicateStyles.forEach((s) => {
            delete inlineStyles[s]
        })
        return stateToHTML(contentState, {
            defaultBlockTag: 'div',
            inlineStyles: inlineStyles,
            blockStyleFn: getBlockStyle,
        } as any)
    }
    return ''
}

function getBlockType(el: Element) {
    const htmlEl = el as HTMLElement
    if (!htmlEl) {
        throw new Error('Given a non-html element')
    }

    const classes = htmlEl.className.split(' ')
    const blockTypes = [
        'paragraph-align-left',
        'paragraph-align-right',
        'paragraph-align-center',
        'paragraph-align-justify',
        'unordered-list-item',
        'ordered-list-item',
    ]
    const blockType = blockTypes.filter((x) => classes.indexOf(x) !== -1)[0] || 'unstyled'
    return { type: blockType }
}

function getInlineStyle(el: Element, { Style, Entity }: InlineCreators) {
    const htmlEl = el as HTMLElement
    if (!htmlEl) {
        throw new Error('Given a non-html element')
    }

    if (htmlEl.style.color) {
        return Style('CUSTOM_COLOR_' + htmlEl.style.color)
    }

    if (htmlEl.tagName === 'SPAN') {
        const classes = htmlEl.className.split(' ')
        return Style(classes[0])
    }

    return undefined
}

function htmlToState(html: string): ContentState {
    return stateFromHTML(html, {
        customBlockFn: getBlockType,
        customInlineFn: getInlineStyle,
    })
}

function getBlockClass(contentBlock: ContentBlock): string {
    const type = contentBlock.getType() as string
    switch (type) {
        case 'paragraph-align-center':
            return 'align-center'
        case 'paragraph-align-right':
            return 'align-right'
        case 'paragraph-align-justify':
            return 'align-justify'
        case 'unordered-list-item':
            return 'unordered-list-item'
        case 'ordered-list-item':
            return 'ordered-list-item'
        default:
            return ''
    }
}

function getFontStyle(style: DraftInlineStyle): FontStyle {
    const bold = style.has('BOLD')
    const italic = style.has('ITALIC')

    if (bold && italic) {
        return 'bold-italic'
    }

    if (bold) {
        return 'bold'
    }

    if (italic) {
        return 'italic'
    }

    return 'regular'
}

function getUsedStyles(contentState: ContentState): FontStyle[] {
    const blocks = contentState.getBlocksAsArray()
    const allStyles = flatMap(blocks, (b) => b.getCharacterList().toArray()).map((x) => x.getStyle())
    const result = new Set()
    for (let i = 0; i < allStyles.length; i++) {
        result.add(getFontStyle(allStyles[i]))
    }

    return Array.from(result) as FontStyle[]
}

export class RichTextInput extends React.Component<RichTextEditorProps, RichTextEditorState> {
    private editor: Editor | null
    private editorContainer: HTMLDivElement | null
    private editorHasFocus = false
    private html: string

    private finishTypingLater: Debounce<string> | undefined

    constructor(props: RichTextEditorProps) {
        super(props)
        this.state = {
            editorState: this.getEditorStateOnLoad(props.value),
            focused: false,
            errorMessage: '',
        }
        this.html = props.value

        if (this.props.onFinishTyping) {
            this.finishTypingLater = new Debounce(this.props.onFinishTyping, this.props.finishTypingDelay || 500)
        }
    }

    componentDidMount() {
        if (!this.editorContainer) {
            throw new Error('Editor container ref was not set. Should not happen')
        }

        this.editorContainer.addEventListener('blur', this.checkIfFocusOutside, true)
        document.addEventListener('mousedown', this.onOutsideClick, false)
    }

    componentWillUnmount() {
        if (!this.editorContainer) {
            throw new Error('Editor container ref was not set. Should not happen')
        }
        if (this.finishTypingLater) {
            this.finishTypingLater.clear()
        }

        this.editorContainer.removeEventListener('blur', this.checkIfFocusOutside)
        document.removeEventListener('mousedown', this.onOutsideClick, false)
    }

    UNSAFE_componentWillReceiveProps(props: RichTextEditorProps) {
        if (this.html !== props.value) {
            this.html = props.value
            this.setState({ editorState: this.getEditorStateOnLoad(props.value) })
        }
    }

    getEditorStateOnLoad = (value: string) => {
        const decorator = new CompositeDecorator([{ strategy: findLinkEntities, component: Link }])
        return EditorState.createWithContent(htmlToState(value), decorator)
    }

    selectEditor = () => {
        if (this.editorHasFocus) {
            return
        }

        const content = this.state.editorState.getCurrentContent()
        const blockMap = content.getBlockMap()
        const key = blockMap.last().getKey()
        const length = blockMap.last().getLength()

        if (this.state.editorState.getSelection().isEmpty()) {
            const selection = new SelectionState({
                anchorKey: key,
                anchorOffset: length,
                focusKey: key,
                focusOffset: length,
            })

            this.setState(
                {
                    editorState: EditorState.acceptSelection(this.state.editorState, selection),
                },
                () => {
                    if (this.editor) {
                        this.editor.focus()
                    }
                },
            )
        } else {
            if (this.editor) {
                this.editor.focus()
            }
        }
    }

    onChange = (editorState: EditorState) => {
        this.setState({ errorMessage: '', editorState })
        const contentState = editorState.getCurrentContent()
        const text = contentState.getPlainText()

        if (this.props.maxLength && text.length > this.props.maxLength) {
            return
        }

        if (this.props.onChange && contentState !== this.state.editorState.getCurrentContent()) {
            const html = getHtml(editorState)
            const usedStyles = getUsedStyles(contentState)
            this.props.onChange(html, text, usedStyles)
            this.html = html
        }

        if (this.finishTypingLater) {
            this.finishTypingLater.trigger(text)
        }

        this.setState({ editorState })
    }

    onFocus = () => {
        this.editorHasFocus = true
        this.setState({ focused: true })
        if (this.props.onFocus) {
            this.props.onFocus()
        }
    }

    onBlur = () => {
        this.editorHasFocus = false
        this.checkIfFocusOutside()
        if (this.props.onBlur) {
            this.props.onBlur()
        }
    }

    changeAndFocus = async (editorState: EditorState) => {
        this.onChange(editorState)
        if (this.editor) {
            await delay(0)
            this.editor.focus()
        }
    }

    handleKeyCommand = (command: string, editorState: EditorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command)
        if (newState) {
            this.onChange(newState)
            return 'handled'
        }
        return 'not-handled'
    }

    handleBeforeInput = (chars: string, editorState: EditorState) => {
        const totalLength = this.state.editorState.getCurrentContent().getPlainText().length + chars.length
        if (this.props.maxLength && totalLength > this.props.maxLength) {
            return 'handled'
        }
        return 'not-handled'
    }

    handlePastedText = (plainText: string, html: string | undefined, editorState: EditorState) => {
        const newLength = this.state.editorState.getCurrentContent().getPlainText().length + plainText.length
        if (this.props.maxLength && newLength > this.props.maxLength) {
            this.setState({
                errorMessage: `Text exceeds max length limit of ${this.props.maxLength}.`,
            })
            return 'handled'
        }
        return 'not-handled'
    }

    checkIfFocusOutside = async () => {
        await delay(50)
        if (!this.editorContainer) {
            return
        }

        let el: Element | null = window.document.activeElement
        if (el === window.document.body || this.checkFocusOnEditorContainer(el)) {
            return
        }

        this.setState({ focused: false })
    }

    checkFocusOnEditorContainer = (el: Element | null) => {
        while (el) {
            if (el === this.editorContainer) {
                return true
            }
            el = el.parentElement
        }
        return false
    }

    onOutsideClick = (ev: MouseEvent) => {
        if (
            this.editorContainer &&
            this.editorContainer.contains &&
            !this.editorContainer.contains(ev.target as Node) &&
            this.state.focused
        ) {
            this.setState({ focused: false })
        }
    }

    setErrorMessage = (errorMessage: string) => {
        this.setState({ errorMessage })
    }

    render() {
        const contentState = this.state.editorState.getCurrentContent()
        const currentTextLength = contentState.getPlainText().length

        return (
            <EditorWrapper ref={(e) => (this.editorContainer = e)} id={this.props.id}>
                <EditorField
                    className={this.state.focused ? 'focused' : ''}
                    onClick={this.selectEditor}
                    status={this.props.status}
                    style={this.props.style}
                >
                    <Editor
                        ref={(e) => (this.editor = e)}
                        editorState={this.state.editorState}
                        onChange={this.onChange}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        handleKeyCommand={this.handleKeyCommand}
                        handleBeforeInput={this.handleBeforeInput}
                        handlePastedText={this.handlePastedText}
                        blockStyleFn={getBlockClass}
                        customStyleMap={styleMap}
                        customStyleFn={customStyleFn}
                    />
                </EditorField>
                <Toolbar
                    visible={this.state.focused}
                    onChange={this.changeAndFocus}
                    styles={styles}
                    editorState={this.state.editorState}
                    style={this.props.style}
                    withLink={this.props.withLink}
                    withColor={this.props.withColor}
                    withOrderedList={this.props.withOrderedList}
                    withUnorderedList={this.props.withUnorderedList}
                    hideAlignmentOption={this.props.hideAlignmentOption}
                    colorPickerOnRight={this.props.colorPickerOnRight}
                    customVariables={this.props.customVariables}
                    setErrorMessage={this.setErrorMessage}
                />
                {this.props.maxLength && this.props.showCharsLeft && (
                    <CharCount>
                        {currentTextLength}/{this.props.maxLength}
                    </CharCount>
                )}
                <ErrorMessage className={this.state.errorMessage ? 'validation-message-visible' : ''}>
                    {this.state.errorMessage}
                </ErrorMessage>
            </EditorWrapper>
        )
    }
}
