import styled from 'styled-typed'
import { SelectElementProps } from './interface'

export const OptionsList = styled.div<SelectElementProps>`
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : '')};
    overflow-y: auto;
`

interface ListItemProps {
    selected: boolean
    disabled?: boolean
}

export const OptionsListItem = styled.div<ListItemProps>`
    background: ${(props) => (props.selected ? props.theme.colors.background : '')};
    line-height: 1.8rem;
    padding: 0 1.1em;
    font-weight: lighter;
    outline: none;
    cursor: pointer;
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1em;

    &:last-child {
        padding-bottom: 0.4em;
    }
    &:hover,
    &.focused {
        background: ${(props) => props.theme.colors.background};
    }
`
