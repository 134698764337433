import { HttpService } from 'http/httpService'
import { Store } from 'redux'
import { State } from 'store'
import { getBrowserInfo } from 'utils/browserInfo'

export const EVENT_TYPE = {
    page_view: 'page_view',
    navigation: 'navigation',
    bug_report: 'bug_report',
    access_denied: 'access_denied',
    order_alerts_subscribed: 'order_alerts_subscribed',
    order_alerts_unsubscribed: 'order_alerts_unsubscribed',
    performance_reports_subscription_update: 'performance_reports_subscription_update',
    barcodes_alert_subscription_update: 'barcodes_alert_subscription_update',
    confirmation_email_resent: 'confirmation_email_resent',
    confirmation_email_updated: 'confirmation_email_updated',
    order_list_ticket_download: 'order_list_ticket_download',
    order_refunded: 'order_refunded',
    bulk_order_refunded: 'bulk_order_refunded',
    order_visit_date_or_time_updated: 'order_visit_date_or_time_updated',
    order_export_requested: 'order_export_requested',
    order_async_export_requested: 'order_async_export_requested',
    order_apf_updated: 'order_apf_updated',
    order_item_valid_to_updated: 'order_item_valid_to_updated',
    order_barcodes_redeemed: 'order_barcodes_redeemed',
    group_order_barcodes_redeemed: 'group_order_barcodes_redeemed',
    order_details_rest: 'order_details_rest',
    order_barcodes_redeemed_from_scanner: 'order_barcodes_redeemed_from_scanner',
    email_template_config_updated: 'email_template_config_updated',
    email_template_test_sent: 'email_template_test_sent',
    copy_editor_search: 'copy_editor_search',
    copy_updated: 'copy_updated',
    copy_deleted: 'copy_deleted',
    copy_created: 'copy_created',
    studio_opened: 'studio_opened',
    studio_closed: 'studio_closed',
    studio_step_accessed: 'studio_step_accessed',
    campaign_product_published: 'campaign_product_published',
    campaign_product_unpublished: 'campaign_product_unpublished',
    campaign_product_updated: 'campaign_product_updated',
    email_template_status_updated: 'email_template_status_updated',
    inventory_created: 'inventory_created',
    inventory_updated: 'inventory_updated',
    inventory_rule_created: 'inventory_rule_created',
    inventory_rule_updated: 'inventory_rule_updated',
    inventory_rule_deleted: 'inventory_rule_deleted',
    timeslot_group_created: 'timeslot_group_created',
    timeslot_group_updated: 'timeslot_group_updated',
    timeslot_group_deleted: 'timeslot_group_deleted',
    opening_times_updated: 'opening_times_updated',
    opening_times_exception_created: 'opening_times_exception_created',
    opening_times_exception_updated: 'opening_times_exception_updated',
    opening_times_exception_deleted: 'opening_times_exception_deleted',
    product_list_created: 'product_list_created',
    product_list_updated: 'product_list_updated',
    product_list_archived: 'product_list_archived',
    product_list_status_or_priority_updated: 'product_list_status_or_priority_updated',
    product_updated: 'product_updated',
    product_created: 'product_created',
    product_duplicated: 'product_duplicated',
    product_archived: 'product_archived',
    product_status_updated: 'product_status_updated',
    product_priority_updated: 'product_priority_updated',
    pricing_settings_updated: 'pricing_settings_updated',
    pricing_exception_updated: 'pricing_exception_updated',
    pricing_exception_created: 'pricing_exception_created',
    pricing_exception_deleted: 'pricing_exception_deleted',
    product_validity_updated: 'product_validity_updated',
    validity_exception_created: 'validity_exception_created',
    validity_exception_updated: 'validity_exception_updated',
    validity_exception_deleted: 'validity_exception_deleted',
    reservation_barcodes_upload_request: 'reservation_barcodes_upload_request',
    vouchers_upload_request: 'vouchers_upload_request',
    coupons_upload_request: 'coupons_upload_request',
    barcodes_upload_request: 'barcodes_upload_request',
    sold_tickets_upload_request: 'sold_tickets_upload_request',
    barcode_pool_created: 'barcode_pool_created',
    barcode_pool_name_updated: 'barcode_pool_name_updated',
    barcodes_deleted: 'barcodes_deleted',
    vouchers_deleted: 'vouchers_deleted',
    coupons_deleted: 'coupons_deleted',
    reservation_codes_deleted: 'reservation_codes_deleted',
    reservation_codes_pool_created: 'reservation_codes_pool_created',
    reservation_codes_pool_edited: 'reservation_codes_pool_edited',
    reservation_codes_pool_name_updated: 'reservation_codes_pool_name_updated',
    reservation_codes_pool_archived: 'reservation_codes_pool_archived',
    reservation_codes_generate_request_placed: 'reservation_codes_generate_request_placed',
    unused_reservation_codes_remove_request: 'unused_reservation_codes_remove_request',
    coupons_pool_created: 'coupons_pool_created',
    coupons_pool_name_updated: 'coupons_pool_name_updated',
    price_calendar_preview: 'price_calendar_preview',
    global_pricing_settings_saved: 'global_pricing_settings_saved',
    global_pricing_settings_fetched: 'global_pricing_settings_fetched',
    global_pricing_settings_reset: 'global_pricing_settings_reset',
    default_global_pricing_settings_fetched: 'default_global_pricing_settings_fetched',
    ticket_template_updated: 'ticket_template_updated',
    ticket_template_created: 'ticket_template_created',
    ticket_template_deleted: 'ticket_template_deleted',
    reseller_created: 'reseller_created',
    reseller_deleted: 'reseller_deleted',
    reseller_updated: 'reseller_updated',
    retailer_created: 'retailer_created',
    retailer_updated: 'retailer_updated',
    impersonation_start: 'impersonation_start',
    impersonation_end: 'impersonation_end',
    crm_audiences_export_requested: 'crm_audiences_export_requested',
    apf_config_updated: 'apf_config_updated',
    report_export_requested: 'report_export_requested',
    option_group_priority_updated: 'option_group_priority_updated',
    option_group_deleted: 'option_group_deleted',
    option_item_deleted: 'option_item_deleted',
    option_group_updated: 'option_group_updated',
    option_group_created: 'option_group_created',
    option_item_updated: 'option_item_updated',
    option_item_created: 'option_item_created',
    google_ttd_item_created: 'google_ttd_item_created',
    google_ttd_item_updated: 'google_ttd_item_updated',
    google_ttd_item_deleted: 'google_ttd_item_deleted',
    discount_rule_created: 'discount_rule_created',
    discount_rule_updated: 'discount_rule_updated',
    discount_rule_deleted: 'discount_rule_deleted',
    get_discount_pools: 'get_discount_pools',
    get_discount_codes: 'get_discount_codes',
    get_discount_upload_details: 'get_discount_upload_details',
    get_discount_code_details: 'get_discount_code_details',
    update_discount_code: 'update_discount_code',
    redeem_discount_codes: 'redeem_discount_codes',
    upload_discount_codes: 'upload_discount_codes',
    get_carts: 'get_carts',
    change_cart_expiration_date: 'change_cart_expiration_date',
    mark_cart_as_paid: 'mark_cart_as_paid',
    add_discount_pool: 'add_discount_pool',
    delete_discount_pool: 'delete_discount_pool',
    edit_discount_pool: 'edit_discount_pool',
    get_mollie_status: 'get_mollie_status',
    send_mollie_auth: 'send_mollie_auth',
    delete_mollie_auth: 'delete_mollie_auth',
    paypal_init_auth: 'paypal_init_auth',
    paypal_get_status: 'paypal_get_status',
    paypal_postback: 'paypal_postback',
    delete_paypal_auth: 'delete_paypal_auth',
    get_park_map_list: 'get_park_map_list',
    get_park_map: 'get_park_map',
    create_park_map: 'create_park_map',
    update_park_map: 'update_park_map',
    delete_park_map: 'delete_park_map',
    get_park_map_styles: 'get_park_map_styles',
    create_park_map_style: 'create_park_map_style',
    update_park_map_style: 'update_park_map_style',
    delete_park_map_style: 'detele_park_map_style',
    get_park_map_categories: 'get_park_map_categories',
    get_park_map_category: 'get_park_map_category',
    get_park_map_pois: 'get_park_map_pois',
    get_park_map_poi: 'get_park_map_poi',
    update_park_map_category: 'update_park_map_category',
    create_park_map_category: 'create_park_map_category',
    create_park_map_poi: 'create_park_map_poi',
    delete_poi: 'delete_poi',
    update_park_map_poi: 'update_park_map_poi',
    delete_category: 'delete_category',
    update_park_map_categories_positions: 'update_park_map_categories_positions',
    get_adyen_account_holder: 'get_adyen_account_holder',
    authenticate_pinpoint_service: 'authenticate_pinpoint_service',
    create_aws_pinpoint_app: 'create_aws_pinpoint_app',
    get_aws_pinpoint_app: 'get_aws_pinpoint_app',
    delete_aws_pinpoint_app: 'delete_aws_pinpoint_app',
    get_notifications_campaigns: 'get_notifications_campaigns',
    get_notifications_campaign: 'get_notifications_campaign',
    create_notifications_campaign: 'create_notifications_campaign',
    update_notifications_campaign: 'update_notifications_campaign',
    delete_notifications_campaign: 'delete_notifications_campaign',
    get_custom_audience_list: 'get_custom_audience_list',
    get_custom_audience: 'get_custom_audience',
    create_custom_audience: 'create_custom_audience',
    update_custom_audience: 'update_custom_audience',
    delete_custom_audience: 'delete_custom_audience',
    get_export_custom_audience_endpoint: 'get_export_custom_audience_endpoint',
    voucherify_get_status: 'voucherify_get_status',
    voucherify_connect: 'voucherify_connect',
    voucherify_disconnect: 'voucherify_disconnect',
}

export type EventType = keyof typeof EVENT_TYPE

export type EventCategoryType =
    | 'unknown'
    | 'uncategorised'
    | 'profile_settings'
    | 'notifications'
    | 'onboarding'
    | 'kpis'
    | 'account_settings'
    | 'users'
    | 'script'
    | 'dashboard'
    | 'orders_transactions'
    | 'order_details_rest'
    | 'orders_details'
    | 'orders_channels'
    | 'reports'
    | 'engage_tools_home'
    | 'engage_tools_wonderbar'
    | 'engage_tools_modal_window'
    | 'engage_tools_trigger_button'
    | 'engage_tools_checkout'
    | 'email_templates'
    | 'copy_editor'
    | 'ticket_templates'
    | 'tap_placements'
    | 'codes_barcodes'
    | 'codes_reservations'
    | 'codes_vouchers'
    | 'codes_coupons'
    | 'codes_product_ids'
    | 'sold_tickets'
    | 'opening_times'
    | 'pricing'
    | 'validity'
    | 'time_slots'
    | 'inventory'
    | 'option_groups'
    | 'products_home'
    | 'products_product_list'
    | 'products_article'
    | 'global_pricing_settings'
    | 'resellers'
    | 'retailers'
    | 'impersonation'
    | 'crm_audiences'
    | 'crm_profiles'
    | 'apf_studio'
    | 'google_ttd'
    | 'discount_rules'
    | 'discount_codes'
    | 'cart'
    | 'integrations'
    | 'tap_park_map'
    | 'custom_audience'

interface AnyDataObject {
    [key: string]: any
}

export interface ActionEventData {
    category: EventCategoryType
    payload: AnyDataObject
}

const parseExceptionUrl = (url: string): EventCategoryType => {
    if (url.indexOf('personal_settings') > -1) {
        return 'profile_settings'
    } else if (url.indexOf('onboarding') > -1) {
        return 'onboarding'
    } else if (url.indexOf('kpis') > -1) {
        return 'kpis'
    } else {
        return 'unknown'
    }
}

const parseProductUrl = (path: string[]): EventCategoryType => {
    switch (path[4]) {
        case 'home?':
            return 'uncategorised'
        case 'home':
            return 'products_home'
        case 'discount_rules':
            return 'discount_rules'
        case 'crud':
            return path[5] === 'productList' ? 'products_product_list' : 'products_article'
        default:
            return path[4] as EventCategoryType
    }
}

const parseEngageToolsUrl = (path: string[]): EventCategoryType => {
    if (path[4] === 'tools') {
        return path[5] === 'popup' ? 'engage_tools_modal_window' : (`engage_tools_${path[5]}` as EventCategoryType)
    }
    return path[4] as EventCategoryType
}

const parseOrdersUrl = (path: string[]): EventCategoryType => {
    if (path[4] === 'channels' && path[5] === '?') {
        return 'uncategorised'
    }
    return `${path[3]}_${path[4]}` as EventCategoryType
}

const parseVenueUrl = (path: string[]): EventCategoryType => {
    switch (path[4]) {
        case 'opening_times':
            return path[5] === '?' ? 'uncategorised' : (path[5] as EventCategoryType)
        case 'codes?':
            return 'uncategorised'
        case 'codes':
            return path[5] === 'tickets'
                ? 'codes_barcodes'
                : path[5] === 'sold_tickets'
                ? 'sold_tickets'
                : (`${path[4]}_${path[5]}` as EventCategoryType)
        default:
            return path[4] as EventCategoryType
    }
}

const parseSettingsUrl = (path: string[]): EventCategoryType => {
    if (path[4] === 'add_snippet') {
        return 'script'
    }
    if (path[5] === '?') {
        return 'uncategorised'
    }
    return path[4] as EventCategoryType
}

const parseCategoryFromUrl = (url: string): EventCategoryType => {
    const path = url.split('/')
    if (path.length <= 4) {
        return parseExceptionUrl(url)
    }
    switch (path[3]) {
        case 'dashboard':
        case 'reports':
        case 'notifications':
        case 'global_pricing_settings':
            return path[3]
        case 'settings':
            return parseSettingsUrl(path)
        case 'tap':
            return `${path[3]}_${path[4]}` as EventCategoryType
        case 'orders':
            return parseOrdersUrl(path)
        case 'engage':
            return parseEngageToolsUrl(path)
        case 'venue':
            return parseVenueUrl(path)
        case 'crm':
            return `${path[3]}_${path[4]}` as EventCategoryType
        case 'products':
            return parseProductUrl(path)
        default:
            return 'unknown'
    }
}

async function parseError(response: Response, defaultMessage: any = null) {
    let message = defaultMessage
    try {
        message = await response.json()
    } catch {}
    return {
        status: response.status,
        message,
    }
}

export class LoggingService {
    constructor(private httpService: HttpService, private endpoint: string, private store: Store<State>) {}

    handleResponse<T = any>({
        response,
        logEventData,
        logEventType,
    }: {
        response: Response
        logEventType: EventType
        logEventData: ActionEventData
    }): Promise<T> {
        return new Promise(async (resolve, reject) => {
            let data

            try {
                data = await response.json()
            } catch (error) {}

            if (!response.ok) {
                this.logResponseError(response, logEventType, logEventData)

                return reject({
                    response: data,
                })
            }

            this.logAction(logEventType, logEventData)

            return resolve(data)
        })
    }

    createLogHttpResponse(logEventType: EventType, errorMessage: string, logEventData: ActionEventData) {
        return (response: Response) => {
            if (!response.ok) {
                this.logResponseError(response, logEventType, logEventData)
                throw new Error(errorMessage)
            }
            this.logAction(logEventType, logEventData)

            return response
        }
    }

    getCommonData(type: EventType, eventData: AnyDataObject) {
        const state = this.store.getState()
        const user = state.auth.user
        const username = user ? user.username : null

        let category = 'uncategorised'
        if (type === 'page_view' || type === 'access_denied') {
            category = parseCategoryFromUrl(eventData.click_to)
        }
        const commonData = {
            impersonated_by: user?.impersonatedBy || null,
            account: state.preferences.activeAccount,
            category,
            cookie_id: localStorage.getItem('cookieId'),
            browser_info: getBrowserInfo(),
        }

        return { username, commonData }
    }

    logAction = async (type: EventType, eventData: AnyDataObject = {}): Promise<void> => {
        try {
            const { username, commonData } = this.getCommonData(type, eventData)
            await this.httpService.fetchAnonymous(`${this.endpoint}log/action/`, {
                method: 'POST',
                body: JSON.stringify({
                    type,
                    username,
                    event_data: { ...commonData, ...eventData },
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw e
        }
    }

    async logResponseError(
        response: Response,
        type: EventType,
        eventData: AnyDataObject = {},
        message?: any,
    ): Promise<void> {
        const errorData = await parseError(response, message)
        this.logError(errorData, type, eventData)
    }

    async logError(errorData: AnyDataObject, type: EventType, eventData: AnyDataObject = {}): Promise<void> {
        try {
            const { username, commonData } = this.getCommonData(type, eventData)
            await this.httpService.fetchAnonymous(`${this.endpoint}log/action/`, {
                method: 'POST',
                body: JSON.stringify({
                    type,
                    username,
                    event_data: { error_data: errorData, ...commonData, ...eventData },
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        } catch (e) {
            throw e
        }
    }
}
