import { AuthenticatedHttpService } from 'http/httpService'
import { Profile } from 'settings/schema'
import { getUserDetails } from './queries'
import { User } from 'auth/state'

export interface UserDetails {
    currentUser: User
    profile: Profile
}

export class UserService {
    constructor(private httpService: AuthenticatedHttpService, private endpoint: string) {}

    async getLoggedInUser(): Promise<UserDetails> {
        const body = JSON.stringify({
            query: getUserDetails,
        })

        const response = await this.httpService.fetch(`${this.endpoint}graphql/`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Dash Api has returned status code: ${response.status}`)
        }

        const payload = await response.json()
        if (payload.errors) {
            throw new Error(`Dash Api has returned an error: ${payload.errors[0].message}`)
        }
        const user = payload.data.currentUser

        return {
            profile: payload.data.profile,
            currentUser: {
                username: user.username,
                lastLogin: user.lastLogin,
                name: user.name,
                accounts: user.accounts,
                permissions: user.permissions,
                isAdmin: user.isAdmin,
                resellerId: user.resellerId,
                impersonatedBy: user.impersonatedBy,
            },
        }
    }
}
