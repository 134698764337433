import { AuthenticatedHttpService } from 'http/httpService'
import { LoggingService, EventType, ActionEventData } from 'http/loggingService'
import { AttachedProduct } from 'products/attachedCategoriesList'

export interface Translation {
    key: string
    text: {
        [lang: string]: string
    }
}

export type PriceVisibilityType = 'NEVER' | 'RESELLERS_ONLY' | 'ALWAYS'
export type TemplateType = 'TICKET' | 'VOUCHER' | 'CARD'

export interface TemplateBody {
    name: string
    image: string
    useBannerImage?: boolean
    printingRequired?: boolean
    showVat: boolean
    showTapQrCode?: boolean
    hidePrice: PriceVisibilityType
    showBarcode: boolean
    showBarcodeNumber: boolean
    showFullName: boolean
    showEmail: boolean
    showOrderNo: boolean
    showOptions?: boolean
    showPhoneNumber?: boolean
    showSupportEmail?: boolean
    showOpeningHours?: boolean
    showAddress?: boolean
    templateType: TemplateType
    additionalInfo?: Translation | null
    bannerFirstInfo?: Translation | null
    bannerSecondInfo?: Translation | null
    language?: string
    textSize?: string | null
    backgroundColor?: string | null
    textColor?: string | null
}

export interface Template extends TemplateBody {
    id: string
    products?: AttachedProduct[]
    modifiedBy?: string
    modifiedAt?: string
}

export class TicketTemplatesService {
    constructor(
        private httpService: AuthenticatedHttpService,
        private loggingService: LoggingService,
        private backofficeEndpoint: string,
    ) {}

    async getTemplatesList(accountSlug: string, templateType: TemplateType | 'all_tickets'): Promise<Template[]> {
        const query = `?template_type=${templateType}`
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/ticket_configs/${query}`,
            {
                method: 'GET',
            },
        )

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        const body = await response.json()
        return body
    }

    async getTemplate(accountSlug: string, id: string): Promise<Template> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/ticket_configs/${id}/`,
            {
                method: 'GET',
            },
        )

        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        return await response.json()
    }

    async createTemplate(accountSlug: string, payload: TemplateBody): Promise<Template> {
        const logEventType: EventType = 'ticket_template_created'
        const logEventData: ActionEventData = {
            category: 'ticket_templates',
            payload: payload,
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/ticket_configs/`,
            {
                method: 'POST',
                body: JSON.stringify({ ...payload }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async updateTemplate(accountSlug: string, id: string, payload: TemplateBody): Promise<any> {
        const logEventType: EventType = 'ticket_template_updated'
        const logEventData: ActionEventData = {
            category: 'ticket_templates',
            payload: payload,
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/ticket_configs/${id}/`,
            {
                method: 'PUT',
                body: JSON.stringify({ ...payload }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async deleteTemplate(accountSlug: string, id: string): Promise<any> {
        const logEventType: EventType = 'ticket_template_deleted'
        const logEventData: ActionEventData = {
            category: 'ticket_templates',
            payload: { id },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/ticket_configs/${id}/`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async updateTemplateName(accountSlug: string, id: string, name: string): Promise<Template> {
        const logEventType: EventType = 'ticket_template_updated'
        const logEventData: ActionEventData = {
            category: 'ticket_templates',
            payload: { name },
        }

        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/ticket_configs/${id}/`,
            {
                method: 'PATCH',
                body: JSON.stringify({ name }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        if (!response.ok) {
            this.loggingService.logResponseError(response, logEventType, logEventData)
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        this.loggingService.logAction(logEventType, logEventData)
        return await response.json()
    }

    async getTemplateHtml(accountSlug: string, payload: TemplateBody): Promise<string> {
        let response = await this.httpService.fetch(
            `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/ticket_configs/preview/html/`,
            {
                method: 'POST',
                body: JSON.stringify({ ...payload }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        if (!response.ok) {
            throw new Error(`Backoffice has returned status code: ${response.status}`)
        }
        return response.text()
    }
}
