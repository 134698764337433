import * as React from 'react'
import styled from 'styled-typed'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IconLink = styled.a`
    cursor: pointer;
    color: ${(props) => props.theme.colors.boyBlue};
    transition: color 0.1s ease-in;
    line-height: 0;
    display: inline-block;
    padding: 0.5em 0.6875em;

    &:hover {
        color: ${(props) => props.theme.colors.textDark};
    }
`
export function NextLink(props: React.HTMLAttributes<HTMLAnchorElement>) {
    return (
        <IconLink {...props}>
            <FontAwesomeIcon icon={faChevronRight} />
        </IconLink>
    )
}

export function PrevLink(props: React.HTMLAttributes<HTMLAnchorElement>) {
    return (
        <IconLink {...props}>
            <FontAwesomeIcon icon={faChevronLeft} />
        </IconLink>
    )
}
