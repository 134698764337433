import { createAction } from 'typesafe-actions'
import { DateLocale } from './state'

export const activeAccountChanged = createAction(
    'ActiveAccountChanged',
    (action) => (slug: string | null) =>
        action({
            type: 'ActiveAccountChanged',
            slug,
        }),
)

export const dateLocaleChanged = createAction(
    'DateLocaleChanged',
    (action) => (dateLocale: DateLocale) =>
        action({
            type: 'DateLocaleChanged',
            dateLocale,
        }),
)
