import { AuthenticatedHttpService } from 'http/httpService'
import { get } from 'lodash'
import { getReadableError } from './helpers'
import {
    NotificationCampaignListItem,
    NotificationCampaignListResponse,
    CAMPAIGN_STATE,
    SCHEDULE_TYPE,
    NotificationCampaignsService,
    AddCampaignPayload,
    FetchNotificationCampaignResponse,
} from './types'

class Service implements NotificationCampaignsService {
    private API_PATH = 'dashboard/v1/campaigns'

    constructor(private httpService: AuthenticatedHttpService, private mendixEndpoint: string) {}

    async fetchAll(accountSlug: string): Promise<NotificationCampaignListItem[]> {
        const response = await this.httpService.fetch(`${this.mendixEndpoint}/${this.API_PATH}/${accountSlug}`)

        if (response.status === 404) {
            return []
        }

        if (!response.ok) {
            throw Error('failed to fetch campaigns')
        }

        const jsonData = await response.json()
        const campaings: NotificationCampaignListItem[] = jsonData.map((data: NotificationCampaignListResponse) => {
            const title = get(data, 'content[0].title', '')
            const message = get(data, 'content[0].body', '')

            return {
                id: data.caid,
                name: data.name,
                title: title,
                message: message,
                state: data.status.toLowerCase() as CAMPAIGN_STATE,
                endDate: new Date(data.schedule.end),
                scheduleType: data.schedule.scheduleType as SCHEDULE_TYPE,
                scheduleDescription: data.schedule.description,
            }
        })

        return campaings
    }

    async addCampaign(data: AddCampaignPayload, accountSlug: string): Promise<boolean> {
        const requestData: RequestInit = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const result = await this.httpService.fetch(
            `${this.mendixEndpoint}/${this.API_PATH}/${accountSlug}`,
            requestData,
        )

        if (!result.ok) {
            const error = await result.json().then((json) => {
                return getReadableError(json.reason)
            })
            throw Error(error)
        }

        return result.ok
    }

    async updateCampaign(data: AddCampaignPayload, accountSlug: string, caid: string): Promise<boolean> {
        const requestData: RequestInit = {
            method: 'PUT',
            body: JSON.stringify({ caid, ...data }),
            headers: {
                'Content-Type': 'application/json',
            },
        }

        const result = await this.httpService.fetch(
            `${this.mendixEndpoint}/${this.API_PATH}/${accountSlug}`,
            requestData,
        )
        if (!result.ok) {
            const error = await result.json().then((json) => {
                return getReadableError(json.reason)
            })
            throw Error(error)
        }

        return result.ok
    }

    async setCampaignState(data: any, accountSlug: string): Promise<boolean> {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }

        const result = await this.httpService.fetch(
            `${this.mendixEndpoint}/${this.API_PATH}/${accountSlug}`,
            requestOptions,
        )

        if (!result.ok) {
            const error = await result.json().then((json) => {
                return getReadableError(json.reason)
            })

            throw new Error(error)
        }

        return result.ok
    }

    async deleteCampaign(accountSlug: string, caid: string): Promise<boolean> {
        const requestData: RequestInit = {
            method: 'DELETE',
        }

        const result = await this.httpService.fetch(
            `${this.mendixEndpoint}/${this.API_PATH}/${accountSlug}/${caid}`,
            requestData,
        )

        if (!result.ok) {
            const error = await result.json().then((json) => {
                return json.reason || 'Unknown error'
            })
            throw Error(error)
        }

        return result.ok
    }

    async fetchCampaign(accountSlug: string, caid: string): Promise<FetchNotificationCampaignResponse> {
        const response = await this.httpService.fetch(`${this.mendixEndpoint}/${this.API_PATH}/${accountSlug}/${caid}`)

        if (!response.ok) {
            throw Error('failed to fetch campaign')
        }

        const jsonData = await response.json()
        return jsonData
    }
}

export default Service
