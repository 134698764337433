import { AuthenticatedHttpService } from 'http/httpService'
import { Result, failure, success } from 'result'
import { LoggingService, EventType, EventCategoryType, ActionEventData } from 'http/loggingService'
import { ProductConfig, PublishDraftResponse } from './schema'
import { syncTranslationKeyWithLanguageLocale } from './shared'

export type ProductName =
    | 'wonderbar'
    | 'modal_window'
    | 'trigger_button'
    | 'checkout'
    | 'email_template'
    | 'placements'
    | 'promotions'
export type TargetType = 'any' | 'desktop' | 'mobile'

export interface ProductVersion {
    id: string
    createdAt: string
    publishedAt: string | null
    unpublishedAt: string | null
    updatedBy: string | null
}

export interface ConfigurationVersions extends ConfigurationData {
    current: ProductVersion | null
    next: ProductVersion | null
}

export interface ConfigurationData {
    id?: string
    name: string | null
    product?: ProductName
    deviceType?: TargetType | null
    validFrom?: string
    validTo?: string
    audiences?: string[]
    duplicates?: DuplicateEngageTool[]
}

export interface DuplicateEngageTool {
    name: string
    uuid: string
}

export interface Component {
    slug: string
    value: any
}

export interface Translation {
    slug: string
    values: {
        [key: string]: string
    }
}

export interface Languages {
    primary: string
    selected: string[]
}

export interface Rule {
    type: string
    values: string[]
}

export interface UrlRules {
    blacklist: Rule[]
    whitelist?: Rule[]
}

export interface Configuration {
    components: Component[]
    translations: Translation[]
    languages: Languages
    urlRules: UrlRules
    productConfig: ProductConfig
}

export interface ImageUrl {
    img_url: string
}

export interface CampaignError {
    type: 'server_error'
    message: string
}

export class ComponentsService {
    constructor(
        private httpService: AuthenticatedHttpService,
        private loggingService: () => LoggingService,
        private backofficeEndpoint: string,
    ) {}

    defaultConfigurationUrl(accountSlug: string, product: ProductName): string {
        return `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/products/${product}/configurations/default`
    }

    configurationUrl(accountSlug: string, product: ProductName | 'all'): string {
        return `${this.backofficeEndpoint}api/v1/accounts/${accountSlug}/products/${product}/configurations`
    }

    async getConfigurationsVersions(
        accountSlug: string,
        product: ProductName | 'all',
        query?: string,
    ): Promise<ConfigurationVersions[]> {
        const response = await this.httpService.fetch(`${this.configurationUrl(accountSlug, product)}/${query || ''}`, {
            method: 'GET',
        })
        if (!response.ok) {
            throw new Error('Unable to fetch versions')
        }
        const data = await response.json()
        return data
    }

    async createCampaignObject(
        accountSlug: string,
        product: ProductName,
        deviceType: TargetType,
        name: string,
        audiences: string[],
        validFrom: string | null,
        validTo: string | null,
    ): Promise<Result<ConfigurationVersions, CampaignError>> {
        const body = JSON.stringify({
            device_type: deviceType,
            name,
            audiences,
            validFrom,
            validTo,
        })
        const response = await this.httpService.fetch(`${this.configurationUrl(accountSlug, product)}/`, {
            method: 'POST',
            body,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
        if (!response.ok) {
            const errorData = await response.json()
            return failure({
                type: 'server_error',
                message: errorData.message || 'Unable to create configuration',
            })
        }
        const data = await response.json()
        return success(data)
    }

    async deleteCampaignObject(accountSlug: string, product: ProductName, configId: string): Promise<void> {
        const response = await this.httpService.fetch(
            `${this.configurationUrl(accountSlug, product)}/${configId}/details/`,
            {
                method: 'DELETE',
            },
        )
        if (!response.ok) {
            throw new Error('Unable to delete campaign configuration.')
        }
    }

    async updateCampaign(
        accountSlug: string,
        product: ProductName,
        deviceType: TargetType,
        name: string,
        audiences: string[],
        validFrom: string | null,
        validTo: string | null,
        configId: string,
    ): Promise<Result<ConfigurationVersions, CampaignError>> {
        const response = await this.httpService.fetch(
            `${this.configurationUrl(accountSlug, product)}/${configId}/details/`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    name,
                    audiences,
                    validFrom,
                    validTo,
                    deviceType,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        if (!response.ok) {
            if (response.status === 500) {
                return failure({
                    type: 'server_error',
                    message: 'Unable to update configuration details',
                })
            }
            const errorData = await response.json()
            return failure({
                type: 'server_error',
                message: errorData.message || 'Unable to update configuration details',
            })
        }
        const data = await response.json()
        return success(data)
    }

    async getCampaignDetails(
        accountSlug: string,
        product: ProductName,
        configId: string,
    ): Promise<ConfigurationVersions> {
        const response = await this.httpService.fetch(`${this.configurationUrl(accountSlug, product)}/${configId}/`, {
            method: 'GET',
        })
        if (!response.ok) {
            throw new Error('Unable to fetch configuration details')
        }
        const data = await response.json()
        return data
    }

    async createEmptyDraft(
        accountSlug: string,
        product: ProductName,
        configurationId: string,
    ): Promise<ProductVersion> {
        const response = await this.httpService.fetch(
            `${this.configurationUrl(accountSlug, product)}/${configurationId}/`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        if (!response.ok) {
            throw new Error('Unable to create next version of configuration')
        }
        const data = await response.json()
        return data
    }

    async getConfiguration(
        accountSlug: string,
        product: ProductName,
        configId: string,
        version: 'current' | 'next' = 'next',
    ): Promise<Configuration> {
        const response = await this.httpService.fetch(
            `${this.configurationUrl(accountSlug, product)}/${configId}/versions/${version}/values/`,
            {
                method: 'GET',
            },
        )
        if (!response.ok) {
            throw new Error('Unable to fetch experiments components')
        }
        const data = await response.json()
        return syncTranslationKeyWithLanguageLocale(data)
    }

    async sendConfiguration(
        accountSlug: string,
        configuration: Configuration,
        product: ProductName,
        configId: string,
    ): Promise<void> {
        let config = { ...configuration }
        if (!config.languages.primary) {
            config.languages.primary = 'en' // workaround to fix primary = null
        }
        const response = await this.httpService.fetch(
            `${this.configurationUrl(accountSlug, product)}/${configId}/versions/next/values/`,
            {
                method: 'PUT',
                body: JSON.stringify(config),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )

        return this.loggingService()
            .handleResponse({
                response,
                logEventType: 'campaign_product_updated',
                logEventData: {
                    category: `engage_tools_${product}` as EventCategoryType,
                    payload: { ...config, configId },
                },
            })
            .catch((err) => {
                throw new Error(err?.response?.[0] || 'Unable to publish version to experiments components')
            })
    }

    _publishDraftApi = async (accountSlug: string, configId: string, versionId: string, product: ProductName) => {
        return this.httpService.fetch(`${this.configurationUrl(accountSlug, product)}/${configId}/versions/current/`, {
            method: 'PUT',
            body: JSON.stringify({ id: versionId }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
    }

    publishDraftWithHandler = async (
        accountSlug: string,
        configId: string,
        versionId: string,
        product: ProductName,
    ): Promise<PublishDraftResponse> => {
        const logEventType: EventType = 'campaign_product_published'
        const logEventData: ActionEventData = {
            category: `engage_tools_${product}` as EventCategoryType,
            payload: { configId, versionId },
        }
        const response = await this._publishDraftApi(accountSlug, configId, versionId, product)
        const data = await response.json()

        if (!response.ok) {
            this.loggingService().logResponseError(response, logEventType, logEventData, data)
            return data
        }
        this.loggingService().logAction(logEventType, logEventData)

        return data
    }

    async publishDraft(accountSlug: string, configId: string, versionId: string, product: ProductName): Promise<void> {
        const logEventType: EventType = 'campaign_product_published'
        const logEventData: ActionEventData = {
            category: `engage_tools_${product}` as EventCategoryType,
            payload: { configId, versionId },
        }
        this.loggingService().createLogHttpResponse('campaign_product_published', 'tes', logEventData)

        const response = await this._publishDraftApi(accountSlug, configId, versionId, product)
        if (!response.ok) {
            let data
            try {
                data = await response.json()
            } catch (error) {}
            this.loggingService().logResponseError(response, logEventType, logEventData, data)

            throw new Error(
                data?.message || data?.error?.message || 'Unable to publish version to experiments components',
            )
        }
        this.loggingService().logAction(logEventType, logEventData)
    }

    async unpublishCurrentVersion(
        accountSlug: string,
        product: ProductName,
        configId: string,
    ): Promise<ConfigurationVersions> {
        const logEventType: EventType = 'campaign_product_unpublished'
        const logEventData: ActionEventData = {
            category: `engage_tools_${product}` as EventCategoryType,
            payload: { configId },
        }

        const response = await this.httpService.fetch(
            `${this.configurationUrl(accountSlug, product)}/${configId}/versions/current/`,
            {
                method: 'PUT',
                body: JSON.stringify({ id: null }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        if (!response.ok) {
            this.loggingService().logResponseError(response, logEventType, logEventData)
            throw new Error('Unable to unpublish current version')
        }
        this.loggingService().logAction(logEventType, logEventData)
        const data = await response.json()
        return data
    }

    async updateConfigurationName(
        accountSlug: string,
        name: string,
        product: ProductName,
        configId: string,
    ): Promise<ConfigurationVersions> {
        const response = await this.httpService.fetch(
            `${this.configurationUrl(accountSlug, product)}/${configId}/details/`,
            {
                method: 'PUT',
                body: JSON.stringify({ name: name }),
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
        if (!response.ok) {
            throw new Error('Unable to update configuration name')
        }
        const data = await response.json()
        return data
    }

    async handleUploadError(response: Response) {
        const error = await response.json()
        if (error.errors.file.indexOf('invalid_image') > -1) {
            throw new Error('Uploaded file does not appear to be an image. Please try another file.')
        } else if (error.errors.file.indexOf('file_size') > -1) {
            throw new Error('Uploaded image is larger than 10MB. Please try a smaller image.')
        } else {
            throw new Error('An unknown error has occured. Please try again.')
        }
    }

    async fileUpload(file: File): Promise<ImageUrl> {
        const data = new FormData()
        data.append('file', file)
        const response = await this.httpService.fetch(`${this.backofficeEndpoint}api/v1/products/img_upload`, {
            method: 'POST',
            body: data,
        })
        if (response.status !== 201) {
            this.handleUploadError(response)
        }
        return await response.json()
    }
}
