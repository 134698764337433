import { CreateExceptionBarcodeError } from '../ordersService'

export type SingleBarcodeRedeemErrorCode = keyof Omit<CreateExceptionBarcodeError, 'barcodes' | 'type' | 'error'>

type GroupBarcodeRedeemErrorCode = 'barcode_not_found' | 'no_redeemable_barcodes_found' | 'barcode_not_group_scannable'

export type BarcodeRedeemErrorCode = SingleBarcodeRedeemErrorCode | GroupBarcodeRedeemErrorCode

export class HistoryRecord {
    private readonly timestamp: number

    public get time(): number {
        return this.timestamp
    }

    public type: Status
    public code: string
    public codes?: string[]
    public message?: string

    constructor(params: HistoryRecordParams) {
        this.timestamp = Date.now()
        this.type = params.type
        this.code = params.code
        this.codes = params.codes
        this.message = params.message
    }
}

export type HistoryRecordParams = Omit<HistoryRecord, 'time'>

export type Status =
    | 'initial'
    | 'loading'
    | 'success'
    | 'questionsPending'
    | 'notFound'
    | 'error'
    | 'pastValidity'
    | 'futureValidity'
    | 'alreadyRedeemed'
    | 'alreadyScannedIn'
    | 'timeLimitReached'
    | 'notScannableCode'
    | 'scanInRequired'
    | 'barcodeNotGroupScannable'
    | 'noRedeemableBarcodesFound'
