import { HttpService } from 'http/httpService'

export type NotificationLevel = 'Notice' | 'Alarm'

export interface UrlParts {
    accountSlug: string
    type: 'codes' | 'products'
    subtype: 'tickets' | 'coupons'
    id: number
    uuid: string
}

interface NotificationMetadata {
    title: string
    subtitle: string
    text: string
    footer: string
    urlParts: UrlParts | null
}

export interface NotificationItem {
    createdAt: Date | string
    accountSlug: string
    level: NotificationLevel
    actionUrl: string
    metadata: NotificationMetadata
}

export class NotificationsService {
    constructor(private httpService: HttpService, private notificationsEndpoint: string) {}

    async getNotificationsForAccount(accountSlug: string, date: string | null = null): Promise<NotificationItem[]> {
        const query = date ? `?created_at__gt=${date}` : ''
        const response = await this.httpService.fetch(
            `${this.notificationsEndpoint}api/v1/notifications/${accountSlug}/${query}`,
            {
                method: 'GET',
            },
        )

        if (!response.ok) {
            throw new Error(`Hermes has returned status code: ${response.status}`)
        }

        return await response.json()
    }

    async getAllNotifications(date: string | null = null): Promise<NotificationItem[]> {
        const query = date ? `?created_at__gt=${date}` : ''
        const response = await this.httpService.fetch(`${this.notificationsEndpoint}api/v1/notifications/${query}`, {
            method: 'GET',
        })

        if (!response.ok) {
            throw new Error(`Hermes has returned status code: ${response.status}`)
        }

        return await response.json()
    }
}
