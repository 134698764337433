import React from 'react'
import { RegularText, Bold } from 'uiComponents/typography'
import { InboxMessageButton, InboxMessageContainer, InboxMessageWrapper } from './ui'

interface Props {
    title?: string
    message?: string
    buttonLabel?: string
}

export const InboxMessage = ({ title, message, buttonLabel }: Props) => {
    const renderButtonLabel = () => {
        return !!buttonLabel?.length ? (
            <InboxMessageButton>
                <RegularText>{buttonLabel}</RegularText>
            </InboxMessageButton>
        ) : null
    }

    return (
        <InboxMessageContainer>
            <InboxMessageWrapper>
                <Bold>{title}</Bold>
                <RegularText>{message}</RegularText>
                {renderButtonLabel()}
            </InboxMessageWrapper>
        </InboxMessageContainer>
    )
}

export default InboxMessage
