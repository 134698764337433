import React from 'react'

import { AppServices } from 'middleware'
import { useQuery } from 'utils/useQuery'
import InlineTools from 'uiComponents/appLayouts/inlineTools'
import RedeemModeDialogContent from '../redeemModeDialog/RedeemModeDialogContent'

const IframeRedeemMode = () => {
    const query = useQuery()

    const redeemBarcode = (accountSlug: string, barcodes: string[]) => {
        return AppServices.ordersService.redeemBarcodesWithAuthToken(
            query.get('accountSlug') as string,
            barcodes,
            query.get('token') as string,
        )
    }

    return (
        <InlineTools>
            <RedeemModeDialogContent redeemBarcodes={redeemBarcode} />
        </InlineTools>
    )
}

export default IframeRedeemMode
