import { HttpService } from 'http/httpService'

export interface FeatureToggle {
    name: string
    value: any
}

export interface FeatureComponent {
    slug: string
    scope: string
    value: any
}

export class FeatureService {
    constructor(private httpService: HttpService, private experimentsEndpoint: string) {}

    async getDefaultFeatures(): Promise<FeatureToggle[]> {
        const response = await this.httpService.fetchAnonymous(
            `${this.experimentsEndpoint}experiments/api/v1/components/?scopes=dashboard`,
        )
        if (!response.ok) {
            throw new Error('Unable to fetch feature toggles')
        }

        const data = await response.json()
        return data.components.map((c: FeatureComponent) => ({
            name: c.slug,
            value: c.value,
        }))
    }

    async getAccountFeatures(accountSlug: string): Promise<FeatureToggle[]> {
        const cookieId = localStorage.getItem('cookieId')
        const url = `${this.experimentsEndpoint}api/v1/experiment/configs/${accountSlug}/${cookieId}?scopes=dashboard`
        const response = await this.httpService.fetchAnonymous(url)
        if (!response.ok) {
            throw new Error('Unable to fetch feature toggles')
        }

        const data = await response.json()
        return Object.entries(data).map(([k, v]) => ({
            name: k,
            value: v,
        }))
    }
}
