import React from 'react'
import styled from 'styled-typed'
import Svg from 'svg'
import { buttonReset } from 'uiComponents/buttons'
import dropdownArrowIcon from './icons/dropdown-arrow.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface ToggleButtonProps {
    icon: any
    active?: boolean
    disabled?: boolean
    className?: string
    fontAwesome?: boolean
    onClick?: () => void
}

const ToggleContainer = styled.button`
    ${buttonReset}
    width: 2.1em;
    height: 1.7em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375em;
    border: 1px solid transparent;
    margin: 0.5em 0.125em;
    transition: all 0.1s ease-in;

    &.disabled {
        color: ${(props) => props.theme.colors.textLight};
    }

    &:hover:not(.disabled) {
        border: 1px solid ${(props) => props.theme.colors.textLight};
    }

    &:active:not(.disabled),
    &.active {
        color: ${(props) => props.theme.colors.white};
        background: ${(props) => props.theme.colors.border};
    }

    &:active:hover:not(.disabled),
    &.active:hover {
        border: 1px solid ${(props) => props.theme.colors.white};
    }
`

const Icon = styled(Svg)`
    height: 0.9375em;
`

export function ToggleButton({ icon, active, disabled, className, fontAwesome, onClick }: ToggleButtonProps) {
    function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        if (onClick && !disabled) {
            onClick()
        }
    }
    const classes = className ? [className] : []
    if (active) {
        classes.push('active')
    }
    if (disabled) {
        classes.push('disabled')
    }

    return (
        <ToggleContainer className={classes.join(' ')} onClick={handleClick}>
            {!fontAwesome && <Icon src={icon} />}
            {fontAwesome && <FontAwesomeIcon icon={icon} style={{ fontSize: '1.1em' }} />}
        </ToggleContainer>
    )
}

const DropdownContainer = styled.div`
    position: relative;
    margin: 0.5em 0.125em;
`

const DropdownTrigger = styled.button`
    ${buttonReset}
    width: 2.5em;
    height: 1.7em;
    position: relative;
    background: ${(props) => props.theme.colors.white};
    border-radius: 0.375em;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transition: all 0.1s ease-in;

    &:hover {
        border: 1px solid ${(props) => props.theme.colors.textLight};
    }

    ${DropdownContainer}.expanded & {
        z-index: 2;
        border: 1px solid ${(props) => props.theme.colors.textLight};
        border-radius: 0.375em 0.375em 0 0;
        border-bottom: none;
    }
`

const TriggerContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    ${DropdownContainer}.expanded & {
        margin-top: 0;
    }
`

const BorderHider = styled.div`
    height: 2px;
    margin: 0 0.5px -2px;
    border: 1px solid transparent;
    background: ${(props) => props.theme.colors.white};
    visibility: hidden;

    ${DropdownContainer}.expanded & {
        height: 2px;
        visibility: visible;
    }
`

const DropdownArrow = styled(Svg)`
    width: 0.4em;
    margin: 0 0.3125em;

    ${DropdownContainer}.expanded & {
        transform: rotate(180deg);
    }
`

const DropdownContent = styled.div`
    background: ${(props) => props.theme.colors.white};
    border-radius: 0 0.375em 0.375em 0.375em;
    border: 1px solid ${(props) => props.theme.colors.textLight};
    padding: 0.25em 0;
    position: absolute;
    z-index: 1;
    top: 1.7em;
    margin-top: -1px;
    left: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.2s, opacity 0.2s ease-in;

    ${DropdownContainer}.expanded & {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s ease-in;
    }
`

interface DropdownItemProps<T> {
    item: T
    onClick: () => void
}

interface DropdownButtonProps<T> {
    icon: any
    className?: string
    onChange: (item: T) => void
    items: T[]
    containerComponent: React.ElementType
    itemComponent: React.ComponentClass<DropdownItemProps<T>> | React.StatelessComponent<DropdownItemProps<T>>
}

interface DropdownButtonState {
    expanded: boolean
}

export class DropdownButton<T> extends React.Component<DropdownButtonProps<T>, DropdownButtonState> {
    private dropdown: HTMLDivElement | null

    constructor(props: DropdownButtonProps<T>) {
        super(props)
        this.state = {
            expanded: false,
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.outsideClick, false)
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.outsideClick, false)
    }

    outsideClick = (evt: MouseEvent) => {
        if (
            this.dropdown &&
            this.dropdown.contains &&
            !this.dropdown.contains(evt.target as Node) &&
            this.state.expanded
        ) {
            this.setState({ expanded: false })
        }
    }

    toggleExpanded = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        this.setState({ expanded: !this.state.expanded })
    }

    onClick = (item: T) => {
        this.setState({ expanded: false })
        this.props.onChange(item)
    }

    render() {
        const { icon, className, items, containerComponent, itemComponent } = this.props
        const classes = className ? [className] : []
        if (this.state.expanded) {
            classes.push('expanded')
        }

        const ContainerComponent = containerComponent
        const ItemComponent = itemComponent

        return (
            <DropdownContainer className={classes.join(' ')} ref={(ref) => (this.dropdown = ref)}>
                <DropdownTrigger onClick={this.toggleExpanded}>
                    <TriggerContent>
                        <Icon src={icon} />
                        <DropdownArrow src={dropdownArrowIcon} />
                    </TriggerContent>
                    <BorderHider />
                </DropdownTrigger>
                <DropdownContent>
                    <ContainerComponent>
                        {items.map((item, i) => (
                            <ItemComponent key={i} item={item} onClick={() => this.onClick(item)} />
                        ))}
                    </ContainerComponent>
                </DropdownContent>
            </DropdownContainer>
        )
    }
}

const DropdownInlineButtonContainer = styled.button`
    ${buttonReset}
    height: 1.7em;
    width: 1.5625em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.textDark};

    &:hover {
        background: ${(props) => props.theme.colors.border};
        color: ${(props) => props.theme.colors.white};
    }
`

export interface DropdownInlineButtonProps {
    icon: any
    className?: string
    onClick?: () => void
}

export function DropdownInlineButton({ icon, className, onClick }: DropdownInlineButtonProps) {
    function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        if (onClick) {
            onClick()
        }
    }

    return (
        <DropdownInlineButtonContainer className={className} onClick={handleClick}>
            <Icon src={icon} />
        </DropdownInlineButtonContainer>
    )
}
