import { Profile } from './schema'

export interface ProfileState {
    profile?: Readonly<Profile> | null
    loading: boolean
}

export function createInitialProfileState(): ProfileState {
    return {
        profile: null,
        loading: true,
    }
}
