import * as React from 'react'
import styled, { css } from 'styled-typed'
import { Debounce } from 'debounce'
import { rtlLanguages } from 'utils/languages'
import { CharCount, commonStyles, errorCss, successCss, blockCss } from './styleComponents'
import { TextInputProps } from './interface'

export const textAreaCss = css<TextInputProps>`
    ${commonStyles}
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.textDark};
    border: none;
    transition: border 0.1s ease-in;
    resize: vertical;

    height: 5.2em;
    ${(props: TextInputProps) => (props.block ? blockCss : '')}
    ${(props: TextInputProps) => (props.status === 'success' ? successCss : '')}
  ${(props: TextInputProps) => (props.status === 'error' ? errorCss : '')}

  &:focus {
        outline: none;
        border: 1px solid ${(props) => props.theme.colors.boyBlue};
        ${(props: TextInputProps) => (props.status === 'success' ? successCss : '')}
        ${(props: TextInputProps) => (props.status === 'error' ? errorCss : '')}
    }
    ${(props) => (props.rtlInput ? 'direction: rtl;' : '')}
`

const TextAreaElement = styled.textarea<TextInputProps>`
    ${textAreaCss}
`

interface TextAreaState {
    currentCharLength: number
}

export type TextAreaProps = TextInputProps & React.InputHTMLAttributes<HTMLTextAreaElement>

export class TextArea extends React.PureComponent<TextAreaProps, TextAreaState> {
    private finishTypingLater: Debounce<string> | undefined

    constructor(props: TextAreaProps) {
        super(props)
        this.state = {
            currentCharLength: props.value ? props.value.toString().length : 0,
        }

        if (this.props.onFinishTyping) {
            this.finishTypingLater = new Debounce(this.props.onFinishTyping, this.props.finishTypingDelay || 500)
        }
    }

    componentWillUnmount() {
        if (this.finishTypingLater) {
            this.finishTypingLater.clear()
        }
    }

    onChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ currentCharLength: e.target.value.length })
        if (this.props.onChange) {
            this.props.onChange(e)
        }

        if (this.finishTypingLater) {
            this.finishTypingLater.trigger(e.target.value)
        }
    }

    render() {
        const { onChange, onFinishTyping, locale, ...rest } = this.props
        const rtlInput = rtlLanguages.includes(locale || 'en')
        return (
            <>
                <TextAreaElement onChange={this.onChanged} rtlInput={rtlInput} {...rest} />
                {this.props.maxLength && this.props.showCharsLeft && (
                    <CharCount>
                        {this.state.currentCharLength}/{this.props.maxLength}
                    </CharCount>
                )}
            </>
        )
    }
}
