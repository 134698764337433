import * as React from 'react'
import styled from 'styled-typed'

const PopupArea = styled.div`
    position: absolute;
    visibility: hidden;
    opacity: 0;
    z-index: 100;
    background: white;
    box-shadow: 0 24px 28px 2px rgba(0, 0, 0, 0.07), 0 9px 36px 4px rgba(0, 0, 0, 0.06),
        0 11px 10px 0 rgba(0, 0, 0, 0.1);
    transition: visibility 0s 0.2s, opacity 0.2s ease-in;

    &.popup-visible {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s ease-in;
    }
`

interface PopupProps {
    visible: boolean
    className?: string
    children: React.ReactNode
    style?: React.CSSProperties
}

export function Popup({ visible, children, className, style }: PopupProps) {
    const popupClass = className || ''
    return (
        <PopupArea className={visible ? popupClass + ' popup-visible' : popupClass} style={style}>
            {children}
        </PopupArea>
    )
}
