import { useCallback, useState } from 'react'

interface UseFetchProps {
    api: (...args: any[]) => Promise<any>
    initialData?: any
}

interface UseFetchReturn<D = any> {
    loading: boolean
    receivedAt?: number
    data: D
    fetch: (...args: any[]) => Promise<D>
}

export function useFetch<D = any>({ api, initialData }: UseFetchProps = {} as any): UseFetchReturn<D> {
    const [loading, setLoading] = useState(false)
    const [receivedAt, setReceivedAt] = useState<number>()
    const [data, setData] = useState(initialData)

    const fetch = useCallback(
        async (...params) => {
            setLoading(true)
            try {
                const time = new Date().valueOf()
                const response = await api(...params)
                setReceivedAt(time)
                setData(response)
                setLoading(false)

                return response
            } catch (error) {
                setLoading(false)
                // TODO: Add replace message callback
            }
        },
        [api],
    )

    return {
        loading,
        receivedAt,
        data,
        fetch,
    }
}
