import * as React from 'react'
import styled from 'styled-typed'
import conviousLogo from 'images/convious-logo-pink.svg'
import Svg from 'svg'

const LogoWrapper = styled.div`
    width: 6em;
    text-align: right;
`
const Text = styled.div`
    font-size: 0.75em;
    font-weight: bold;
    color: #3c4953;
`
const MainMenuHeader = styled.div`
    height: 6em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const StudioConviousLogo = () => {
    return (
        <MainMenuHeader>
            <LogoWrapper>
                <Svg src={conviousLogo} />
                <Text>Studio</Text>
            </LogoWrapper>
        </MainMenuHeader>
    )
}

export default StudioConviousLogo
