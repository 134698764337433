import { HttpService } from 'http/httpService'
import { filterMetaDataQuery, queryMap } from 'reports/queriesGeneral'
import {
    datedBarcodesQuery,
    undatedBarcodesQuery,
    statsOverviewQuery,
    emailsMarketingStatsQuery,
    emailsTransactionalStatsQuery,
    forecastSingleDataStats,
    forecastTimeseriesStats,
    notificationCampaignOverviewStatsQuery,
} from 'reports/queriesCustomReports'
import {
    BarcodesChartData,
    StatsOverviewData,
    ReportTimeIdentifierType,
    EmailsData,
    StatsResponse,
    ForecastTimeSeriesStats,
    ForecastSingleItemStats,
    DataSeries,
} from 'reports/schema'
import { Filters } from 'uiComponents/filter/schema'
import { ConviousMetrics } from 'admin/conviousMetrics/schema'
import { QueryConfig, getQueryString } from 'reports/queryGenerator'

export type Impact =
    | 'no_significant_change_detected'
    | 'expected_decrease_in_revenue'
    | 'expected_increase_in_revenue'
    | 'not_enough_data_to_estimate'
    | 'generic_error'

interface VariablesObject {
    [key: string]: string | number | Date | string[] | Filters[] | null
}

export class StatsService {
    constructor(private httpService: HttpService, private statsApiEndpoint: string) {}

    private async handleRequest(
        requestBody: any,
        responseLens: (response: any) => any = (response: any) => response.data,
    ): Promise<any> {
        const response = await this.httpService.fetch(`${this.statsApiEndpoint}`, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })

        if (!response.ok) {
            throw new Error(`Stats Api has returned status code: ${response.status}`)
        }
        const payload = await response.json()
        if (payload.errors) {
            throw new Error(`Stats Api has returned an error: ${payload.errors[0].message}`)
        }
        return responseLens(payload)
    }

    async getStats(queryConfig: QueryConfig, variables: VariablesObject): Promise<StatsResponse> {
        const query = getQueryString(queryConfig)
        const body = {
            query,
            operationName: queryConfig.querySetName,
            variables: { ...variables },
        }

        return await this.handleRequest(body)
    }

    async getBarcodesChartData(
        widget: string,
        dateFrom: string,
        dateTo: string,
        granularity: string | null,
        filters: Filters[],
        dated: boolean,
    ): Promise<BarcodesChartData> {
        const body = {
            query: dated ? datedBarcodesQuery : undatedBarcodesQuery,
            variables: { widget, dateFrom, dateTo, filters, granularity },
        }

        return await this.handleRequest(body)
    }

    private static getSecondaryMetric = (metric: string) => {
        switch (metric) {
            case 'visitors':
                return 'ticket_revenue_by_event_date'
            case 'upsells_sold_by_event_date':
                return 'upsell_revenue_by_event_date'
            default:
                return null
        }
    }

    async getReportStats(
        query: string,
        widget: string,
        metric: string,
        dateFrom: string,
        dateTo: string,
        granularity: string | null,
        filters: Filters[],
        groupBy: string | null = null,
    ): Promise<StatsResponse> {
        const queryDetails = queryMap[query]
        if (!queryDetails || !queryDetails.query || !queryDetails.operationName) {
            throw new Error(`Query ${query} not found`)
        }
        const secondaryMetric = StatsService.getSecondaryMetric(metric)
        const body = {
            operationName: queryDetails.operationName,
            query: queryDetails.query,
            variables: {
                widget,
                metric,
                secondaryMetric,
                dateFrom,
                dateTo,
                granularity,
                groupBy,
                filters,
            },
        }

        return await this.handleRequest(body)
    }

    async getOverviewReportTableStats(
        widget: string,
        dateFrom: string,
        dateTo: string,
        filters: Filters[],
        timeIdentifierType: ReportTimeIdentifierType,
    ): Promise<StatsOverviewData> {
        const body = {
            query: statsOverviewQuery,
            variables: { widget, dateFrom, dateTo, filters, timeIdentifierType },
        }

        return await this.handleRequest(body, (response: any) => response.data.defaultOverview)
    }

    async getEmailsOverviewStats(
        widget: string,
        dateFrom: string,
        dateTo: string,
        segment: 'marketing' | 'transactional',
    ): Promise<EmailsData[]> {
        const body = {
            query: segment === 'marketing' ? emailsMarketingStatsQuery : emailsTransactionalStatsQuery,
            variables: { widget, dateFrom, dateTo },
        }

        return await this.handleRequest(body, (response) => {
            return segment === 'marketing'
                ? response.data.marketingOverview.dataSeries
                : response.data.transactionalOverview.dataSeries
        })
    }

    async getForecastSingleItemsStats(
        widget: string,
        today: string,
        tomorrow: string,
        nextRangeTo: string,
    ): Promise<ForecastSingleItemStats> {
        const body = {
            query: forecastSingleDataStats,
            variables: { widget, today, tomorrow, nextRangeTo },
        }

        return await this.handleRequest(body)
    }

    async getForecastTimeseriesStats(
        widget: string,
        dateFrom: string,
        dateTo: string,
        forecastDateFrom: string,
        forecastDateTo: string,
    ): Promise<ForecastTimeSeriesStats> {
        const body = {
            query: forecastTimeseriesStats,
            variables: {
                widget,
                dateFrom,
                dateTo,
                forecastDateFrom,
                forecastDateTo,
                granularity: 'day',
            },
        }

        return this.handleRequest(body)
    }

    async getPriceImpactEstimate(
        productIds: string[],
        accountSlug: string,
        startDate: string,
        overrideString: string,
    ): Promise<Impact> {
        const query = `query impactOnDemand($productIds: [String],
      $accountSlug: String, $startDate: Date, $overrideString: String) {
        impactOnDemand(productIds: $productIds, accountSlug: $accountSlug, startDate: $startDate,
        overrideString: $overrideString) {
          alerts {
            impact
            score
          }
        }
      }
    `

        const requestBody = {
            query: query,
            variables: {
                productIds: productIds,
                accountSlug: accountSlug,
                startDate: startDate,
                overrideString: overrideString,
            },
            operationName: 'impactOnDemand',
        }

        return await this.handleRequest(requestBody, (response: any) => {
            return response.data.impactOnDemand.alerts.impact
        })
    }

    async getFilterMetaData(accountSlug: string, metadataFor: string[]): Promise<string> {
        const body = {
            query: filterMetaDataQuery,
            variables: { accountSlug, metadataFor },
        }

        return await this.handleRequest(body, (response: any) => {
            return response.data.metadata.result.metadata
        })
    }

    async getConviousMetrics(dateFrom: string, dateTo: string): Promise<ConviousMetrics[]> {
        const query = `
            query ConviousMetrics($dateFrom: Date, $dateTo: Date) {
                stats(widget: "", metric: company_performance_summary, dateFrom: $dateFrom, dateTo: $dateTo) {
                  dataSeriesDictionary {
                    details {
                      name
                      values {
                        name
                        value
                        format
                      }
                    }
                  }
                }
              }
            `
        const body = {
            query,
            variables: { dateFrom, dateTo },
        }

        return await this.handleRequest(body, (response: any) => {
            return response.data.stats.dataSeriesDictionary.details
        })
    }

    async getNotificationCampaignOverviewStats(
        widget: string,
        dateFrom: string,
        dateTo: string,
        filters: Filters[],
    ): Promise<DataSeries> {
        const body = {
            query: notificationCampaignOverviewStatsQuery,
            variables: {
                widget,
                dateFrom,
                dateTo,
                filters,
            },
        }

        return await this.handleRequest(body, (response: any) => {
            return response.data.stats.dataSeries
        })
    }
}
