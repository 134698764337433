import { HttpService } from 'http/httpService'

export interface ImageUploadResponse {
    url: string
}

export interface CropOptions {
    crop_x?: number
    crop_y?: number
    crop_height?: number
    crop_width?: number
    rotate?: number
    max_height?: number
    max_width?: number
    optimise_size?: boolean
}

export class ImagesService {
    constructor(private httpService: HttpService, private filesEndpoint: string) {}

    async uploadImage(file: File, options: CropOptions = {}): Promise<ImageUploadResponse> {
        const data = new FormData()
        data.append('file', file)
        if (options.crop_x) {
            data.append('crop_x', options.crop_x.toString())
        }
        if (options.crop_y) {
            data.append('crop_y', options.crop_y.toString())
        }
        if (options.crop_height) {
            data.append('crop_height', options.crop_height.toString())
        }
        if (options.crop_width) {
            data.append('crop_width', options.crop_width.toString())
        }
        if (options.rotate) {
            data.append('rotate', options.rotate.toString())
        }
        if (options.max_height) {
            data.append('max_height', options.max_height.toString())
        }
        if (options.max_width) {
            data.append('max_width', options.max_width.toString())
        }
        if (options.optimise_size) {
            data.append('optimise_size', options.optimise_size.toString())
        }

        const response = await this.httpService.fetch(`${this.filesEndpoint}/images/`, {
            method: 'POST',
            body: data,
        })

        if (response.status !== 200) {
            throw new Error(`Image upload service has returned status code: ${response.status}`)
        }
        return await response.json()
    }
}
