import * as config from 'config'

export const ConfigReader = {
    getCheckoutDemoEndpoint() {
        return config.getRequired('checkout-demo-endpoint')
    },
    getRecaptchaSiteKey() {
        return config.getRequired('recaptcha-site-key')
    },
    getBackOfficeEndpoint() {
        return config.getRequired('backoffice-endpoint')
    },
    getPreviewEndpoint() {
        return config.getRequired('preview-endpoint')
    },
}
