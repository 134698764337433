import { createAction } from 'typesafe-actions'
import { Profile } from './schema'

export const profileLoaded = createAction(
    'ProfileLoaded',
    (action) => (profile: Profile) =>
        action({
            type: 'ProfileLoaded',
            profile,
        }),
)
